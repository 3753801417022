import { useCallback, useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { StyledButton, CouponContainer } from '../../../styles';
import pixIcon from '../../../../../../assets/icons/pix.svg';
import boletoIcon from '../../../../../../assets/boletoicon.png';
import cartaoIcon from '../../../../../../assets/icons/cartao_icon.png';
import Dropdown from '../../../../../../components/Atoms/Dropdown';
import { getInstallments } from '../utils/getInstallments';
import Input from '../../../../../../components/Atoms/Input';
import { cardMask, cvvMask, expMask } from '../../../../../../utils/masks';
import { PAYMENT_METHODS_ENUM } from '../../../constants/paymentMethodsEnum';
import { convertNumberToBRL } from '../../../../../../utils/functions';
import { PaymentMethodsBtnsProps } from './types';
import { DropdownSkeleton, PixInfos } from './styles';
import { calculatePixTotalValue } from './utils';

const PaymentMethodsBtns = ({
  courseDetailsData,
  isLogging,
  isLoading = false,
  setPaymentMethod,
  setInstallments,
  selectedInstallments,
  handleApplyCoupon,
  setCoupon,
  discount,
  totalPrice,
}: PaymentMethodsBtnsProps) => {
  const [showButtonCredit, setShowButtonCredit] = useState(true);
  const [showButtonBoleto, setShowButtonBoleto] = useState(false);
  const totalToCharge = Number(totalPrice - discount);
  const {
    max_installments,
    subscription = false,
    subscription_installments,
    pix_price,
    pix_discount,
  } = courseDetailsData || {};

  const titleDropdownInit = Boolean(subscription)
    ? `${subscription_installments} de ${convertNumberToBRL(totalToCharge)}`
    : `1x de ${convertNumberToBRL(totalToCharge)}`;

  const hasDiscount = discount > 0;
  const hasPixDiscount = Boolean(pix_discount && !hasDiscount);
  const pixTotalValue =
    subscription && pix_price && hasDiscount
      ? calculatePixTotalValue(
          totalPrice,
          discount,
          subscription,
          Number(subscription_installments),
        )
      : Number(pix_price) - discount;

  const getDefaultDropdownValue = useCallback(() => {
    return getInstallments(selectedInstallments ?? 1, discount, totalPrice, subscription)[
      selectedInstallments - 1
    ];
  }, [selectedInstallments, totalPrice, discount, subscription]);

  const handleCreditCard = () => {
    setShowButtonCredit(true);
    setShowButtonBoleto(false);
    setPaymentMethod(PAYMENT_METHODS_ENUM.CREDCARD);
  };

  const handleBoleto = () => {
    setShowButtonCredit(false);
    setShowButtonBoleto(true);
    setPaymentMethod(PAYMENT_METHODS_ENUM.BILLET);
  };

  const handlePix = () => {
    setShowButtonCredit(false);
    setShowButtonBoleto(false);
    setPaymentMethod(PAYMENT_METHODS_ENUM.PIX);
  };

  return (
    <>
      <div className="btns">
        <StyledButton
          className={showButtonCredit ? 'cartao2-btn-signup-selected' : 'cartao2-btn-signup'}
          onClick={handleCreditCard}
          enabled={!isLoading}
          loading={isLoading}
        >
          <img src={cartaoIcon} alt="cartao-icon" />
          <p>Cartão de Crédito</p>
        </StyledButton>

        <StyledButton
          onClick={handleBoleto}
          enabled={!isLoading}
          className={showButtonBoleto ? 'boleto-btn-signup-selected' : 'boleto-btn-signup'}
          loading={isLoading}
        >
          <img src={boletoIcon} alt="boleto-icon" />

          <p className="colored" aria-hidden="true" text-align="right">
            Boleto
          </p>
        </StyledButton>

        <StyledButton
          onClick={handlePix}
          enabled={!isLoading}
          className={
            !showButtonBoleto && !showButtonCredit ? 'pix2-btn-signup-selected' : 'pix2-btn-signup'
          }
          loading={isLoading}
        >
          <img src={pixIcon} alt="pix-icon" />

          <p className="colored" aria-hidden="true" text-align="right">
            PIX
          </p>
        </StyledButton>
      </div>

      {!showButtonCredit && !showButtonBoleto && (
        <PixInfos>
          Valor no PIX: {convertNumberToBRL(pixTotalValue)}
          {hasPixDiscount && ` (${pix_discount} de desconto)`}
        </PixInfos>
      )}

      <CouponContainer>
        <Input
          autoComplete="off"
          name="coupon"
          placeholder="Cupom de desconto"
          containerStyle={{ width: '100%' }}
          enabled={!isLogging}
          onChange={event => {
            setCoupon(event.target.value);
          }}
        />

        <StyledButton
          onClick={handleApplyCoupon}
          enabled={!isLogging}
          loading={isLoading}
          customStyle="success"
          className="apply-coupon-btn"
          type="button"
        >
          <FiCheckCircle />
          Aplicar
        </StyledButton>
      </CouponContainer>

      {showButtonCredit ? (
        <>
          {!isLogging ? (
            <Dropdown
              arrowColor="#fff"
              textColor="#fff"
              backgroundCollor="#7a8095"
              customFontSize={14}
              customRadius={28}
              customWidth={100}
              customHeight={3.5}
              heightScale="rem"
              title={titleDropdownInit}
              items={getInstallments(
                selectedInstallments ?? 1,
                discount,
                totalPrice,
                subscription,
                max_installments,
              )}
              defaultValue={getDefaultDropdownValue()}
              isLoading={false}
              size="smaller"
              onChange={(e: any) => setInstallments(!e.length ? e.key : 1)}
            />
          ) : (
            <DropdownSkeleton className="progress-bar-wrapper-skeleton" animationDelay={0.1} />
          )}

          <Input
            autoComplete="off"
            name="cardNumber"
            placeholder="Número do cartão"
            containerStyle={{ width: '100%' }}
            mask={cardMask}
            enabled={!isLogging}
          />
          <Input
            autoComplete="off"
            name="cardName"
            placeholder="Nome"
            containerStyle={{ width: '100%' }}
            enabled={!isLogging}
          />

          <div className="group-info">
            <Input
              autoComplete="off"
              name="exp"
              placeholder="Data de validade"
              containerStyle={{ width: '48%' }}
              mask={expMask}
              enabled={!isLogging}
            />

            <Input
              autoComplete="off"
              name="cvv"
              placeholder="CVV"
              containerStyle={{ width: '48%' }}
              mask={cvvMask}
              enabled={!isLogging}
            />
          </div>
        </>
      ) : showButtonBoleto ? (
        !isLogging ? (
          <Dropdown
            arrowColor="#fff"
            textColor="#fff"
            backgroundCollor="#7a8095"
            customFontSize={14}
            customRadius={28}
            customWidth={100}
            customHeight={3.5}
            heightScale="rem"
            title={titleDropdownInit}
            items={getInstallments(
              selectedInstallments ?? 1,
              discount,
              totalPrice,
              subscription,
              max_installments,
            )}
            defaultValue={getDefaultDropdownValue()}
            isLoading={false}
            size="smaller"
            onChange={(e: any) => setInstallments(!e.length ? e.key : 1)}
          />
        ) : (
          <DropdownSkeleton className="progress-bar-wrapper-skeleton" animationDelay={0.2} />
        )
      ) : null}
    </>
  );
};

export default PaymentMethodsBtns;
