export const telMask = {
  values: ['(99) 9999-99999', '(99) 9 9999-9999'],
  maxLenght: 14,
};

export const dateMask = {
  values: [ '99/99/9999' ],
  maxLenght: 11,
};


export const cpfMask = {
  values: [ '999.999.999-99' ],
  maxLenght: 15,
};


export const cepMask = {
  values: [ '99999-999' ],
  maxLenght: 10,
};

export const cardMask = {
  values: [ '9999.9999.9999.9999' ],
  maxLenght: 20,
};

export const expMask = {
  values: [ '99/99' ],
  maxLenght: 6,
};

export const cvvMask = {
  values: [ '999' ],
  maxLenght: 4,
};
