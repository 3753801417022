import {
  SchoolLevel,
  SchoolLiveClasses,
} from "../../../../models/SchoolModels";
import { Course } from "../../../../models/CourseModels";

import VideoCard from "../../../Atoms/VideoCard/LiveClassVideoCard";

import ShimmerVideoCard from "../../../Atoms/Shimmer/VideoCard";

import Dropdown from "../../../Atoms/Dropdown";

import Separator from "../../../Atoms/Separator";
import * as S from "./styles";
import { IoVideocamOutline } from "react-icons/io5";
import Button from "../../../Atoms/Button";
import { useAuth } from "../../../../hooks/auth";

interface SelectItems {
  key: string;
  value: string;
}

interface CourseSideMenuProps {
  courseDetails?: Course;
  courseSeasonOptions?: Array<SelectItems>;
  filters?: Array<string>;
  firstFilter?: { key: string; value: string };
  firstItem?: SelectItems;
  isLoading?: boolean;
  isLoadingInfo?: boolean;
  videos?: SchoolLiveClasses[];
  selectedPosition?: number;
  onSeasonChange?(item: any): void;
  onVideoChange(video: SchoolLiveClasses): void | undefined;
  onFilterChage(item: any): void;
  schoolClass: SchoolLiveClasses[] | undefined;
  schoolLevel: SchoolLevel[] | undefined;
  handleLevelId: Function;
}

const LiveSideMenu = ({
  courseDetails,
  courseSeasonOptions = [{ key: "", value: "" }],
  filters,
  firstFilter = { key: "", value: "" },
  firstItem = { key: "", value: "" },
  isLoading = false,
  isLoadingInfo = false,
  selectedPosition = 0,
  videos = [],
  onSeasonChange,
  onVideoChange,
  onFilterChage,
  schoolClass,
  schoolLevel,
  handleLevelId,
}: CourseSideMenuProps) => {
  const { user } = useAuth();

  const path = window.location.pathname.split("/")[2];

  return (
    <S.Container customType="">
      <S.Header>
        <IoVideocamOutline className="IconHeader" />
        <p>Ao Vivo</p>
      </S.Header>

      {user.profileid === "Teacher" && (
        <S.ClassesArea>
          {schoolLevel &&
            schoolLevel.map((item, key) => (
              <div
                style={{ marginRight: "22px" }}
                onClick={() => handleLevelId(item)}
                key={key}
              >
                <Button
                  loading={isLoading}
                  bolder={true}
                  contrast={true}
                  noShaddow={true}
                  customStyle={path === item.levelid ? "selected" : ""}
                >
                  {item.title}
                </Button>
              </div>
            ))}
        </S.ClassesArea>
      )}

      <S.Heading>
        <S.HeadingScrollView>
          {schoolClass &&
            schoolClass.map((item, key) => (
              <S.ContentHeading key={key}>
                <S.ContentTextHeading>
                  <S.WrappetTextTitle>
                    <p>{key + 1}.</p>
                    <h4>{item.title}</h4>
                  </S.WrappetTextTitle>
                  <p>{item.description}</p>
                </S.ContentTextHeading>
                <S.LinkButton href={item.url} target="_blank">
                  <S.ButtonLive>
                    live
                    <IoVideocamOutline className="iconLive" />
                  </S.ButtonLive>
                </S.LinkButton>
              </S.ContentHeading>
            ))}
        </S.HeadingScrollView>
      </S.Heading>

      <Separator type="horizontal" />

      <S.Content>
        <S.FilterContainer>
          <>
            <p>Escolha</p>
            <div className="subject-select">
              <Dropdown
                arrowColor="#ffd35c"
                textColor="#ffd35c"
                title="Selecionar aula"
                items={courseSeasonOptions}
                defaultValue={isLoading ? { key: "", value: "" } : firstItem}
                isLoading={isLoading}
                onChange={onSeasonChange}
                customFontSize={21}
                size="small"
              />
            </div>
            {filters &&
              filters[0] &&
              filters[0] !== "" &&
              filters[0] !== " " && (
                <div className="class-select">
                  <Dropdown
                    title="Selecione"
                    arrowColor="#ffff"
                    textColor="#ffff"
                    backgroundCollor="#171a21"
                    customRadius={30}
                    customHeight={30}
                    customFontSize={11}
                    items={
                      filters
                        ? filters
                            .filter(
                              (filter: string, key: number) =>
                                filter !== filters[key - 1] && {
                                  key: key,
                                  value: filter,
                                }
                            )
                            .map((filter) => ({
                              key: filter,
                              value: filter,
                            }))
                        : [{ key: "1", value: "1" }]
                    }
                    defaultValue={firstFilter}
                    isLoading={false}
                    size="small"
                    onChange={(item) => onFilterChage(item)}
                  />
                </div>
              )}
          </>
        </S.FilterContainer>
        <S.VideosScrollContainer className="hasVerticalScroll">
          {videos.length > 0 &&
            !isLoading &&
            videos.map((video, index) => (
              <VideoCard
                key={video.classid}
                index={index + 1}
                video={video}
                isWatching={parseInt(video.classid) === selectedPosition}
                onSelect={onVideoChange}
                exercisePreviewActive={false}
              />
            ))}
          {isLoading && videos.length < 1 && (
            <>
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
            </>
          )}
        </S.VideosScrollContainer>
      </S.Content>
    </S.Container>
  );
};

export default LiveSideMenu;
