import styled, { css } from "styled-components";

export const Container = styled.section`
  ${({ theme }) => css`
    padding: 2.5rem;
    background: ${theme.colors.background_primary};
    min-height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 980px) {
      height: auto;
    }
  `}
`;

export const FirstColumn = styled.article`
  ${({ theme }) => css`
    padding: 2rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    gap: 2rem;
    justify-content: center;

    p.detail {
      font-size: 1rem;
      font-weight: 600;
      color: ${theme.colors.default_white};

      span {
        color: ${theme.colors.default_green};
      }
    }

    a.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bolder;
      color: ${theme.colors.default_white};
      background-color: ${theme.colors.default_green};
      border: none;
      border-radius: 0px 10px 0px 10px;
      padding: 0.5rem 0.8rem;
      height: 2.5rem;
      cursor: pointer;
    }
  `}
`;

export const SecondColumn = styled.article`
  ${({ theme }) => css`
    padding: 4rem;
    display: flex;
    justify-content: center;
    flex: 1;
    max-height: 90vh;
  `}
`;

export const PDefaultText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 1rem;
    font-weight: 500;
    max-width: 380px;

    a {
      color: ${theme.colors.default_green};
    }
  `}
`;

export const H2Custom = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.6rem;

    span {
      color: ${theme.colors.default_green};
    }
  `}
`;

export const H3Custom = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 1.5rem;

    &.yellow {
      color: ${theme.colors.default_yellow};
    }
    &.green {
      color: ${theme.colors.default_green};
    }
    &.blue {
      color: ${theme.colors.default_blue};
    }
  `}
`;

export const ImgCustom = styled.img`
  ${({ theme }) => css`
    width: 100%;
    max-width: 450px;
  `}
`;

export const GridItems = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 1.5rem;

    @media (max-width: 980px) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`;

export const ItemsCards = styled.div`
  ${({ theme }) => css`
    border: solid 3px;
    border-radius: 30px 0px 30px 0px;
    border-color: ${theme.colors.default_green};
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem;
    position: relative;

    @media (max-width: 980px) {
    }
  `}
`;

export const ImgSeal = styled.img`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 980px) {
    }
  `}
`;

export const ImgDetail = styled.img`
  ${({ theme }) => css`
    width: 150px;
    position: absolute;
    top: -12px;
    right: -25px;

    @media (max-width: 980px) {
    }
  `}
`;

export const ListOptions = styled.ul`
  ${({ theme }) => css`
    padding: 0 0 0 20px;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    color: ${theme.colors.default_white};

    @media (max-width: 980px) {
    }
  `}
`;
