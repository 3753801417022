import React, { useCallback } from "react";
import { FiLock } from "react-icons/fi";

import Input from "../../../components/Atoms/Input";
import Loading from "../../../components/Atoms/Loading";
import { useAuth } from "../../../hooks/auth";
import { useCart } from "../../../hooks/cart";

import { StyledButton } from "./styles";

interface ResetPasswordProps {
  isRequesting: boolean;
}

const SendCode: React.FC<ResetPasswordProps> = ({ isRequesting = false }) => {
  const { signOut } = useAuth();
  const { reset } = useCart();

  const handleSignOut = useCallback(() => {
    signOut();
    reset();
  }, [signOut]);

  return (
    <>
      <Input
        name="code"
        icon={FiLock}
        type="text"
        placeholder="Digite seu código"
        style={{ width: 300 }}
        autoComplete="off"
        enabled={!isRequesting}
      />

      <StyledButton type="submit" enabled={!isRequesting}>
        {isRequesting ? <Loading size={2} /> : "Enviar"}
      </StyledButton>

      <StyledButton type="button" className="logout" onClick={handleSignOut}>
        {isRequesting ? <Loading size={2} /> : "Logout"}
      </StyledButton>
    </>
  );
};

export default SendCode;
