export enum SUBDOMAIN_ENUM {
  BINDEMY = 'BINDEMY',
  SEDI = 'SEDI',
  MARTINBUCER = 'MARTINBUCER',
  RAIZDAQUESTAO = 'RAIZDAQUESTAO',
  ESCOLAIMPULSE = 'ESCOLAIMPULSE',
  XLR8ACADEMY = 'XLR8ACADEMY',
  PROPAGA = 'PROPAGA',
  POLOSUNIP = 'POLOSUNIP',
  CURSO = 'CURSO',
  CGA = 'CGA',
}
