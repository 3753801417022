import styled, { css } from "styled-components";

type MainProps = {
  backColor?: string | any;
};

export const DefaultMain = styled.main`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: ${theme.colors.background_primary};

    p {
      line-height: 1.5rem;
    }
  `}
`;

export const DefaultColors = styled.div<MainProps>`
  ${({ theme, backColor }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: ${backColor === "gradient"
      ? theme.colors.background_secondary
      : theme.colors.background_primary};
  `}
`;
