import styled, { css } from "styled-components";
import { shade } from "polished";

interface ContainerProps {
  enabled?: boolean;
  contrast?: boolean;
  bolder?: boolean;
  noShaddow?: boolean;
  shimmer?: boolean;
  customStyle?: string;
  state?: string;
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;

  background: #fed44a;
  border-radius: 6px;
  height: 35px;
  border: 0;
  padding: 0 16px;
  /* min-width: 154px; */

  color: #000;

      box-shadow: none;

  margin-top: 16px;
  transition: background-color 0.2s;

  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  color: #353536;

  .signuppayment {
    margin-top: 0px;
  }

  &:disabled {
    opacity: 0.7 !important;
    pointer-events: none;
  }

  &:hover {
    background: ${shade(0.4, '#00581e')};
    color: #fff;
    cursor: pointer;
  }

  ${(props) =>
    props.state === "danger" &&
    css`
      background: #f15057;
      color: #fff;
    `}

  ${(props) =>
    props.bolder
      ? css`
          font-weight: lighter !important;
        `
      : css`
          font-weight: bolder !important;
        `}

  ${(props) =>
    props.noShaddow &&
    css`
      box-shadow: none;
    `}

  ${(props) =>
    props.enabled === false &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}

  ${(props) =>
    props.contrast &&
    css`
      background: rgba(255, 211, 92, 0.14);
      border: solid 2px #fed44a;
      color: #fed44a;

      box-shadow: none;
    `}



  ${(props) =>
    props.shimmer &&
    css`
      animation: buttonShimmer 2s ease-in-out infinite;
    `}

  ${(props) =>
    props.customStyle === "success" &&
    css`
      background: #04b530;
      color: #fff;
    `}

  ${(props) =>
    props.customStyle === "danger" &&
    css`
      background: #bf0202;
      color: #fff;
    `}

    ${(props) =>
    props.customStyle === "call" &&
    css`
      background: #00aeca;
      color: #fff;

      transition: 0.4s;

      &:hover {
        background: rgba(0, 174, 202, 0.4);
      }
    `}

  ${(props) =>
    props.contrast &&
    props.customStyle === "call" &&
    css`
      background: #00aeca;
      border: solid 2px #3fe4ff;
      color: #fff;

      box-shadow: none;
      transition: 0.4s;

      &:hover {
        background: rgba(0, 174, 202, 0.4);
      }
    `}
  ${(props) =>
    props.customStyle === "white" &&
    css`
      background: #fff;
      border: solid 2px #353536;
    `}

  svg {
    font-size: 18px;
    margin-right: 4px;
  }
  ${(props) =>
    props.contrast &&
    props.customStyle === "selected" &&
    css`
      background: rgb(254, 212, 74);
      border: none;
      color: rgb(53, 53, 54);
      font-weight: bolder !important;

      transition: 0.4s;

      &:hover {
        opacity: 0.5;
      }
    `}

  @keyframes buttonShimmer {
    0% {
      background: rgba(255, 211, 92, 0.4);
    }
    50% {
      background: rgba(255, 211, 92, 0.8);
    }
    100% {
      background: rgba(255, 211, 92, 0.4);
    }
  }
`;
