import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: #fff;
`;

export const Link = styled.a`
  display: flex;
  width: fit-content;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;
