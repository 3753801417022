/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { Overlay } from "./styles";

type ModalProps = {
  children?: React.ReactNode;
  isOpen?: boolean;
  element?: Element;
  className?: string;
  onClick?: any;
  onFocus?: any;
  refProp?: any;
};

export const Modal = ({
  children,
  isOpen,
  element,
  className,
  onClick,
  onFocus,
  refProp,
}: ModalProps): JSX.Element => {
  const [isDOMReady, setIsDOMReady] = useState(false);

  useEffect(() => {
    setIsDOMReady(true);
    isOpen && document.body.classList.add("block_scroll");

    // paleativo para evitar conflito com o modal do carrinho
    return () => {
      const cartWrapper = document.getElementById("cart-container");

      if (cartWrapper && !cartWrapper.classList.contains("active")) {
        document.body.classList.remove("block_scroll");
      }
    };
  }, [isOpen]);

  return isDOMReady ? (
    createPortal(
      <Overlay
        className={`${isOpen ? "modal-overlay-on" : "modal-overlay-off"} ${
          className || ""
        }`}
        onClick={onClick}
        onFocus={onFocus}
        ref={refProp}
      >
        {children}
      </Overlay>,
      element ?? (document.getElementById("root") as Element)
    )
  ) : (
    <></>
  );
};
