import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { BackPageArrowProps } from './types';

const BackPageArrow = ({ backTo, textRoute }: BackPageArrowProps) => {
  const { push } = useHistory();

  const handleRouteAction = () => {
    push(backTo);
  };

  return (
    <S.Container onClick={handleRouteAction}>
      <S.Link>
        <FiArrowLeft size={24} />
        <p>{textRoute}</p>
      </S.Link>
    </S.Container>
  );
};

export default BackPageArrow;
