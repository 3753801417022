import styled, { css } from "styled-components";

interface ContainerProps {
  large: boolean;
  from?: string;
}

interface ContainerOverlayProps {
  isVisible: boolean;
}

interface VimeoWrapperProps {
  hasChat?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 78%;
  transition: width 0.4s;
  ${(props) =>
    props.large &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.from === "course" &&
    css`
      height: 100%;
    `}
  ${(props) =>
    props.from === "class" &&
    css`
      height: 100%;
    `}
  .vimeo-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    transition: filter 0, 2s;
    &.blured {
      filter: blur(2px) drop-shadow(1px 4px 53px #000000);
    }
  }
  .chapter-marker-active {
    background-color: red !important;
  }
  @media (max-width: 768px) {
    width: 100%;

    iframe {
      width: 100% !important;
    }
  }
`;

export const VimeoWrapper = styled.div<VimeoWrapperProps>`
  display: flex;
  width: 100%;
  height: 100%;
  transition: filter 0, 2s;
  ${(props) =>
    props.hasChat &&
    css`
      width: 75%;
    `}
  &.blured {
    filter: blur(2px) drop-shadow(1px 4px 53px #000000);
  }
`;

export const ContainerOverlay = styled.div<ContainerOverlayProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
      visibility: unset;
    `}
`;

export const EventChat = styled.div`
  display: flex;
  height: 100%;
  width: 25%;
  padding: 0 0.5rem;
  background: white;
  iframe {
    width: 100%;
  }
`;
