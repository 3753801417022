import React from "react";
import * as S from "./styles";
import Img from "../../assets/section_one.svg";

const FirstSection: React.FC = () => {
  return (
    <S.Container id="product">
      <S.FirstColumn>
        <p className="detail">
          <span>BINDEMY</span> | Plataforma de Ensino
        </p>
        <S.H1Custom>
          Uma plataforma que <br /> integra o físico com o digital
          <br /> e desenvolvida para
          <br />
          <span>
            criadores de conteúdo
            <br /> pedagógico.
          </span>
        </S.H1Custom>
        <S.PDefaultText>
          Melhore o aprendizado por meio de um sistema que entende as
          necessidades do estudante atual.
        </S.PDefaultText>
        <a href="/">CONHECER</a>
      </S.FirstColumn>
      <S.SecondColumn>
        <S.ImgCustom src={Img} />
      </S.SecondColumn>
    </S.Container>
  );
};

export default FirstSection;
