import { MdDomainVerification } from 'react-icons/md';
import Input from '../../../../../components/Atoms/Input';
import { useWhitelabel } from '../../../../../hooks/useWhitelabel';
import { InputSchoolPartnerProps } from './types';
import { hideComponentCheck } from './utils';
import { useState } from 'react';

const InputSchoolPartner = ({ isLogging = false }: InputSchoolPartnerProps) => {
  const { subdomain } = useWhitelabel();
  const subdomainValue = subdomain();
  const hideInput = hideComponentCheck(subdomainValue);

  const [value, setValue] = useState('');

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  if (!hideInput) {
    return null;
  }

  return (
    <Input
      onChange={handleChange}
      value={value}
      autoComplete="off"
      name="schoolpartner"
      icon={MdDomainVerification}
      placeholder="Nome da Igreja"
      containerStyle={{ width: '100%' }}
      enabled={!isLogging}
    />
  );
};

export { InputSchoolPartner };
