import { MdDomainVerification } from 'react-icons/md';
import Input from '../../../../../components/Atoms/Input';
import { useWhitelabel } from '../../../../../hooks/useWhitelabel';
import { InputDocNumberProps } from './types';
import { hideComponentCheck } from './utils';
import { cpfMask } from '../../../../../utils/masks';

const InputDocNumber = ({
  isLogging = false,
  allowPaymentInSignup = false,
}: InputDocNumberProps) => {
  const { subdomain } = useWhitelabel();
  const subdomainValue = subdomain();
  const hideInput = hideComponentCheck(subdomainValue);

  if (!hideInput || allowPaymentInSignup) {
    return null;
  }

  return (
    <Input
      autoComplete="off"
      name="documentNumber"
      icon={MdDomainVerification}
      placeholder="CPF"
      containerStyle={{ width: '100%' }}
      enabled={!isLogging}
      mask={cpfMask}
    />
  );
};

export { InputDocNumber };
