/* eslint-disable react/no-array-index-key */
import React, { useState, useMemo, useCallback, useEffect } from 'react';



import { Quiz } from "../../../../models/CourseModels";

import { convertHoursMinutesSecondsToSeconds } from "../../../../utils/functions";

import { useToast } from "../../../../hooks/toast";
import { useAuth } from "../../../../hooks/auth";
import { useParams } from "react-router-dom";

import Checkbox from "../../Checkbox";
import Button from "../../Button";



import { Container, Header, Item } from './styles';

interface VideoModalQuizProps {
  actualTime?: {
    loaded: number;
    loadedSeconds: number;
    played: number;
    playedSeconds: number;
  };
  quiz: Quiz;
  onAction(from: string): void;
}

const VideoModalQuiz: React.FC<VideoModalQuizProps> = ({
  actualTime,
  quiz,
  onAction,
}) => {
  const [selectedOption, setSelectedOption] = useState(-1);
  const [canModalOpen, setCanModalOpen] = useState(true);

  const { addToast } = useToast();

  const handleQuizAction = useCallback(
    (action: string) => {
      if (selectedOption < 0 && action !== 'jump') {
        addToast({
          title: 'Atenção',
          description: 'Para continuar é preciso marcar uma opção',
          type: 'info',
        });
        return;
      }
      onAction('modal');
      setTimeout(() => {
        setCanModalOpen(true);
      }, 2000);
      setSelectedOption(-1);
    },
    [onAction, addToast, selectedOption],
  );

  const questionOptions = useMemo<string[]>(() => JSON.parse(quiz.options), [
    quiz,
  ]);

  useEffect(() => {
    if (actualTime) {
      const actual = Math.floor(actualTime.playedSeconds);
      const timeToTriggerModal = convertHoursMinutesSecondsToSeconds(
        quiz.videoposition,
      );

      if (timeToTriggerModal === actual && canModalOpen) {
              console.log("aqui tempo quiz");
        onAction('timer');
        setCanModalOpen(false);
      }
    }
  }, [actualTime, quiz, onAction, canModalOpen]);

  return (
    <Container>
      <div className="content-wrapper">
        <Header>
          <h2>{quiz.title}</h2>
        </Header>
        <div className="content hasHorizontalScroll">
          {questionOptions.map((item, index) => (
            <Item key={index} onClick={() => setSelectedOption(index)}>
              <Checkbox
                customColor="#000"
                round
                isSelected={selectedOption === index}
              />
              <p>{item}</p>
            </Item>
          ))}
        </div>
        <div className="answer">
          <Button onClick={() => handleQuizAction('jump')} customStyle="danger">
            Pular
          </Button>
          <Button onClick={() => handleQuizAction('continue')}>
            Continuar
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default VideoModalQuiz;
