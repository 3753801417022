import React, { createContext, useContext, useState } from "react";

interface TesteModalProps {
  teste: string;
}

interface ModalContextData {
  modalState: any;
  toggleModal: any;
}

interface Props {
  children: React.ReactNode;
}

const ModalContext = createContext({} as ModalContextData);

export const ModalProvider = ({ children }: Props) => {
  const [modalState, setModalState] = useState<TesteModalProps>();

  const toggleModal = () => console.log("toggleModal");

  return (
    <ModalContext.Provider value={{ modalState, toggleModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  return context;
}
