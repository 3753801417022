import { MdDomainVerification } from 'react-icons/md';
import Input from '../../../../../components/Atoms/Input';
import { useWhitelabel } from '../../../../../hooks/useWhitelabel';
import { InputCodeProps } from './types';
import { hideComponentCheck } from './utils';
import { useState } from 'react';

const InputCode = ({ allowPaymentInSignup, isLogging = false }: InputCodeProps) => {
  const { subdomain } = useWhitelabel();
  const subdomainValue = subdomain();
  const hideInput = hideComponentCheck(subdomainValue, allowPaymentInSignup);

  const [value, setValue] = useState('');

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  if (hideInput) {
  if(subdomainValue ==="ESCOLAIMPULSE"){
  } else{
    return null;
    }
  }

  return (
    <Input
      onChange={handleChange}
      value={value}
      autoComplete="off"
      name="codeVerification"
      icon={MdDomainVerification}
      placeholder="Código da escola"
      containerStyle={{ width: '100%' }}
      enabled={!isLogging}
    />
  );
};

export { InputCode };
