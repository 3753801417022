import React, { useMemo, memo } from "react";

import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useLocation,
} from "react-router-dom";

import { getSpecialUser } from "../utils/accessRoleHelper";
import { useAuth } from "../hooks/auth";
import { AdminLayout } from "../pages/_layout/AdminLayout";
import { UsersLayout } from "../pages/_layout/UsersLayout";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  cantAccess?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  cantAccess = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  const specialUser = useMemo(() => {
    if (user) {
      return getSpecialUser(user.schoolid);
    }
    return "/";
  }, [user]);

  const redirectTo = useMemo<string>(() => {
    if (!user) {
      return "/";
    }
    if (isPrivate) {
      if (
        user.schoolid === "SETEPEB" ||
        user.schoolid === "SELETIVO" ||
        user.levelid.includes("Ano")
      ) {
        return "/liveclasses";
      }
      return "/courses";
    }
    if (pathname === "/login") {
      return "/courses";
    }

    return pathname !== "/" ? pathname : "/courses";
  }, [isPrivate, user, pathname]);

  const getRoute = (location: any): any => {
    if (isPrivate && !!user) {
      if (cantAccess) {
        if (specialUser !== "/") {
          return (
            <Redirect
              to={{
                pathname: specialUser,
                state: { from: location },
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location },
            }}
          />
        );
      }
      return <Component />;
    }
    if (!isPrivate && !!user && pathname !== "/login") {
      if (location.pathname === "/") {
        return (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location },
            }}
          />
        );
      }
      return <Component />;
    }
    if (!user && !isPrivate) {
      return <Component />;
    }

    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { from: pathname },
        }}
      />
    );
  };
  const isAdmin = user?.profileid.toLocaleLowerCase() === "admin";
  const Layout = isAdmin ? AdminLayout : UsersLayout;

  return (
    <Layout>
      <ReactDOMRoute {...rest} render={({ location }) => getRoute(location)} />
    </Layout>
  );
};

export default memo(Route);
