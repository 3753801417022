import React from "react";
import Logo from "../../assets/logo.svg";
import * as S from "./styles";

const Footer: React.FC = () => {
  return (
    <S.Container>
      <S.GridItems>
        <S.ItemsCards>
          <S.ImgLogo src={Logo} />
          <S.DivisorVertical />
          <span>(19) 9.8183.8203</span>
        </S.ItemsCards>
        <S.ItemsCards className="center">
          <p>© 2023 - Todos os direitos reservados</p>
          <span>Bindemy é uma marca registrada</span>
        </S.ItemsCards>
        <S.ItemsCards>
          <p>bindemyplataforma@bindemy.com.br</p>
        </S.ItemsCards>
      </S.GridItems>
    </S.Container>
  );
};

export default Footer;
