import React, { createContext, useCallback, useState, useContext } from "react";

import {
  convertSecondsToHoursMinutesSeconds,
  convertHoursMinutesSecondsToSeconds,
} from "../utils/functions";

interface ProgressContextData {
  setVideos(videos: any, type?: string): void;
  clearVideos(): void;
  setProgress(video: any, time: any): void;
  videos: any;
  currentlyVideo?: any;
  setCurrentlyVideo?: any;
}
interface Props {
  children: React.ReactNode;
}

const ProgressContext = createContext({} as ProgressContextData);

export const ProgressProvider = ({ children }: Props) => {
  const [currentlyVideo, setCurrentlyVideo] = useState([]);

  const [data, setData] = useState<any>(() => {
    const videos = localStorage.getItem("@NextLevel:videos");

    if (videos) {
      return JSON.parse(videos);
    }

    return {};
  });

  const handleCourseVideos = useCallback((videos: Array<any>) => {
    if (videos) {
      let updatedData = {};
      videos.forEach((video) => {
        let time;
        if (video.courseseasonmovieuser) {
          time = video.courseseasonmovieuser.videowatched
            ? video.courseseasonmovieuser.videowatched
            : "00:00:00";
        } else {
          time = "00:00:00";
        }
        const addingItem = { [`${video.url}`]: time };
        updatedData = { ...updatedData, ...addingItem };
      });
      localStorage.removeItem("@NextLevel:videos");
      localStorage.setItem("@NextLevel:videos", JSON.stringify(updatedData));
      setData(updatedData);
    }
  }, []);

  const setVideos = useCallback(
    (videos: Array<any>, type?: string) => {
      if (type === "course") {
        handleCourseVideos(videos);
        return;
      }
      if (videos) {
        let updatedData = {};
        videos.forEach((video) => {
          const time =
            video.schoollevelsubjectseasonclassuser &&
            video.schoollevelsubjectseasonclassuser.videowatched
              ? video.schoollevelsubjectseasonclassuser.videowatched
              : "00:00:00";
          updatedData = { ...updatedData, [`${video.url}`]: time };
        });

        localStorage.setItem("@NextLevel:videos", JSON.stringify(updatedData));
        setData(updatedData);
      }
    },
    [handleCourseVideos]
  );

  const clearVideos = useCallback(() => {}, []);

  const setPreviousUrl = useCallback((url: string) => {
    setPreviousUrl(url);
  }, []);

  const setProgress = useCallback(
    (video: any, time: any) => {
      // console.log(`aaaaaaaa - ${video}: ${time}`);
      if (time > 0) {
        const updatedData = data;

        if (convertSecondsToHoursMinutesSeconds(time) > updatedData[video]) {
          updatedData[video] = convertSecondsToHoursMinutesSeconds(time);
          localStorage.setItem(
            "@NextLevel:videos",
            JSON.stringify(updatedData)
          );
          setData(updatedData);
        }
      }
    },
    [data]
  );

  return (
    <ProgressContext.Provider
      value={{
        currentlyVideo,
        setCurrentlyVideo,
        videos: data,
        setVideos,
        setProgress,
        clearVideos,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export function useProgress() {
  return useContext(ProgressContext);
}
