import ReactDOM from "react-dom/client";
import App from "./App";

import "react-awesome-slider/dist/styles.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // It's commented because it cause an error on react player library, onProgress doesn't works
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
