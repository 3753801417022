import { CustomRadioGroupStyles } from './types';

export interface RadioGroupCustomProps {
  arialabelledby: string;
  name: string;
  defaultValue: string;
  children: React.ReactNode;
}

export const RadioGroupCustomStyles: CustomRadioGroupStyles = {
  flexDirection: 'row',
  paddingLeft: '15px',
};
