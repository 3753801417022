import React, { useEffect, useState, useCallback } from "react";

import apiV2 from "../../../services/apiV2";

import { Category as CategoryInterface } from "../../../models/AuthModels";

import CategoryCoursesAllItems from "../../../components/Mols/CategoryCoursesAllItems";

import { Container } from "./styles";

const SeeItemsCategory: React.FC = () => {
  const [categories, setCategories] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllCategories = useCallback(async () => {
    setIsLoading(true);
    const response = await apiV2.get<any>(
      `/course/category?page=0&categoryid=Comprados&schoolid=ObjetivoMogiGuaçu&userid=thiago.coradi`
    );

    setCategories(response.data.categorycourseList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getAllCategories();
  }, [getAllCategories]);

  return (
    <Container>
      <CategoryCoursesAllItems categories={categories} isLoading={isLoading} />
    </Container>
  );
};

export default SeeItemsCategory;
