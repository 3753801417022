import React from "react";
import Skeleton from "../../../Skeleton";

import { Container } from "./styles";

const MainSliderContainer: React.FC = () => (
  <Container>
    <Skeleton className="bg-skeleton">
      <Skeleton className="progress-bar-wrapper-skeleton" />
    </Skeleton>
  </Container>
);

export default MainSliderContainer;
