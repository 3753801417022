import { RadioGroup } from '@material-ui/core';
import { RadioGroupCustomProps, RadioGroupCustomStyles } from './styles';
import { useField } from '@unform/core';
import { ChangeEvent, useEffect, useRef } from 'react';

const RadioGroupCustom = ({
  arialabelledby,
  name,
  defaultValue,
  children,
}: RadioGroupCustomProps) => {
  const RadioGroupReference = useRef<null>(null);

  const { fieldName, registerField } = useField(name);

  const handleChange = (selectedOption: ChangeEvent<HTMLInputElement>) => {
    registerField({
      name: fieldName,
      ref: RadioGroupReference.current,
      getValue: (ref: HTMLSelectElement | null) => {
        if (!ref) {
          return '';
        }
        return selectedOption ? selectedOption.target.value : '';
      },
    });
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: RadioGroupReference.current,
      getValue: (ref: HTMLSelectElement | null) => {
        if (!ref) {
          return '';
        }
        return defaultValue;
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RadioGroup
      ref={RadioGroupReference}
      aria-labelledby={arialabelledby}
      defaultValue={defaultValue}
      name={name}
      style={RadioGroupCustomStyles}
      onChange={handleChange}
    >
      {children}
    </RadioGroup>
  );
};

export default RadioGroupCustom;
