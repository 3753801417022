import styled, { css, keyframes } from 'styled-components';

interface SelectedIconContainerProps {
  isWatching?: boolean;
}

export const Container = styled.div`
    display: flex;
    width: 80%;
    align-self: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 12px;
    font-family: Raleway;
`;

export const Header = styled.div`
    position: absolute;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    height: 22px;
    width: 22px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    transition: all 0.4s ease 0s;
    top: 24px;
    left: -24px;
    opacity: 1;
    animation: 0.4s ease 0s 1 normal none running jsxLXJ;
    margin-right: 10px !important;

    p {
        color: rgb(255, 211, 92);
        font-size: 14px;
        font-weight: bold;
    }
`;

export const P = styled.text`
    color: rgb(255, 211, 92);
    font-size: 14px;
    font-weight: bold;
`;

export const Box = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(28, 32, 42);
    padding: 8px;

    p {
        font-size: 14px;
        white-space: nowrap;
        width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const Button = styled.button`
    position: absolute;
    padding: 4px 0px;
    right: 0px;
    bottom: -16px;
    width: 65%;
    height: 24px;
    font-size: 12px;
    background: rgb(254, 212, 74);
    border-radius: 30px;
    border: 0px;
    box-shadow: rgb(0 0 0 / 40%) 0px 10px 20px;
    margin-top: 16px;
    transition: background-color 0.2s ease 0s;
    font-family: Raleway, sans-serif;
    color: rgb(53, 53, 54);
    font-weight: bolder !important;
    flex-direction: row;
    cursor: pointer;

    p {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
    }
`;
