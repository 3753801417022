import React, { Suspense, useCallback, useEffect, useState, lazy } from 'react';

import {
  Course as CourseInterface,
  CourseSeasonMovie,
  CourseSeason,
} from '../../../../../models/CourseModels';

import apiV2 from '../../../../../services/apiV2';

import Dropdown from '../../../Dropdown';
import Button from '../../../Button';
import ButtonBlocked from '../../../ButtonBlocked';
import Loading from '../../../Loading';

import {
  MdLockOutline,
  MdKeyboardArrowDown,
  MdPlayCircleOutline,
  MdOutlineAddShoppingCart,
  MdOutlineClose,
} from 'react-icons/md';

import {
  ModalContainer,
  ModalContent,
  ImgContainer,
  OverlayMask,
  InfosCourseContainer,
  InfosCourseWrap,
  ClassesContainer,
  ClasseItem,
  PlayFreeVideo,
} from './styles';
import ReactPlayer from 'react-player';

interface Props {
  closeModal?: Function | any;
  openCourseModal?: Function | any;
  course?: CourseInterface | any;
  courseSeasons?: CourseSeason[] | any;
  // addToCart?: () => void;
  addToCart?: any;
  alreadyInCart?: boolean;
  seasonListLength?: number;
  refProp?: any;
  id?: any;
  isPlayer?: boolean;
  setPlayer?: Function | any;
}

const CourseDetailsModal = ({
  closeModal,
  openCourseModal,
  course,
  courseSeasons,
  addToCart,
  alreadyInCart,
  seasonListLength,
  refProp,
  id,
  isPlayer,
  setPlayer,
}: Props) => {
  const PlayerFreeVideo = lazy(() => import('./PlayerFreeVideo'));
  const [classesSeason, setClassesSeason] = useState([]);
  const [currentlyUrl, setCurrentlyUrl] = useState<string>('');

  const getSeasonMovies = useCallback(async (seasonid: any) => {
    const response = await apiV2.get<any>(
      `/course/season/movie?courseid=${course.courseid}&seasonid=${seasonid}`,
    );
    setClassesSeason(response.data);
  }, []);

  useEffect(() => {
    getSeasonMovies(courseSeasons[0]?.season);
  }, [courseSeasons]);

  return (
    <ModalContainer ref={refProp} id={id}>
      <ModalContent>
        {isPlayer ? (
          <Suspense fallback={null}>
            <PlayerFreeVideo currentlyUrl={currentlyUrl} setPlayer={setPlayer} />
          </Suspense>
        ) : (
          <>
            <ImgContainer>
              <MdOutlineClose onClick={closeModal} />
              {course?.teaservideourl && course.teaservideourl !== ' ' ? (
                <Suspense fallback={<Loading />}>
                  <ReactPlayer autoPlay={false} controls url={course.teaservideourl} width="100%" />
                </Suspense>
              ) : (
                <>
                  <img
                    src={course.teaserurl}
                    alt={course.teaserurl}
                    aria-hidden="true"
                    width="100%"
                  />
                  <OverlayMask />
                </>
              )}
            </ImgContainer>
            <InfosCourseContainer>
              <InfosCourseWrap>
                <h2>
                  {course.title}
                  <br />
                  <span>{courseSeasons.length} Módulo(s)</span>
                </h2>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                  }}
                >
                  {!course.is_owner &&
                    (course.subscription ? (
                      <p className="colored" aria-hidden="true" text-align="right">
                        {Number(course.subscription_installments).toFixed(0)}x de R$
                        {Number(course.price).toFixed(2)}
                      </p>
                    ) : (
                      <p className="colored" aria-hidden="true" text-align="right">
                        {course.max_installments > 0 ? course.max_installments : 12}x de R$
                        {Number(
                          (course.price *
                            (course.max_installments && course.max_installments <= 6
                              ? 1
                              : 1.0459)) /
                            (course.max_installments > 0 ? course.max_installments : 12),
                        ).toFixed(2)}
                      </p>
                    ))}
                  {course.comingsoon === 'true' ? (
                    <div
                      className="card_button"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <p>EM BREVE</p>
                    </div>
                  ) : !course.is_owner ? (
                    <div
                      className="card_button"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {alreadyInCart === true ? (
                        <Button disabled>
                          <MdOutlineAddShoppingCart />
                          Item já adicionado
                        </Button>
                      ) : (
                        <Button onClick={addToCart}>
                          <MdOutlineAddShoppingCart />
                          Adicionar ao carrinho
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div
                      className="card_button"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {course.blocked ? (
                        <ButtonBlocked onClick={openCourseModal}>
                          <MdLockOutline />
                          Acesso Bloqueado
                        </ButtonBlocked>
                      ) : course.status_payment && course.status_payment !== 'PAID' ? (
                        <ButtonBlocked onClick={openCourseModal}>
                          <MdLockOutline />
                          Pagamento Pendente
                        </ButtonBlocked>
                      ) : course.clicksign_is_signed && course.clicksign_is_signed === 'false' ? (
                        <ButtonBlocked onClick={openCourseModal}>
                          <MdLockOutline />
                          Contrato Pendente
                        </ButtonBlocked>
                      ) : (
                        <Button onClick={openCourseModal}>
                          <MdPlayCircleOutline />
                          Ir para o curso
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </InfosCourseWrap>
              <InfosCourseWrap>
                <h2>Aulas</h2>
                <Dropdown
                  arrowColor="#fed44a"
                  textColor="#fed44a"
                  backgroundCollor="transparent"
                  customFontSize={21}
                  customRadius={28}
                  customWidth={'fit-content'}
                  customHeight={3.5}
                  heightScale="rem"
                  fullHeight
                  title={`Selecionar módulo`}
                  items={courseSeasons}
                  defaultValue={courseSeasons ? courseSeasons[0] : undefined}
                  isLoading={false}
                  size="smaller"
                  onChange={(e: any) => getSeasonMovies(e.season)}
                  leftPosition={'-30px'}
                />
              </InfosCourseWrap>
              <ClassesContainer>
                <Suspense fallback={<Loading />}>
                  {classesSeason &&
                    classesSeason.length > 0 &&
                    classesSeason.map((item: any, key: number) => (
                      <ClasseItem key={key}>
                        <p className="number">{key + 1}</p>
                        {item.isfree ? (
                          <PlayFreeVideo
                            onClick={() => {
                              setPlayer();
                              setCurrentlyUrl(item.url);
                            }}
                          >
                            <MdPlayCircleOutline />
                            <span className="flag">Grátis</span>
                            <img src={item.thumb} alt={course.title} />
                          </PlayFreeVideo>
                        ) : course.is_owner ? (
                          <PlayFreeVideo onClick={openCourseModal}>
                            <MdPlayCircleOutline />
                            <img src={item.thumb} alt={course.title} />
                          </PlayFreeVideo>
                        ) : (
                          <img src={item.thumb} alt={course.title} />
                        )}
                        <p>
                          {item.title}
                          <br />
                          {item?.description}
                        </p>
                        <p className="time_tracking">{item?.videoduration}min</p>
                      </ClasseItem>
                    ))}
                  {classesSeason.length === 0 && <Loading />}
                </Suspense>
              </ClassesContainer>
            </InfosCourseContainer>
          </>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default CourseDetailsModal;
