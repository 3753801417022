import { BrowserRouter } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"
import GlobalStyle from "./styles/global";
import AppProvider from "./hooks";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";
import { InitialRouteContextProvider } from "./hooks/InitialRouteContext";
import { ProgressProvider } from "./hooks/progress";

function App() {
  return (
    <InitialRouteContextProvider>
      <BrowserRouter>
        <GlobalStyle />
        <AppProvider>
          <ProgressProvider>
            <Routes />
          </ProgressProvider>
        </AppProvider>
      </BrowserRouter>
    </InitialRouteContextProvider>
  );
}

export default App;
