import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiUser, FiLock, FiMail, FiSmartphone } from 'react-icons/fi';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useAuth } from '../../../hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { telMask } from '../../../utils/masks';
import { useCart } from '../../../hooks/cart';

import Input from '../../../components/Atoms/Input';
import Loading from '../../../components/Atoms/Loading';
import ImageUpload from '../../../components/Atoms/ImageUploadV2';

import {
  Container,
  ContentWrapper,
  LoginWrapper,
  FormWrapper,
  StyledButton,
  PixContainer,
  InputContainerCGA,
} from './styles';
import { useInitialRoute } from '../../../hooks/InitialRouteContext';
import { BaseCodesProducts, DataFormInfo, QrCodeObject } from './types';
import PayOnRegister from './components/PayOnRegister';
import apiV2 from '../../../services/apiV2';
import QRCode from 'react-qr-code';
import { InputCode } from './components/InputCode';
import { InputDocNumber } from './components/InputDocNumber';
import { InputBirthDate } from './components/InputBirthDate';
import { InputSchoolPartner } from './components/InputSchoolPartner';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { v4 } from 'uuid';
import useCourseDetails from '../../../services/client/useCourseDetails';
import { PAYMENT_METHODS_ENUM } from './constants/paymentMethodsEnum';
import { PAYMENT_METHODS_TYPE_ENUM } from './constants/paymentMethodsTypeEnum';
import InputPosition from './components/InputPosition';
import { SUBDOMAIN_ENUM } from '../../../constants/subddomainEnum';
import { ErrorMessage } from './components/InputPosition/styles';

let urlAtual =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_WHITELABEL}`
    : window.location.href;
let splitUrlProd = urlAtual.split('.');
let subdomain = splitUrlProd[0].includes('www')
  ? ['', splitUrlProd[1]]
  : splitUrlProd[0].split('//');
const subdomainDefined = subdomain[1];

const isBindemyCourse = subdomainDefined.toUpperCase() === SUBDOMAIN_ENUM.CURSO;
const isMartinApp = subdomainDefined.toUpperCase() === SUBDOMAIN_ENUM.MARTINBUCER;
const isCGA = subdomainDefined.toUpperCase() === SUBDOMAIN_ENUM.CGA;

const baseCodesProducts: BaseCodesProducts = {
  curso: 'CURSO_LIVE_1',
  martinbucer: 'COBT_B224',
};

const productCodeBasedOnWhitelabel = baseCodesProducts[subdomainDefined] ?? 'curso';

const Landing: React.FC = () => {
  const { scrollToTop } = useScrollToTop();
  const location = useLocation();
  const generatedId = v4();
  const { fetchCourseDetailsData, courseDetailsData } = useCourseDetails();
  const { user } = useAuth();

  const queryParams = new URLSearchParams(location.search);
  const checkoutParam = queryParams.get('checkout');
  const courseParam = queryParams.get('courseid');

  const allowPaymentInSignup =
    isBindemyCourse || Boolean(checkoutParam && courseParam && isMartinApp);

  const isSub = Boolean(courseDetailsData?.subscription);

  const { signupApplyCoupon } = useCart();
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHODS_ENUM>(
    PAYMENT_METHODS_ENUM.CREDCARD,
  );

  const [selectedInstallments, setInstallments] = useState(1);
  const [qrCode, setQrCode] = useState<QrCodeObject>({
    generated: false,
    qrCode: '',
    transactionId: '',
  });
  const [userData, setUserData] = useState({
    username: '',
    password: '',
  });

  const [discount, setDiscount] = useState(0);
  const totalPrice = courseDetailsData && allowPaymentInSignup ? courseDetailsData.price : 0;
  const pixTotalPrice = courseDetailsData?.pix_price ? courseDetailsData.pix_price : totalPrice;
  const validPriceToCharge = totalPrice - discount > 0;
  const [coupon, setCoupon] = useState('');
  const [isLogging, setIsLogging] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const formRef = useRef<FormHandles>(null);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const { subdomainUrlImg } = useInitialRoute();

  const { signUp, signIn, signUpWithPayment } = useAuth();
  const { push } = useHistory();

  const handleChangeImg = (img: string): void => {
    setImgUrl(img);
  };

  const handlePixPayment = useCallback(async () => {
    setIsLogging(true);
    try {
      console.log('check pix payment');

      const response = await apiV2.get<string>(
        `/user/signup/payment/pix/verify?tid=${qrCode.transactionId}&courseid=${courseDetailsData?.courseid}`,
      );

      if (response.data === 'WAITING_PAYMENT' || response.data === '') {
        window.alert('Caso já tenha pagado, espere alguns minutos e verifique novamente.');
      } else if (response.data === 'PAID') {
        window.alert('Pagamento efetuado com sucesso :)');

        signIn(userData);
      }
    } catch (err) {
      console.log('error', err);

      window.alert('Erro ao finalizar compra :(');
    } finally {
      setIsLogging(false);
    }
  }, [userData, qrCode.transactionId, courseDetailsData?.courseid, signIn]);

  const handleApplyCoupon = useCallback(async (): Promise<void> => {
    setIsLogging(true);

    try {
      const discount = await signupApplyCoupon(coupon ?? '', subdomain[1], totalPrice);

      setDiscount(Number(discount));
    } catch (err) {
      console.log('error', err);

      window.alert('Erro no cupom :(');
    } finally {
      const handleLoggin = () => setIsLogging(false);
      setTimeout(handleLoggin, 1500);
    }
  }, [signupApplyCoupon, coupon, totalPrice]);

  const handleLogin = useCallback(
    async (data: DataFormInfo) => {
      setIsLogging(true);
      setFormErrors({});
      console.log('submit data', data);

      try {
        formRef.current?.setErrors({});

        if (data.cellphone && data.cellphone !== '') {
          data.cellphone = data.cellphone.replaceAll(/[^\d]/g, '');
        }

        if (data.documentNumber && data.documentNumber !== '') {
          data.documentNumber = data.documentNumber.replaceAll(/[^\d]/g, '');
        }

        const schema = Yup.object().shape({
          fullName: Yup.string().required('Nome completo obrigatório!'),
          address:
            allowPaymentInSignup && validPriceToCharge
              ? Yup.string().required('Endereço obrigatório')
              : Yup.string(),
          number:
            allowPaymentInSignup && validPriceToCharge
              ? Yup.string().required('Número obrigatório')
              : Yup.string(),
          city:
            allowPaymentInSignup && validPriceToCharge
              ? Yup.string().required('Cidade obrigatória')
              : Yup.string(),
          neighborhood:
            allowPaymentInSignup && validPriceToCharge
              ? Yup.string().required('Bairro obrigatório')
              : Yup.string(),
          state:
            allowPaymentInSignup && validPriceToCharge
              ? Yup.string().required('Estado obrigatório')
              : Yup.string(),
          zipcode:
            allowPaymentInSignup && validPriceToCharge
              ? Yup.string().required('CEP obrigatório')
              : Yup.string(),
          cardName:
            allowPaymentInSignup &&
            validPriceToCharge &&
            paymentMethod === PAYMENT_METHODS_ENUM.CREDCARD
              ? Yup.string().required('Nome no cartão obrigatório')
              : Yup.string(),
          cardNumber:
            allowPaymentInSignup &&
            validPriceToCharge &&
            paymentMethod === PAYMENT_METHODS_ENUM.CREDCARD
              ? Yup.string().required('Número obrigatório')
              : Yup.string(),
          cvv:
            allowPaymentInSignup &&
            validPriceToCharge &&
            paymentMethod === PAYMENT_METHODS_ENUM.CREDCARD
              ? Yup.string().required('cvv obrigatório')
              : Yup.string(),
          exp:
            allowPaymentInSignup &&
            validPriceToCharge &&
            paymentMethod === PAYMENT_METHODS_ENUM.CREDCARD
              ? Yup.string().required('validade obrigatória')
              : Yup.string(),
          documentNumber:
            allowPaymentInSignup && validPriceToCharge
              ? Yup.string().required('CPF obrigatório')
              : Yup.string(),
          cellphone: allowPaymentInSignup
            ? Yup.string().required('Celular obrigatório')
            : Yup.string(),
          birthdate: isMartinApp
            ? Yup.string().required('Data de nascimento obrigatória!')
            : Yup.string(),
          email: Yup.string().email('E-mail inválido').required('E-mail obrigatório!'),
          password: Yup.string().required('Senha obrigatória!'),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), undefined],
            'As senhas não são iguais',
          ),
          position: isCGA ? Yup.string().required('Selecione uma opção') : Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (allowPaymentInSignup) {
          if (totalPrice - discount === 0) {
            console.log('free payment signup');

            await signUpWithPayment({
              schoolpartner: '',
              documentNumber: data.documentNumber,
              birthdate: data.birthdate,
              cellphone: data.cellphone,
              email: data.email,
              password: data.password,
              username: data.fullName,
              imageUrl: imgUrl ?? ' ',
              fullName: data.fullName,
              address: data.address,
              number: data.number,
              city: data.city,
              state: data.state,
              neighborhood: data.neighborhood,
              zipcode: '',
              complement: data.complement,
              credit_card_installments: selectedInstallments ? selectedInstallments : 1,
              amount: 0,
              price: 0,
              cartid: '',
              cardNumber: '',
              cardName: data.cardName,
              exp: '',
              cvv: data.cvv,
              method: PAYMENT_METHODS_TYPE_ENUM.FREE,
              couponid: coupon,
              schoolid: data.schoolId ? data.schoolId : subdomainDefined.toUpperCase(),
              levelid: data.schoolLevel ? data.schoolLevel : '1',
              roomid: data.schoolPhase ? data.schoolPhase : subdomainDefined.toUpperCase(),
              codeVerification: data.codeVerification ? data.codeVerification : '',
              productid: courseParam ? courseParam : productCodeBasedOnWhitelabel,
            });

            return;
          }

          if (paymentMethod === PAYMENT_METHODS_ENUM.CREDCARD) {
            console.log('credcard', data, 'method', paymentMethod);

            const body = {
              schoolpartner: '',
              documentNumber: data.documentNumber,
              birthdate: data.birthdate,
              cellphone: data.cellphone,
              email: data.email,
              password: data.password,
              username: data.fullName,
              imageUrl: imgUrl ?? ' ',
              fullName: data.fullName,
              address: data.address,
              number: data.number,
              city: data.city,
              state: data.state,
              neighborhood: data.neighborhood,
              zipcode: data.zipcode.replaceAll('-', '').replaceAll('.', ''),
              complement: data.complement,
              credit_card_installments: selectedInstallments ? selectedInstallments : 1,
              amount: (totalPrice - discount) * 100,
              price: (totalPrice - discount) * 100,
              cartid: '',
              cardNumber: data.cardNumber.replaceAll('.', ''),
              cardName: data.cardName,
              exp: data.exp.replaceAll('/', ''),
              cvv: data.cvv,
              method: isSub
                ? PAYMENT_METHODS_TYPE_ENUM.CREDCARD_SUB
                : PAYMENT_METHODS_TYPE_ENUM.CREDCARD,
              couponid: coupon,
              schoolid: data.schoolId ? data.schoolId : subdomainDefined.toUpperCase(),
              levelid: data.schoolLevel ? data.schoolLevel : '1',
              roomid: data.schoolPhase ? data.schoolPhase : subdomainDefined.toUpperCase(),
              codeVerification: data.codeVerification ? data.codeVerification : '',
              productid: courseParam ? courseParam : productCodeBasedOnWhitelabel,
            };

            console.log('cred card submit', body);

            await signUpWithPayment(body);

            return;
          }

          if (paymentMethod === PAYMENT_METHODS_ENUM.BILLET) {
            console.log('billet', data, 'method', paymentMethod);

            const body = {
              schoolpartner: '',
              documentNumber: data.documentNumber,
              birthdate: data.birthdate,
              cellphone: data.cellphone,
              email: data.email,
              password: data.password,
              username: data.fullName,
              imageUrl: imgUrl ?? ' ',
              fullName: data.fullName,
              address: data.address,
              number: data.number,
              city: data.city,
              state: data.state,
              neighborhood: data.neighborhood,
              zipcode: data.zipcode.replaceAll('-', '').replaceAll('.', ''),
              complement: data.complement,
              credit_card_installments: selectedInstallments ? selectedInstallments : 1,
              amount: (totalPrice - discount) * 100,
              price: (totalPrice - discount) * 100,
              cartid: '',
              method: isSub
                ? PAYMENT_METHODS_TYPE_ENUM.BILLET_SUB
                : PAYMENT_METHODS_TYPE_ENUM.BILLET,
              couponid: coupon,
              cardNumber: '',
              cardName: '',
              exp: '',
              cvv: '',
              schoolid: data.schoolId ? data.schoolId : subdomain[1].toUpperCase(),
              levelid: data.schoolLevel ? data.schoolLevel : '1',
              roomid: data.schoolPhase ? data.schoolPhase : subdomain[1].toUpperCase(),
              codeVerification: data.codeVerification ? data.codeVerification : '',
              productid: courseParam ? courseParam : productCodeBasedOnWhitelabel,
            };

            console.log('billet submit', body);

            await signUpWithPayment(body);

            return;
          }

          if (paymentMethod === PAYMENT_METHODS_ENUM.PIX) {
            console.log('pix', data, 'method', paymentMethod);

            const body = {
              schoolpartner: '',
              documentNumber: data.documentNumber,
              birthdate: data.birthdate,
              cellphone: data.cellphone,
              email: data.email,
              password: data.password,
              username: data.fullName,
              imageUrl: imgUrl ?? ' ',
              fullName: data.fullName,
              address: data.address,
              number: data.number,
              city: data.city,
              state: data.state,
              neighborhood: data.neighborhood,
              zipcode: data.zipcode.replaceAll('-', '').replaceAll('.', ''),
              complement: data.complement,
              credit_card_installments: 1,
              amount: (Number(pixTotalPrice) - discount) * 100,
              price: (Number(pixTotalPrice) - discount) * 100,
              cartid: '',
              method: PAYMENT_METHODS_TYPE_ENUM.PIX,
              couponid: coupon,
              cardNumber: '',
              cardName: '',
              exp: '',
              cvv: '',
              schoolid: data.schoolId ? data.schoolId : subdomain[1].toUpperCase(),
              levelid: data.schoolLevel ? data.schoolLevel : '1',
              roomid: data.schoolPhase ? data.schoolPhase : subdomain[1].toUpperCase(),
              codeVerification: data.codeVerification ? data.codeVerification : '',
              productid: courseParam ? courseParam : productCodeBasedOnWhitelabel,
            };

            console.log('submit pix', body);
            const response = (await signUpWithPayment(body)) as
              | {
                  qrcode: string;
                  transactionid: string;
                }
              | undefined;

            console.log('res pix', response);

            if (response && Boolean(response && response.qrcode)) {
              setQrCode({
                generated: true,
                qrCode: response.qrcode,
                transactionId: response.transactionid,
              });
            }

            return;
          }
        }

        console.log('normal sign in');

        await signUp({
          schoolpartner: isMartinApp ? data.schoolpartner : '',
          documentNumber: isMartinApp ? data.documentNumber : '',
          birthdate: isMartinApp ? data.birthdate : '',
          cellphone: isMartinApp ? data.cellphone : '',
          email: data.email,
          password: data.password,
          username: data.fullName,
          imageUrl: imgUrl ?? ' ',
          fullName: data.fullName,
          schoolid: data.schoolId ? data.schoolId : subdomainDefined.toUpperCase(),
          levelid: data.schoolLevel ? data.schoolLevel : '1',
          roomid: data.schoolPhase ? data.schoolPhase : subdomainDefined.toUpperCase(),
          codeVerification: data.codeVerification ? data.codeVerification : '',
          position: data.position,
        });
      } catch (err) {
        console.log('error', err);

        if (err instanceof Yup.ValidationError) {
          console.log('error', err);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setFormErrors(errors);

          setIsLogging(false);
        } else {
          setIsLogging(false);
        }
      } finally {
        if (paymentMethod !== PAYMENT_METHODS_ENUM.PIX) {
          scrollToTop();
        }

        setIsLogging(false);
      }
    },
    [
      signUp,
      signUpWithPayment,
      scrollToTop,
      isSub,
      allowPaymentInSignup,
      validPriceToCharge,
      coupon,
      totalPrice,
      imgUrl,
      selectedInstallments,
      paymentMethod,
      discount,
      courseParam,
    ],
  );

  const handleRedirectToCourses = () => push('/');

  useEffect(() => {
    courseParam && fetchCourseDetailsData(courseParam, generatedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    handleRedirectToCourses();
  }

  return (
    <Container>
      <ContentWrapper>
        <LoginWrapper>
          <img src={subdomainUrlImg} alt="logo" onClick={() => push('./login')} />
          <ImageUpload type="simple" setImg={handleChangeImg} customChild={false} />
          <Form ref={formRef} onSubmit={handleLogin}>
            <FormWrapper>
              <h3>Cadastro</h3>

              <Input
                autoComplete="off"
                name="fullName"
                icon={FiUser}
                placeholder="Digite seu nome completo"
                enabled={!isLogging}
                containerStyle={{ width: '100%' }}
              />
              <InputCode isLogging={isLogging} allowPaymentInSignup={allowPaymentInSignup} />
              <InputDocNumber isLogging={isLogging} allowPaymentInSignup={allowPaymentInSignup} />
              <InputBirthDate isLogging={isLogging} />

              {(isMartinApp || isBindemyCourse) && (
                <Input
                  autoComplete="off"
                  name="cellphone"
                  icon={FiSmartphone}
                  placeholder="Celular"
                  mask={telMask}
                  containerStyle={{ width: '100%' }}
                  enabled={!isLogging}
                />
              )}

              <InputSchoolPartner isLogging={isLogging} />

              {isCGA && (
                <InputContainerCGA>
                  <InputPosition isLoading={isLogging} />

                  {formErrors?.position && <ErrorMessage>{formErrors?.position}</ErrorMessage>}
                </InputContainerCGA>
              )}

              {isBindemyCourse ? (
                <Input
                  autoComplete="off"
                  name="email"
                  icon={FiMail}
                  placeholder="E-mail"
                  enabled={!isLogging}
                  containerStyle={{ width: '100%' }}
                  onChange={event =>
                    setUserData(prev => ({ ...prev, username: event.target.value }))
                  }
                />
              ) : (
                <Input
                  autoComplete="off"
                  name="email"
                  icon={FiMail}
                  placeholder="E-mail"
                  enabled={!isLogging}
                  containerStyle={{ width: '100%' }}
                />
              )}

              {isBindemyCourse ? (
                <Input
                  autoComplete="off"
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Sua senha"
                  enabled={!isLogging}
                  containerStyle={{ width: '100%' }}
                  onChange={event =>
                    setUserData(prev => ({ ...prev, password: event.target.value }))
                  }
                />
              ) : (
                <Input
                  autoComplete="off"
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Sua senha"
                  enabled={!isLogging}
                  containerStyle={{ width: '100%' }}
                />
              )}

              <Input
                autoComplete="off"
                name="confirmPassword"
                icon={FiLock}
                type="password"
                placeholder="Confirmar senha"
                enabled={!isLogging}
                containerStyle={{ width: '100%' }}
              />

              {allowPaymentInSignup && validPriceToCharge ? (
                <PayOnRegister
                  courseDetailsData={courseDetailsData}
                  selectedInstallments={selectedInstallments}
                  totalPrice={totalPrice}
                  discount={discount}
                  setInstallments={setInstallments}
                  setPaymentMethod={setPaymentMethod}
                  handleApplyCoupon={handleApplyCoupon}
                  setCoupon={setCoupon}
                  isLogging={isLogging}
                />
              ) : null}
              {paymentMethod === PAYMENT_METHODS_ENUM.PIX ? null : (
                <StyledButton enabled={!isLogging} className="signup" type="submit">
                  {isLogging ? (
                    <Loading size={2} />
                  ) : allowPaymentInSignup ? (
                    validPriceToCharge ? (
                      isSub ? (
                        'Assinar'
                      ) : (
                        'Comprar'
                      )
                    ) : (
                      'Concluir'
                    )
                  ) : (
                    'Registrar'
                  )}
                </StyledButton>
              )}
              {allowPaymentInSignup &&
                !qrCode.generated &&
                paymentMethod === PAYMENT_METHODS_ENUM.PIX && (
                  <StyledButton enabled={!isLogging} className="signup" type="submit">
                    {isLogging ? <Loading size={2} /> : 'Gerar QRCode'}
                  </StyledButton>
                )}
              {allowPaymentInSignup &&
                qrCode.generated &&
                paymentMethod === PAYMENT_METHODS_ENUM.PIX && (
                  <>
                    <PixContainer>
                      <QRCode value={qrCode.qrCode} size={180} />
                    </PixContainer>
                    <StyledButton
                      enabled={!isLogging}
                      className="signup"
                      type="button"
                      onClick={handlePixPayment}
                    >
                      {isLogging ? <Loading size={2} /> : 'JÁ PAGUEI'}
                    </StyledButton>
                  </>
                )}
            </FormWrapper>
          </Form>
        </LoginWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Landing;
