import styled, { css } from 'styled-components';

interface CourseContainerProps {
  type: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 10px;

  transition: width 0.4s, height 0.4s;

  .bg-skeleton {
    width: 100%;
    height: auto;
    min-height: 55vh;

    transition: width 0.4s, height 0.4s;

    box-shadow: 0 0 10px rgba(0,0,0,0.08);

    border: solid 0.8px rgba(117, 117, 117, 0.5);

    @media(max-width: 980px) {
      min-height: 250px;
      height: 100%;
    }
  }
`;
