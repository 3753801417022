import React from "react";

import { Category as CategoryInterface } from "../../../models/AuthModels";

import CourseContainer from "../../../components/Mols/CourseContainer";
import ShimmerCategoryContainer from "../../../components/Mols/Shimmer/CategoryContainer";

import {
  Container,
  CategoryTitle,
  CarouselWrapper,
  SeeAllItems,
} from "./styles";

interface CategoryContainerProps {
  categories: Array<CategoryInterface>;
  isLoading: boolean;
}

const CategoryContainer: React.FC<CategoryContainerProps> = ({
  categories,
  isLoading,
}) => {
  if (!isLoading) {
    return (
      <>
        {categories.map((category, index) => (
          <Container key={category.id} position={index}>
            <CategoryTitle>
              <h3>{category.title}</h3>
              <p>{`(${category.courses.length})`}</p>
              {/* <SeeAllItems to={`/categorycourses/${category.id}`}>
                VER TODOS
                <FiChevronRight />
              </SeeAllItems> */}
            </CategoryTitle>
            <CarouselWrapper className={category.title}>
              <CourseContainer
                courseViewType={"horizontal"}
                // courseViewType={category.type}
                courses={category.courses}
                isLoading={isLoading}
              />
            </CarouselWrapper>
          </Container>
        ))}
      </>
    );
  }
  return (
    <>
      <ShimmerCategoryContainer />
      <ShimmerCategoryContainer />
      <ShimmerCategoryContainer />
    </>
  );
};

export default CategoryContainer;
