import React from "react";

import { Container, Content } from "./styles";

interface ModalInterface {
  onClose(): void;
  reset?: boolean;
  children: React.ReactNode;
}

const ModalCertificate = ({ children, onClose, reset = false }: ModalInterface) => (
  <Container reset={reset}>
    <Content>{children}</Content>
  </Container>
);

export default ModalCertificate;
