import styled from "styled-components";

export const WhatsIcon = styled.div`
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;

  font-size: 2.5rem;

  a {
    display: flex;
    text-decoration: none;

    svg {
      fill: #4bc959;
    }
  }
`;
