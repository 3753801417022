import SelectCustom from '../../../../../components/Atoms/Select';

const defaultOptionSelected = [
  {
    value: 'Diretor',
    label: 'Diretor',
  },
  {
    value: 'Funcionário',
    label: 'Funcionário',
  },
];

const InputPosition = ({ isLoading = false }) => {
  return (
    <div style={{ width: '100%' }}>
      <SelectCustom
        options={defaultOptionSelected}
        name="position"
        placeholder="Selecione o cargo"
        isLoading={isLoading}
      />
    </div>
  );
};

export default InputPosition;
