import React, { useCallback,  useRef } from "react";

import { SchoolLiveClasses } from "../../../models/SchoolModels";

import { Container, VimeoWrapper } from "./styles";
import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from "react-player/vimeo";
import { ReactPlayerProps } from "react-player";
interface ViemoComponentProps {
  url?: string;
  large?: boolean;
  width?: string;
  height?: string;
  video?: SchoolLiveClasses;
  onPause?(info: any): void;
  onFinish?(info: any): void;
}

const VimeoComponent: React.FC<ViemoComponentProps> = ({
  url,
  large = false,
  width,
  height,
  video,
  onPause,
  onFinish,
}) => {
  const handlePauseVideo = useCallback(async (info: any) => {
    console.log(info);
  }, []);
  const vimeoPlayerRef = useRef<ReactPlayerProps>(null);
  const handleEndVideo = useCallback((info: any) => {}, []);

  const handleProgressVideo = useCallback((info: any) => {}, []);

  return (

   <VimeoWrapper
          className={""}
          hasChat={url?.includes("event")}
        >
                 {url
  //                && courseDetails?.is_owner
                 && (

              <>
                          <ReactPlayer
                            url={url}
                            playing={true}
                            progressInterval={950}
                            start={0}
                            autoPlay={true}
                            width="100%"
                            height="100%"
                            controls
                            config={{
                              playerOptions: {
                                autopause: !true,
                                autoplay: true,
                              },
                              onSeek: 0,
                            }}
                            ref={vimeoPlayerRef}

                          />
                        </>

          )}
        </VimeoWrapper>


  );
};

export default VimeoComponent;
