import React from "react";
import { FiChevronLeft } from "react-icons/fi";

import { Category as CategoryInterface } from "../../../models/AuthModels";

import CoursesContainerAllItems from "../../../components/Mols/CoursesContainerAllItems";
import CategoryContainer from "../Shimmer/CategoryContainer";

import {
  Container,
  CategoryTitle,
  CarouselWrapper,
  SeeAllItems,
} from "./styles";

interface CategoryContainerProps {
  categories: Array<CategoryInterface>;
  isLoading: boolean;
}

const CategoryCoursesAllItems: React.FC<any> = ({ categories, isLoading }) => {
  if (!isLoading) {
    return (
      <>
        <Container>
          <CategoryTitle>
            <FiChevronLeft />
            <h3>Lançamentos (3)</h3>
          </CategoryTitle>
          <CoursesContainerAllItems
            courseViewType={"horizontal"}
            // courseViewType={category.type}
            courses={categories}
            isLoading={isLoading}
          />
        </Container>
      </>
    );
  }
  return (
    <>
      <CategoryContainer />
    </>
  );
};

export default CategoryCoursesAllItems;
