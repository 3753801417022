import React from "react";
import { FiLock } from "react-icons/fi";

import Input from "../../../components/Atoms/Input";
import Loading from "../../../components/Atoms/Loading";

import { StyledButton } from "./styles";

interface ResetPasswordProps {
  isRequesting: boolean;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  isRequesting = false,
}) => {
  return (
    <>
      <Input
        name="password"
        icon={FiLock}
        type="password"
        placeholder="Digite a nova senha"
        style={{ width: 300 }}
        autoComplete="on"
        enabled={!isRequesting}
      />

      <StyledButton type="submit" enabled={!isRequesting}>
        {isRequesting ? <Loading size={2} /> : "Salvar"}
      </StyledButton>
    </>
  );
};

export default ResetPassword;
