import React from "react";
import * as S from "./styles";
import Img from "../../assets/fifith_section.svg";
import Img2 from "../../assets/fifith_section_2.svg";
import ImgDetail from "../../assets/green_line.svg";
import ImgDetail2 from "../../assets/blue_line.svg";

const FifithSection: React.FC = () => {
  return (
    <S.Container id="plans">
      <S.H2Custom>Escolha seu plano</S.H2Custom>
      <S.GridItems>
        <S.ItemsCards>
          <S.H3Custom className="yellow">Básico</S.H3Custom>
          <S.ListOptions>
            <li>Aulas e cursos ao vivo</li>
            <li>Teaser dos cursos</li>
            <li>Catalogação de aulas ao estilo Netflix</li>
            <li>Receita por cartão, pix e boleto</li>
            <li>Aulas grátis dentro do curso</li>
            <li>
              Avaliação de conteúdo aprendido (que pode ser interativo, no meio
              do vídeo)
            </li>
            <li>Controle administrativo de vendas</li>
            <li>
              E-mails de agradecimento de compra, cadastro e andando de carrinho
            </li>
            <li>Domínio próprio Venda de cursos/conteúdos extras</li>
            <li>Emissão de certificado</li>
            <li>Customização Google analytics</li>
            <li>Cupom de desconto</li>
            <li>Mensalidade fixa</li>
            <li>Divisão de conteúdo por turma ou faixa de idade</li>
          </S.ListOptions>
        </S.ItemsCards>
        <S.ItemsCards>
          <S.H3Custom className="green">Essencial</S.H3Custom>
          <S.ListOptions>
            <li>Aulas e cursos ao vivo</li>
            <li>Teaser dos cursos</li>
            <li>Catalogação de aulas ao estilo Netflix</li>
            <li>Receita por cartão, pix e boleto</li>
            <li>Aulas grátis dentro do curso</li>
            <li>
              Avaliação de conteúdo aprendido (que pode ser interativo, no meio
              do vídeo)
            </li>
            <li>Controle administrativo de vendas</li>
            <li>
              E-mails de agradecimento de compra, cadastro e andando de carrinho
            </li>
            <li>Domínio próprio Venda de cursos/conteúdos extras</li>
            <li>Emissão de certificado</li>
            <li>Customização Google analytics</li>
            <li>Cupom de desconto</li>
            <li>Mensalidade fixa</li>
            <li>Divisão de conteúdo por turma ou faixa de idade</li>
          </S.ListOptions>
          <S.ImgDetail src={ImgDetail} />
          <S.ImgSeal src={Img} />
        </S.ItemsCards>
        <S.ItemsCards>
          <S.H3Custom className="blue">Avançado</S.H3Custom>
          <S.ListOptions>
            <li>Aulas e cursos ao vivo</li>
            <li>Teaser dos cursos</li>
            <li>Catalogação de aulas ao estilo Netflix</li>
            <li>Receita por cartão, pix e boleto</li>
            <li>Aulas grátis dentro do curso</li>
            <li>
              Avaliação de conteúdo aprendido (que pode ser interativo, no meio
              do vídeo)
            </li>
            <li>Controle administrativo de vendas</li>
            <li>
              E-mails de agradecimento de compra, cadastro e andando de carrinho
            </li>
            <li>Domínio próprio Venda de cursos/conteúdos extras</li>
            <li>Emissão de certificado</li>
            <li>Customização Google analytics</li>
            <li>Cupom de desconto</li>
            <li>Mensalidade fixa</li>
            <li>Divisão de conteúdo por turma ou faixa de idade</li>
          </S.ListOptions>
          <S.ImgDetail src={ImgDetail2} />
          <S.ImgSeal src={Img} />
          <S.ImgSeal src={Img2} />
        </S.ItemsCards>
      </S.GridItems>
    </S.Container>
  );
};

export default FifithSection;
