import React, { useEffect, useState, useCallback } from "react";

import apiV2 from "../../../services/apiV2";

import { Category as CategoryInterface } from "../../../models/AuthModels";

import CategoryContainer from "../../../components/Mols/CategoryContainer";
import MainSlider from "../../../components/Mols/MainSlider";
import MainSliderSkeleton from "../../../components/Mols/Shimmer/MainSlider";

import { Container } from "./styles";

const Home: React.FC = () => {
  const [categories, setCategories] = useState<any>([]);
  const [slideImgs, setSlideImgs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllCategories = useCallback(async (url: string) => {
    setIsLoading(true);

    const response = await apiV2.get<any>(`/course?schoolid=${url}`);
    setCategories(response.data.courses);
    setSlideImgs(response.data.banners);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let urlAtual =
      process.env.NODE_ENV === "development"
        ? `${process.env.REACT_APP_WHITELABEL}`
        : window.location.href;
    let splitUrlProd = urlAtual.split(".");
    let subdomain = splitUrlProd[0].includes("www")
      ? ["", splitUrlProd[1]]
      : splitUrlProd[0].split("//");

    slideImgs.length === 0 &&
      getAllCategories(subdomain[1].toLocaleUpperCase());
  }, []);

  return (
    <Container>
      {slideImgs.length > 0 ? (
        <MainSlider slideImgs={slideImgs} />
      ) : (
        <MainSliderSkeleton />
      )}
      <CategoryContainer categories={categories} isLoading={isLoading} />
    </Container>
  );
};

export default Home;
