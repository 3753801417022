import styled from "styled-components";

interface BackgroundProps {
  backImg?: string;
}

export const ImgContainer = styled.div<BackgroundProps>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: ${(props) =>
    props.backImg
      ? `linear-gradient(360deg, rgba(15, 15, 15, 0.4) 0%, rgba(15, 15, 15, 0) 40%), url(${props.backImg});`
      : "#0f0f0f"};
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
`;

export const SliderMaskWrap = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg,
    rgb(15, 15, 15) 22%,
    rgb(226, 226, 226) 77%
  );

  div.keen-slider {
    min-height: 55vh;

    @media (max-width: 979px) {
      min-height: 15vh;
    }
  }
`;
