export const convertToBRLDate = (dateString: string, includeTime = false): string => {
  const parts = dateString.split(' ');

  const dayMonthYear = parts[1].split('-');
  const day = dayMonthYear[0];
  const month = dayMonthYear[1];
  const year = dayMonthYear[2];
  const time = includeTime ? parts[2] : '';

  const brlDate = `${day}/${month}/${year} ${time}`;

  return brlDate;
};
