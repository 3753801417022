import React, { useRef, useState, useCallback } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';
import { useAuth } from "../../../../../hooks/auth";
import parse from 'html-react-parser';
import { CourseSeasonMovie, Course } from "../../../../../models/CourseModels";

import imageSIM from "../../../../../assets/images/certificate/SIM.png";
import printerIcon from "../../../../../assets/icons/printer.png";

import {
  Container,
  CertificadoMessageContainer,
  OrangeContainer,
} from './styles';

interface CertificateModalProps {
//   isAddingNote?: boolean;
//   actualTime: {
//     playedSeconds: number;
//     played: number;
//     loadedSeconds: number;
//     loaded: number;
//   };
//   addNoteInputRef: Ref<HTMLInputElement>;
  setShowCertificate(state: boolean): void;
  courseDetails?: Course;
//   setIsPlaying(state: boolean): void;
//   handleAddNote(action: string): void;
}

const CertificateModal: React.FC<CertificateModalProps> = ({
                                                          setShowCertificate,
                                                          courseDetails,
                                                        }) => {

  const [print, setPrint] = useState(false);


// var parse = require('html-react-parser');
// var a=parse('<img>');

let newDate = new Date()
let day = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

  const { user } = useAuth();
//   const { team } = useTeam();

  const certificateRef = useRef(null);

  const printer = useReactToPrint({
    content: () => certificateRef.current,
    copyStyles: false,
    pageStyle: `@media print {
          @page {
            size: 842px 595px;
            margin: 0;
          }
        }`,

  });
  const handlePrint = useCallback(() => {
    setPrint(true);
    printer && printer();
  }, [printer]);

  return (
    <>



      <Container>
{/*         <h1> */}

{/*         </h1> */}

{/*         <button */}
{/*                                                                                    className="forgot" */}
{/*                                                                                    type="button" */}
{/*                                                                                    onClick={() => setShowCertificate(false)} */}
{/*                                                                                  > */}
{/*                                                                                    Fechar */}
{/*                                                                                  </button> */}



{/*         <CertificadoMessageContainer ref={certificateRef}> */}
         <OrangeContainer ref={certificateRef} print={print}>



<div
      style={{
        alignItems: "center",margin: "0px auto",
                                                              display: "flex",
                                                              flexWrap: "wrap",
                                                          height: "100%",
                                                          width: "100%"
      }}
    >

    <style>
            {`
              @media print {
                body {
                  -webkit-print-color-adjust: exact !important; /* Ensure exact color printing */
                  font-size: 16px !important; /* Base font size for print */
                  color: black; /* Fallback color */
                  margin: 0; /* Reset margins for print */
                  padding: 0; /* Reset padding for print */
                }
                .header {
                  font-size: 24px !important; /* Header size */
                  background-color: lightblue; /* Example background color */
                  -webkit-print-color-adjust: exact !important; /* Preserve background color */
                }
                p {
                  font-size: 22px !important; /* Paragraph size */
                  margin: 0; /* Remove margins */
                }
                .no-print {
                  display: none; /* Hide elements not needed in print */
                }
              }
            `}
          </style>
     {courseDetails!.schoolid==="PROPAGA" && (
        <div style={{             margin: "0px auto",
                                                                                                                     display: "flex",
                                                                                                                     flexWrap: "wrap",
                                                                                                                 height: "100%",
                                                                                                                 width: "100%",
                                                                                                                }}>

                                                                                                                 <div
                                                                                                                                                        style={{
                                                                                                                                                         backgroundImage:
                                                                                                                                                                                                                                                                   'url("https://nl-files.s3.sa-east-1.amazonaws.com/certificados/sim/certificado_sim_v1.png")',
                                                                                                                                                                                                                                                                   backgroundSize: "contain",
                                                                                                                                                                                                                                                                   backgroundRepeat: "no-repeat",
                                                                                                                                                          position: "absolute",
                                                                                                                                                           width: "100%",
                                                                                                                                                           height: "100%"
                                                                                                                                                        }}
                                                                                                                                                      >

                                                                                                                                                      </div>

                                   <div
                                                                          style={{
                                                                            position: "absolute",
                                                                             width: "71%",
                                                                            top: "54.7%",
                                                                            left: "50%",
                                                                            transform: "translate(-50%, -50%)",
                                                                          }}
                                                                        >
                                                                         <span>
                                                                                                                                                                <p style={{ whiteSpace: 'pre-line' }}>
                                                                                                                                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{user.fullname}, por haver concluído com êxito o curso PROPAGA Básico,
                                                                                                                                                                  com carga horária de 40 horas através da Plataforma EAD,
                                                                                                                                                                  organizado pelo Seminário Teológico Pentecostal do Brasil (SETEPEB), Mogi-Guaçu – SP.
                                                                                                                                                                </p>
                                                                                                                                                              </span>
{/*                                                                                       <span> */}
{/*                                                                                         <p style={{ whiteSpace: 'pre-line' }}> */}
{/*                                                                                           {user.fullname}, por haver concluído com êxito o curso {courseDetails!.title!}, */}
{/*                                                                                           com carga horária de {courseDetails!.totalhours!} {courseDetails!.totalhours! > 1 ? "horas ":"hora "} */}
{/*                                                                                           através da Plataforma EAD. */}
{/*                                                                                         </p> */}
{/*                                                                                       </span> */}
                                                                        </div>


                                                                          <div
                                                                                                                                                  style={{
                                                                                                                                                    position: "absolute",
                                                                                                                                                     width: "100%",
                                                                                                                                                    top: "72%",
                                                                                                                                                    left: "116.3%",
                                                                                                                                                    transform: "translate(-50%, -50%)",
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                 <span>
                                                                                                                                                                                                                                        <p style={{ whiteSpace: 'pre-line' }}>
                                                                                                                                                                                                                                         , {day}/{month}/{year}.
                                                                                                                                                                                                                                        </p>
                                                                                                                                                                                                                                      </span>
                                                                        {/*                                                                                       <span> */}
                                                                        {/*                                                                                         <p style={{ whiteSpace: 'pre-line' }}> */}
                                                                        {/*                                                                                           {user.fullname}, por haver concluído com êxito o curso {courseDetails!.title!}, */}
                                                                        {/*                                                                                           com carga horária de {courseDetails!.totalhours!} {courseDetails!.totalhours! > 1 ? "horas ":"hora "} */}
                                                                        {/*                                                                                           através da Plataforma EAD. */}
                                                                        {/*                                                                                         </p> */}
                                                                        {/*                                                                                       </span> */}
                                                                                                                                                </div>

                                                                        </div>

                                                                        )}

                                                                         {courseDetails!.schoolid==="MARTINBUCER" && courseDetails!.courseid.includes("COBT") && (
                                                                              <div style={{             margin: "0px auto",
                                                                                                               display: "flex",
                                                                                                               flexWrap: "wrap",
                                                                                                           height: "100%",
                                                                                                           width: "100%",
                                                                                                          }}>

                                                                                                           <div
                                                                                                                                                  style={{
                                                                                                                                                   backgroundImage:
                                                                                                                                                                                                                                                             'url("https://nl-files.s3.sa-east-1.amazonaws.com/certificados/martinbucer/martinbucer_v3.png")',
                                                                                                                                                                                                                                                             backgroundSize: "contain",
                                                                                                                                                                                                                                                             backgroundRepeat: "no-repeat",
                                                                                                                                                    position: "absolute",
                                                                                                                                                     width: "100%",
                                                                                                                                                     height: "100%"
                                                                                                                                                  }}
                                                                                                                                                >

                                                                                                                                                </div>



<div
                                                                                                                                                  style={{
                                                                                                                                                  textAlign: "center",
                                                                                                                                                    position: "absolute",
                                                                                                                                                     width: "100%",
                                                                                                                                                    top: "41%",
                                                                                                                                                    left: "0%",
                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                              <span>
                                                                                                                                                                <p style={{  fontSize: '16px'  }}>
                                                                                                                                                                  {user.fullname}
                                                                                                                                                                </p>
                                                                                                                                                              </span>
                                                                                                                                                </div>


                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                    top: "49%",
                                                                                                                                                                                                                                                                                                    left: "52%",
                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                              <span>
                                                                                                                                                                                                                                                                                                                <p style={{  fontSize: '12px'  }}>
                                                                                                                                                                                                                                                                                                                  {user.documentNumber}
                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                </div>


                                                                                                                                                                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    top: "90.5%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    left: "46.8%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <span>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  <p style={{  fontSize: '12px'  }}>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  000{courseDetails!.certificateUserNumber}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>

                                                                                                                                                </div>

                                                                                                                                                )}








                                                                                                                                                {courseDetails!.schoolid==="MARTINBUCER" && courseDetails!.courseid==="CursoDeGrego" &&(
                                                                                                                                                                                                                              <div style={{             margin: "0px auto",
                                                                                                                                                                                                                                                               display: "flex",
                                                                                                                                                                                                                                                               flexWrap: "wrap",
                                                                                                                                                                                                                                                           height: "100%",
                                                                                                                                                                                                                                                           width: "100%",
                                                                                                                                                                                                                                                          }}>

                                                                                                                                                                                                                                                           <div
                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                   backgroundImage:
                                                                                                                                                                                                                                                                                                                                                                                                             'url("https://nl-files.s3.sa-east-1.amazonaws.com/certificados/martinbucer/certificado_grego_v1.png")',
                                                                                                                                                                                                                                                                                                                                                                                                             backgroundSize: "contain",
                                                                                                                                                                                                                                                                                                                                                                                                             backgroundRepeat: "no-repeat",
                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                     height: "100%"
                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                >

                                                                                                                                                                                                                                                                                                </div>



                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                  textAlign: "center",
                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                    top: "41%",
                                                                                                                                                                                                                                                                                                    left: "0%",
                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                              <span>
                                                                                                                                                                                                                                                                                                                <p style={{  fontSize: '16px'  }}>
                                                                                                                                                                                                                                                                                                                  {user.fullname}
                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                </div>


                                                                                                                                                                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    top: "49%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    left: "52%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                                              <span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                <p style={{  fontSize: '12px'  }}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  {user.documentNumber}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>


                                                                                                                                                                                                                                                                                                                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    top: "90.5%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    left: "46.8%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <span>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  <p style={{  fontSize: '12px'  }}>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  000{courseDetails!.certificateUserNumber}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>

                                                                                                                                                                                                                                                                                                </div>

                                                                                                                                                                                                                                                                                                )}


                                                                                                                                                                                                                                                                                                 {courseDetails!.schoolid==="MARTINBUCER" && courseDetails!.courseid==="CursoDeHebraico" &&(
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <div style={{             margin: "0px auto",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               display: "flex",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               flexWrap: "wrap",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           height: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          }}>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           <div
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   backgroundImage:
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             'url("https://nl-files.s3.sa-east-1.amazonaws.com/certificados/martinbucer/certificado_hebraico_v1.png")',
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             backgroundSize: "contain",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             backgroundRepeat: "no-repeat",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     height: "100%"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                >

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>



                                                                                                                                                                                                                                                                                                                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  textAlign: "center",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    top: "41%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    left: "0%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <p style={{  fontSize: '16px'  }}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  {user.fullname}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>


                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    top: "49%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    left: "52%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <p style={{  fontSize: '12px'  }}>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  {user.documentNumber}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>


                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                <div
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  style={{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    position: "absolute",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     width: "100%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    top: "90.5%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    left: "46.8%",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    transform: "translate(0%, 0%)",
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              <span>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  <p style={{  fontSize: '12px'  }}>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  000{courseDetails!.certificateUserNumber}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </p>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              </span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </div>

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )}
    </div>


 <FiPrinter onClick={handlePrint} size={40} color="#ff3c00" />
          </OrangeContainer>
{/*         </CertificadoMessageContainer> */}
 <span>
                                                                                        <p style={{ whiteSpace: 'pre-line' }}>

                                                                                        </p>
                                                                                      </span>
      </Container>
    </>
  );
};

export default CertificateModal;
