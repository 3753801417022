import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import styled from 'styled-components';

export const AccordionContainer = styled(Accordion)`
  display: flex;
  align-items: center;
  flex-direction: column;

  &.MuiCollapse-entered,
  .MuiCollapse-root {
    width: 100%;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  width: fit-content;
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  margin: 1rem;
  margin-top: 0;
  padding: 1rem;
  background-color: #dedcdc;
  border-radius: 4px;
`;
