export const convertSecondsToHoursMinutesSeconds = (
  seconds: number,
): string => {
  // StringExemple: '1000000'
  const measuredTime = new Date();

  measuredTime.setHours(0);
  measuredTime.setMinutes(0);
  measuredTime.setMilliseconds(0);
  measuredTime.setSeconds(seconds);

  const hours =
    measuredTime.getHours() >= 10
      ? measuredTime.getHours()
      : `0${measuredTime.getHours()}`;
  const minutes =
    measuredTime.getMinutes() >= 10
      ? measuredTime.getMinutes()
      : `0${measuredTime.getMinutes()}`;
  const secondds =
    measuredTime.getSeconds() >= 10
      ? measuredTime.getSeconds()
      : `0${measuredTime.getSeconds()}`;

  return `${hours}:${minutes}:${secondds}`;
};

export const convertSecondsToHoursMinutesSecondsVimeo = (
  seconds: number,
): string => {
  // 2021-03-22T09:10:00-03:00
  // StringExemple: '1000000'
  const measuredTime = new Date();

  measuredTime.setHours(0);
  measuredTime.setMinutes(0);
  measuredTime.setMilliseconds(0);
  measuredTime.setSeconds(seconds);

  const hours =
    measuredTime.getHours() >= 10
      ? measuredTime.getHours()
      : `0${measuredTime.getHours()}`;
  const minutes =
    measuredTime.getMinutes() >= 10
      ? measuredTime.getMinutes()
      : `0${measuredTime.getMinutes()}`;
  const secondds =
    measuredTime.getSeconds() >= 10
      ? measuredTime.getSeconds()
      : `0${measuredTime.getSeconds()}`;

  return `${hours}h${minutes}m${secondds}s`;
};

export const formatTime = (time: string): string => {
  // StringExemple: '24:05:10'
  const formattedTime = time.split(':');
  let hours;
  let minutes;
  let seconds;

  if (formattedTime.length === 2) {
    [minutes] = formattedTime;
    [, seconds] = formattedTime;
  }
  if (formattedTime.length === 3) {
    [hours] = formattedTime;
    [, minutes] = formattedTime;
    [, , seconds] = formattedTime;
  }

  const finalTime =
    hours && Number(hours) !== 0
      ? `${hours}:${minutes}:${seconds}`
      : `${minutes}:${seconds}`;
  return finalTime;
};

export const convertHoursMinutesSecondsToSeconds = (time: string): number => {
  // StringExemple: '24:05:10'
  if (time) {
    const [
//     hours,
    minutes, seconds] = time.split(':');
    const totalSeconds =
//       Number(hours) * 60 * 60 +
      Number(minutes) * 60 + Number(seconds);

    return totalSeconds;
  }
  return 0;
};

export const convertStringDateToDate = (date: string): Date => {
  // StringExemple: '1998-04-05 24:05:10'
  const [datestamp, timestamp] = date.split(' ');

  const [year, month, day] = datestamp.split('-');
  const [hour, minutes, seconds] = timestamp.split(':');

  const theDate = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hour),
    Number(minutes),
    Number(seconds),
  );
  return theDate;
};

export const getStringDateInfo = (date: string): string => {
  // 2021-03-24 13:30:00
  const [yearMonthDay, hour] = date.split(' ');
  const [year, month, day] = yearMonthDay.split('-');
  const [hours, minutes] = hour.split(':');

  const today = new Date();

  if (
    today.getDate() === Number(day) &&
    today.getMonth() + 1 === Number(month) &&
    today.getFullYear() === Number(year)
  ) {
    return `Hoje às ${hours}:${minutes}`;
  }
  return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

export const numberWeekDayToStringWeekDay = (value: number): string => {
  switch (value) {
    case 0:
      return 'Dom';
    case 1:
      return 'Seg';
    case 2:
      return 'Ter';
    case 3:
      return 'Qua';
    case 4:
      return 'Qui';
    case 5:
      return 'Sex';
    case 6:
      return 'Sáb';
    default:
      return '?';
  }
};

export const formatTimeStamp = (fromDate: string, toDate: string): string => {
  // StringExemple: 2021-03-22T09:10:00-03:00
  const [fDatestamp, fTimestamp] = fromDate.split('T');
  const [, tTimestamp] = toDate.split('T');

  const [fYear, fMonth, fDay] = fDatestamp.split('-');
  const [fHours, fMinutes] = fTimestamp.split(':');

  // const [tYear, tMonth, tDay] = tDatestamp.split('-');
  const [tHours, tMinutes] = tTimestamp.split(':');

  const timeDate = new Date(Number(fYear), Number(fMonth) - 1, Number(fDay));

  return `${numberWeekDayToStringWeekDay(
    timeDate.getDay(),
  )} - ${fDay}/${fMonth}/${fYear} das ${fHours}h${fMinutes} às ${tHours}h${tMinutes}`;
};

export const isToday = (date: Date): boolean => {
  if (!date) return false;

  const todayDate = new Date();
  const todayDay = String(todayDate.getDate()).padStart(2, '0');
  const todayMonth = String(todayDate.getMonth() + 1).padStart(2, '0');
  const todayYear = todayDate.getFullYear();

  const dateDay = String(date.getDate()).padStart(2, '0');
  const dateMonth = String(date.getMonth() + 1).padStart(2, '0');
  const dateYear = date.getFullYear();

  if (
    todayDay === dateDay &&
    todayMonth === dateMonth &&
    todayYear === dateYear
  ) {
    return true;
  }
  return false;
};

export const convertNumberToBRL = (number: number): string => {
  const f = number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return f;
};

export const getSubscriptionValue = (subscription_installments: number, number:number): string => {
//     number=number* 1.0459/subscription_installments;
  const f = number.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  if(number<=0){
    number=0;
    return number.toLocaleString('pt-br', {
             style: 'currency',
             currency: 'BRL',
           });
  } else{
    return `${subscription_installments}x de ${f}`;
  }
};


export const getPixDiscountPrice = (pixprice: number): string => {
//   price = price*((100-discount)/100);
  const f = pixprice.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

    return `${f} no pix`;

};

export const getPixDiscountPriceSubscription = (pixprice: number): string => {
//   price = price*((100-discount)/100);
  const f = pixprice.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

    return `ou ${f} no pix`;

};

export const getPixDiscountValue = (discount:string): string => {

    return `(${discount} de desconto)`;

};

export const getPriceCreditCardValue = (price:number, max_installments:number): string => {
    price=price * (max_installments>6 ? 1.0459 : 1)/(max_installments>0 ? max_installments : 12);
     const f = price.toLocaleString('pt-br', {
       style: 'currency',
       currency: 'BRL',
     });

     max_installments = (max_installments>0 ? max_installments : 12)

       return `ou ${max_installments}x de ${f}`;

};

