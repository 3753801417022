import styled, { css } from "styled-components";
import Button from "../Button";

interface ButtonsWrapper {
  isDragging?: boolean;
  hasImage: boolean;
  customChild?: boolean;
}

export const SimpleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24%;

  &.backgroundImg {
    width: 100%;
  }
`;

export const MultipleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonsWrapper = styled.div<ButtonsWrapper>`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  .imgButton {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 6rem;
    height: 6rem;

    border-radius: 1.875rem;
    border: solid 0.25rem var(--gray-200);
    margin-bottom: 0.5rem;
    background: transparent;

    &.backgroundImg {
      width: 100%;
      height: 12rem;
      padding: 0;
    }

    .backgroundImg {
      width: 120%;
      height: auto;
    }

    ${(props) =>
      props.customChild &&
      css`
        border: none;
        margin: 0;
        width: auto;
        height: auto;

        border-radius: unset;
      `}

    .image-item {
      display: flex;

      justify-content: center;
      align-items: center;

      .profileUrl {
        border-radius: 1.675rem;
      }
    }

    svg {
      position: absolute;

      bottom: 10px;
      right: 0.8251rem;
    }
  }

  ${(props) =>
    !props.hasImage &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;

export const StyledButton = styled(Button)`
  font-size: 0.625rem;
  font-family: "Raleway", sans-serif;
  font-weight: 800;

  overflow: hidden;

  .mini-img {
    border-radius: 0.25rem;
  }

  &.photo {
    width: 32%;
    height: 3rem;
    background: var(--dark-purple);
    border: solid 2px var(--dark-purple);
    border-radius: 0.25rem;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 1rem;
  }
`;
