import React, { useCallback, useEffect, useRef, useState } from 'react';
import Axios from 'axios';

import Cards, { Focused } from 'react-credit-cards';
import { FiX, FiArrowLeft, FiEdit } from 'react-icons/fi';
import * as Yup from 'yup';
import { mask as masker, unMask } from 'remask';
import { cpf } from 'cpf-cnpj-validator';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

import { useAuth } from '../../../hooks/auth';

import Loading from '../../Atoms/Loading';
import Input from '../../Atoms/Input';
import Dropdown from '../../Atoms/Dropdown';

import 'react-credit-cards/es/styles-compiled.css';

import { useCart } from '../../../hooks/cart';
import apiV2 from '../../../services/apiV2';
import { convertNumberToBRL } from '../../../utils/functions';

import {
  Container,
  ItensSection,
  QrArea,
  AddressWrapper,
  TitleSection,
  StyledButton,
} from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';

interface PaymentProps {
  method: string;
  cartId: string;
  stepBack(): void;
  closeCart(): void;
}

interface PixResponse {
  qrcode: string;
  transactionid: string;
}

export interface DataPay {
  cpf: string;
  cardNumber: string;
  cardName: string;
  exp: string;
  cvv: string;
  focused: Focused;
  CPF: string;
}

interface AddressItems {
  address?: string;
  number?: string;
  city?: string;
  state?: string;
  neighborhood?: string;
  zipcode?: string;
  complement?: string;
}

const initialInputValues: AddressItems = {
  address: '',
  number: '',
  city: '',
  state: '',
  neighborhood: '',
  zipcode: '',
  complement: '',
};

const Payment: React.FC<PaymentProps> = ({ closeCart, method, cartId, stepBack }) => {
  const [card_number, setNumb] = useState('');
  const [Nome_card, setNome_card] = useState('');
  const [Exp, setData] = useState('');
  const [CVV, setCVV] = useState('');
  const [CPF, setCPF] = useState('');
  const [focused, setFocused] = useState();
  const [qr, setQR] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [generatedQr, setGeneratedQr] = useState(false);
  const [selectedInstallments, setInstallments] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [userAddress, setUserAddress] = useState<AddressItems | undefined>();
  const [inputValues, setInputValues] = useState(initialInputValues);

  const { user } = useAuth();
  const { cartCourses, reset, toggleCart, generateKey, savePayment } = useCart();

  const formRef = useRef<FormHandles>(null);
  const formAddressRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: DataPay) => {
      setIsLoading(true);

      if (userAddress === undefined) {
        window.alert('Por favor, adicione um endereço.');

        setIsLoading(false);

        return;
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required('CPF obrigatório').min(10),
          cardNumber: Yup.number().required('Número do cartão obrigatório').min(13),
          cardName: Yup.string().required('Nome obrigatório').min(3),
          exp: Yup.string().required('Data de validade obrigatória').min(4),
          cvv: Yup.number().required('CVV obrigatório').min(0),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const isCartFree = cartCourses.total * 100 === 0;

        // eslint-disable-next-line no-param-reassign
        data.cpf = unMask(data.cpf);
        // eslint-disable-next-line no-param-reassign
        data.exp = unMask(data.exp);

        const savePaymentBody = {
          userid: user.userid,
          cartid: cartId,
          method: isCartFree ? 'free' : 'credit_card',
          amount: cartCourses.total * 100,
          coupons: cartCourses.coupons,
          couponid: cartCourses.coupon?.couponid ?? '',
          userfullname: user.username,
          useremail: user.email,
          user_cellphone: user.cellphone ?? '',
          user_documentNumber: user.documentNumber ?? '',
          user_birthdate: user.birthdate ?? '',
          credit_card_cpf: data.cpf,
          credit_card_name: data.cardName,
          credit_card_installments: selectedInstallments ?? 1,
          credit_card_number: data.cardNumber,
          credit_card_cvv: data.cvv,
          credit_card_expiration_date: data.exp,
        };

        const response = (await savePayment(savePaymentBody)) as AxiosResponse<string>;

        if (response.data === 'OK') {
          generateKey(true);
          window.alert('Seu pagamento foi confirmado e você receberá acesso aos cursos comprados.');
          toggleCart(false);
          reset();
        } else {
          window.alert(
            'Ocorreu um erro na confirmaçao de seu pagamento! Por favor, tente novamente mais tarde.',
          );
        }
      } catch (err) {
        window.alert('Por favor, preencha todas as informações do seu cartão!');
      }
      setIsLoading(false);
    },
    [
      cartId,
      user,
      cartCourses,
      reset,
      toggleCart,
      generateKey,
      savePayment,
      selectedInstallments,
      userAddress,
    ],
  );

  const handleSubmitCardSubscription = useCallback(
    async (data: DataPay) => {
      setIsLoading(true);

      if (userAddress === undefined) {
        window.alert('Por favor, adicione um endereço.');

        setIsLoading(false);

        return;
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required('CPF obrigatório').min(10),
          cardNumber: Yup.number().required('Número do cartão obrigatório').min(13),
          cardName: Yup.string().required('Nome obrigatório').min(3),
          exp: Yup.string().required('Data de validade obrigatória').min(4),
          cvv: Yup.number().required('CVV obrigatório').min(0),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const isCartFree = cartCourses.total * 100 === 0;

        // eslint-disable-next-line no-param-reassign
        data.cpf = unMask(data.cpf);
        // eslint-disable-next-line no-param-reassign
        data.exp = unMask(data.exp);

        const savePaymentBody = {
          userid: user.userid,
          cartid: cartId,
          method: isCartFree ? 'free' : 'credit_card_subscription',
          amount: cartCourses.total * 100,
          coupons: cartCourses.coupons,
          couponid: cartCourses.coupon?.couponid ?? '',
          userfullname: user.username,
          useremail: user.email,
          user_cellphone: user.cellphone ?? '',
          user_documentNumber: user.documentNumber ?? '',
          user_birthdate: user.birthdate ?? '',
          credit_card_cpf: data.cpf,
          credit_card_name: data.cardName,
          credit_card_installments: cartCourses?.courses[0].subscription_installments,
          credit_card_number: data.cardNumber,
          credit_card_cvv: data.cvv,
          credit_card_expiration_date: data.exp,
        };

        const response = (await savePayment(savePaymentBody)) as AxiosResponse<string>;

        if (response.data === 'OK') {
          generateKey(true);
          window.alert('Seu pagamento foi confirmado e você receberá acesso aos cursos comprados.');
          toggleCart(false);
          reset();
        } else {
          window.alert(
            'Ocorreu um erro na confirmaçao de seu pagamento! Por favor, tente novamente mais tarde.',
          );
        }
      } catch (err) {
        window.alert('Por favor, preencha todas as informações do seu cartão!');
      }
      setIsLoading(false);
    },
    [
      cartId,
      user,
      cartCourses,
      reset,
      toggleCart,
      generateKey,
      savePayment,
      selectedInstallments,
      userAddress,
    ],
  );

  const handleSubmitBoletoSubscription = useCallback(
    async (data: DataPay) => {
      setIsLoading(true);

      if (userAddress === undefined) {
        window.alert('Por favor, adicione um endereço.');

        setIsLoading(false);

        return;
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required('CPF obrigatório').min(10),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const isCartFree = cartCourses.total * 100 === 0;

        // eslint-disable-next-line no-param-reassign
        data.cpf = unMask(data.cpf);

        const savePaymentBody = {
          userid: user.userid,
          cartid: cartId,
          method: isCartFree ? 'free' : 'boleto_subscription',
          amount: cartCourses.total * 100,
          coupons: cartCourses.coupons,
          couponid: cartCourses.coupon?.couponid ?? '',
          userfullname: user.username,
          useremail: user.email,
          user_cellphone: user.cellphone ?? '',
          user_documentNumber: user.documentNumber ?? '',
          user_birthdate: user.birthdate ?? '',
          credit_card_cpf: data.cpf,
          credit_card_installments: cartCourses?.courses[0].subscription_installments,
        };

        const response = (await savePayment(savePaymentBody)) as AxiosResponse<string>;

        if (response.data === 'OK') {
          generateKey(true);
          window.alert(
            'Seus boletos foram gerados e enviados por e-mail. Você receberá acesso aos cursos comprados após o pagamento.',
          );
          toggleCart(false);
          reset();
        } else {
          window.alert('Ocorreu um erro nos boletos! Por favor, tente novamente mais tarde.');
        }
      } catch (err) {
        window.alert('Por favor, preencha todas as informações!');
      }
      setIsLoading(false);
    },
    [
      cartId,
      user,
      cartCourses,
      reset,
      toggleCart,
      generateKey,
      savePayment,
      selectedInstallments,
      userAddress,
    ],
  );

  const handleSubmitBoletoParcelado = useCallback(
    async (data: DataPay) => {
      setIsLoading(true);

      if (userAddress === undefined) {
        window.alert('Por favor, adicione um endereço.');

        setIsLoading(false);

        return;
      }

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required('CPF obrigatório').min(10),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const isCartFree = cartCourses.total * 100 === 0;

        // eslint-disable-next-line no-param-reassign
        data.cpf = unMask(data.cpf);

        const savePaymentBody = {
          userid: user.userid,
          cartid: cartId,
          method: isCartFree ? 'free' : 'boleto_parcelado',
          amount: cartCourses.total * 100,
          coupons: cartCourses.coupons,
          couponid: cartCourses.coupon?.couponid ?? '',
          userfullname: user.username,
          useremail: user.email,
          user_cellphone: user.cellphone ?? '',
          user_documentNumber: user.documentNumber ?? '',
          user_birthdate: user.birthdate ?? '',
          credit_card_cpf: data.cpf,
          credit_card_installments: selectedInstallments ?? 1,
        };

        const response = (await savePayment(savePaymentBody)) as AxiosResponse<string>;

        if (response.data === 'OK') {
          generateKey(true);
          window.alert(
            'Seus boletos foram gerados e enviados por e-mail. Você receberá acesso aos cursos comprados após o pagamento.',
          );
          toggleCart(false);
          reset();
        } else {
          window.alert('Ocorreu um erro nos boletos! Por favor, tente novamente mais tarde.');
        }
      } catch (err) {
        window.alert('Por favor, preencha todas as informações!');
      }
      setIsLoading(false);
    },
    [
      cartId,
      user,
      cartCourses,
      reset,
      toggleCart,
      generateKey,
      savePayment,
      selectedInstallments,
      userAddress,
    ],
  );

  const generatePixQr = useCallback(async () => {
    setIsLoading(true);
    try {
      if (userAddress === undefined) {
        window.alert('Por favor, adicione um endereço.');

        setIsLoading(false);

        return;
      }

      if (CPF === '') {
        window.alert('Por favor, adicione o seu CPF.');

        setIsLoading(false);

        return;
      }

      const isCartFree = cartCourses.total * 100 === 0;

      const savePaymentBody = {
        userid: user.userid,
        cartid: cartId,
        method: isCartFree ? 'free' : 'pix',
        amount:
          cartCourses &&
          cartCourses.courses.length > 0 &&
          cartCourses.total_pix &&
          cartCourses.total_pix > 0
            ? cartCourses.total_pix * 100
            : cartCourses.total * 100,
        user_cellphone: user.cellphone ?? '',
        user_documentNumber: user.documentNumber ?? '',
        user_birthdate: user.birthdate ?? '',
        coupons: cartCourses.coupons,
        couponid: cartCourses.coupon?.couponid ?? '',
        pix_cpf: CPF,
      };

      const response = (await savePayment(savePaymentBody)) as AxiosResponse<PixResponse>;

      if (!response) return;

      if (response.data) {
        setQR(response.data.qrcode);
        setTransactionId(response.data.transactionid);
        setGeneratedQr(true);
      }
    } catch (err) {
      toast.error('Erro ao gerar QR code :(');
    }

    setIsLoading(false);
  }, [cartId, user?.userid, cartCourses, savePayment, userAddress, CPF]);

  const handlePixPayment = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiV2.get<string>(
        `/cart/payment/verify/transaction?tid=${transactionId}`,
      );

      if (response.data === 'WAITING_PAYMENT') {
        toast.success('Caso já tenha pagado, espere alguns minutos e verifique novamente.');
      } else {
        toast.success('Pagamento efetuado com sucesso :)');
        toggleCart(false);
        reset();
      }
    } catch (err) {
      console.log(err);
      toast.error('Erro ao finalizar compra :(');
    }
    setIsLoading(false);
  }, [transactionId, reset, toggleCart]);

  const validarCPF = (CPFparam: string): { valido: boolean; texto: string } => {
    if (cpf.isValid(CPFparam) === true) {
      return { valido: true, texto: '' };
    }
    return { valido: false, texto: 'CPF Incorreto.' };
  };

  const retCard = (cardNumber: string): void => {
    if (cardNumber !== undefined) {
      if (cardNumber.length > 15) {
        setNumb(cardNumber.slice(0, -1));
      }
    }
  };

  const retCVV = (CVVparam: string): void => {
    if (CVVparam !== undefined) {
      if (CVV.length > 2) {
        setCVV(CVV.slice(0, -1));
      }
    }
  };

  const retData = (exp: string): void => {
    if (exp !== undefined) {
      if (exp.length > 3) {
        setData(exp.slice(0, -1));
      }
    }
  };

  const changeFocus = (e: any): void => {
    setFocused(e.target.id);
  };

  const getMaxInstallments = (max_installments: number): any => {
    const cartTotal = cartCourses.totalwithcouponapplied
      ? cartCourses.totalwithcouponapplied
      : cartCourses.total;

    const dropdownInstallmentsItemsNoTax = Array.from({ length: max_installments }).map(
      (i, index) => ({
        key: index + 1,
        value:
          index >= 6
            ? `${index + 1}x de ${convertNumberToBRL(
                (cartTotal * 1.0459) / (index + 1),
              )}`
            : `${index + 1}x de ${convertNumberToBRL(cartTotal / (index + 1))}`,
      }),
    );

    const finalInstallmentsArray = [...dropdownInstallmentsItemsNoTax].filter(i => !!i && i);

    return finalInstallmentsArray;
  };

  const getInstallments = (installments: number): any => {
    const cartTotal = cartCourses.totalwithcouponapplied
      ? cartCourses.totalwithcouponapplied
      : cartCourses.total;

    const dropdownInstallmentsItemsNoTax = Array.from({ length: 6 }).map((i, index) => ({
      key: index + 1,
      value: `${index + 1}x de ${convertNumberToBRL(cartTotal / (index + 1))}`,
    }));

    const dropdownInstallmentsItemsWithTax = Array.from({
      length: 12,
    }).map((i, index) => {
      if (index > 5) {
        return {
          key: index + 1,
          value: `${index + 1}x de ${convertNumberToBRL(
            (cartTotal * 1.0459) / (index + 1),
          )}`,
        };
      }
      return false;
    });

    const finalInstallmentsArray = [
      ...dropdownInstallmentsItemsNoTax,
      ...dropdownInstallmentsItemsWithTax,
    ].filter(i => !!i && i);

    return finalInstallmentsArray;
  };

  const getInstallmentsSubscritions = (installments: number): any => {
    const cartTotal = cartCourses.totalwithcouponapplied
      ? cartCourses.totalwithcouponapplied
      : cartCourses.total;

    const dropdownInstallmentsItemsNoTax = Array.from({ length: 1 }).map((i, index) => ({
      key: index + 1,
      value: `${installments}x de ${convertNumberToBRL(cartTotal)}`,
    }));

    const finalInstallmentsArray = [...dropdownInstallmentsItemsNoTax].filter(i => !!i && i);

    return finalInstallmentsArray;
  };

  const handleAddressSubmit = useCallback(
    async (data: any) => {
      setIsLoading(true);
      try {
        formAddressRef.current?.setErrors({});

        const schema = Yup.object().shape({
          address: Yup.string().required('Endereço Obrigatório!').min(3),
          number: Yup.string().required('Número Obrigatório!').min(1),
          city: Yup.string().required('Cidade Obrigatório!').min(3),
          state: Yup.string().required('Estado Obrigatório!').min(2),
          neighborhood: Yup.string().required('Bairro Obrigatório!').min(3),
          zipcode: Yup.string().required('CEP Obrigatório!').min(3),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const addressBody = {
          userid: user.userid,
          address: data.address,
          number: data.number,
          city: data.city,
          state: data.state,
          neighborhood: data.neighborhood,
          zipcode: data.zipcode.replace('.', ''),
          complement: data?.complement ? data.complement : '',
        };

        const response = await apiV2.post<string>('/user/address', addressBody);

        if (response.data === 'OK') {
          setIsAddress(false);
          window.alert('Endereço salvo!');
        } else if (response.data === 'ERROR') {
          window.alert('Ocorreu um erro ao salvar, por favor, tente novamente.');
        }
      } catch (err) {
        window.alert('Por favor, preencha todos os campos!');

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
      setIsLoading(false);
    },
    [user, user?.userid],
  );

  const getAddress = useCallback(async () => {
    setIsLoading(true);
    await apiV2.get(`/user/address?userid=${user.userid}`).then(response => {
      if (response.data !== 'Not found') {
        setUserAddress(response.data);
      }

      setIsLoading(false);
    });
  }, []);

  const handleCepValidationReq = (cepToValidate: string) => {
    let tratedCep = cepToValidate.replace('.', '').replace('-', '');

    Axios.get(`https://viacep.com.br/ws/${tratedCep}/json/`)
      .then((res: any) => {
        const { cep, uf, localidade, bairro, logradouro, erro } = res.data;
        if (erro) {
          window.alert('Cep não encontrado, tente movamente');
          return;
        }

        setInputValues({
          ...inputValues,
          address: logradouro,
          city: localidade,
          state: uf,
          neighborhood: bairro,
          zipcode: cep,
        });
      })
      .catch(() => {
        window.alert('Erro ao requisitar, por favor, tente novamente mais tarde.');
      });
  };

  const handleInputChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value,
    });

    if (name === 'zipcode' && value.length === 10) {
      handleCepValidationReq(value);
    }
  };

  useEffect(() => {
    getAddress();
  }, [isAddress, getAddress]);

  useEffect(() => {
    if (userAddress !== undefined) {
      setInputValues({
        ...inputValues,
        address: userAddress?.address,
        number: userAddress?.number,
        city: userAddress?.city,
        state: userAddress?.state,
        neighborhood: userAddress?.neighborhood,
        zipcode: userAddress?.zipcode,
        complement: userAddress?.complement,
      });
    }
  }, [isAddress, getAddress, userAddress, inputValues]);

  return (
    <Container className="teste">
      <TitleSection>
        <h2> Pagamento </h2>
        <FiX size={20} className="close_btn" onClick={closeCart} />
        <FiArrowLeft size={20} className="back_btn" onClick={stepBack} />
      </TitleSection>
      <ItensSection>
        {method === 'card' ? (
          <>
            {isAddress ? (
              <Form
                ref={formAddressRef}
                onSubmit={handleAddressSubmit}
                className="form big"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div className="group-info">
                  <Input
                    name="zipcode"
                    placeholder="CEP"
                    style={{ width: 60 }}
                    value={masker(inputValues.zipcode, ['99.999-999'])}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                  <Input
                    name="number"
                    placeholder="Número"
                    style={{ width: 60 }}
                    value={inputValues.number}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                </div>
                <Input
                  name="address"
                  placeholder="Rua / Avenida"
                  style={{ width: 300 }}
                  value={inputValues.address}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="neighborhood"
                  placeholder="Bairro"
                  style={{ width: 300 }}
                  value={inputValues.neighborhood}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="city"
                  placeholder="Cidade"
                  style={{ width: 300 }}
                  value={inputValues.city}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="state"
                  placeholder="Estado"
                  style={{ width: 300 }}
                  value={inputValues.state}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="complement"
                  placeholder="Complemento"
                  style={{ width: 300 }}
                  value={inputValues.complement}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <StyledButton type="submit" enabled={!isLoading}>
                  {isLoading ? <Loading size={1.6} /> : 'Salvar'}
                </StyledButton>
                <StyledButton
                  style={{
                    color: '#fff',
                    background: '#49baae',
                  }}
                  onClick={() => setIsAddress(false)}
                  type="button"
                  enabled={!isLoading}
                >
                  {isLoading ? <Loading size={1.6} /> : 'Cancelar'}
                </StyledButton>
                <br />
              </Form>
            ) : (
              <>
                <Cards
                  number={card_number}
                  name={Nome_card}
                  expiry={Exp}
                  cvc={CVV}
                  focused={focused}
                />
                {!isLoading ? (
                  <AddressWrapper>
                    {userAddress === undefined && (
                      <StyledButton
                        type="button"
                        onClick={() => setIsAddress(true)}
                        enabled={!isLoading}
                      >
                        Adicionar Endereço
                      </StyledButton>
                    )}
                    <p>
                      Endereço:{' '}
                      {userAddress !== undefined ? (
                        <>
                          {`${userAddress?.address}, ${userAddress?.number} - ${
                            userAddress?.city
                          } ${userAddress?.state} \n CEP: ${masker(userAddress?.zipcode, [
                            '99.999-999',
                          ])}`}
                          <FiEdit onClick={() => setIsAddress(true)} />
                        </>
                      ) : (
                        'Sem endereço cadastrado.'
                      )}
                    </p>
                  </AddressWrapper>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <Loading size={1.6} />
                  </div>
                )}
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="form big"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  autoComplete="off"
                >
                  <div className="dropdown-wrapper">
                    <Dropdown
                      arrowColor="#fff"
                      textColor="#fff"
                      backgroundCollor="#7a8095"
                      customFontSize={14}
                      customRadius={28}
                      customWidth={100}
                      customHeight={3.5}
                      heightScale="rem"
                      title={`1x de ${convertNumberToBRL(
                        cartCourses.totalwithcouponapplied
                          ? cartCourses.totalwithcouponapplied
                          : cartCourses.total,
                      )}`}
                      items={getMaxInstallments(
                        cartCourses?.courses.length > 0 &&
                          cartCourses?.courses[0].max_installments &&
                          cartCourses?.courses[0].max_installments > 0
                          ? cartCourses?.courses[0].max_installments
                          : 12,
                      )}
                      defaultValue={
                        getMaxInstallments(
                          cartCourses?.courses.length > 0 &&
                            cartCourses?.courses[0].max_installments &&
                            cartCourses?.courses[0].max_installments > 0
                            ? cartCourses?.courses[0].max_installments
                            : 12,
                        )[0]
                      }
                      isLoading={false}
                      size="smaller"
                      onChange={(e: any) => setInstallments(!e.length ? e.key : 1)}
                    />
                  </div>
                  <Input
                    name="cpf"
                    placeholder="CPF"
                    style={{ width: 300 }}
                    value={masker(CPF, ['999.999.999-99'])}
                    onChange={event => {
                      setCPF(unMask(event.target.value));
                    }}
                    onBlur={event => {
                      validarCPF(CPF);
                    }}
                    onFocus={changeFocus}
                  />
                  <Input
                    name="cardNumber"
                    placeholder="Número do cartão"
                    style={{ width: 300 }}
                    value={masker(card_number, ['9999999999999999'])}
                    onChange={event => {
                      retCard(card_number);
                      setNumb(event.target.value);
                    }}
                    onFocus={changeFocus}
                    autoComplete="off"
                  />
                  <Input
                    name="cardName"
                    placeholder="Nome"
                    style={{ width: 300 }}
                    value={Nome_card}
                    onChange={event => {
                      setNome_card(event.target.value);
                    }}
                    onFocus={changeFocus}
                    autoComplete="off"
                  />
                  <div className="group-info">
                    <Input
                      name="exp"
                      placeholder="Data de validade"
                      style={{ width: 60 }}
                      value={masker(Exp, ['99/99'])}
                      onChange={event => {
                        setData(unMask(event.target.value));
                        retData(Exp);
                      }}
                      onFocus={changeFocus}
                      autoComplete="off"
                    />
                    <Input
                      name="cvv"
                      placeholder="CVV"
                      style={{ width: 60 }}
                      value={masker(CVV, ['999'])}
                      onChange={event => {
                        setCVV(event.target.value);
                        retCVV(CVV);
                      }}
                      onFocus={changeFocus}
                      autoComplete="off"
                    />
                  </div>

                  <StyledButton type="submit" enabled={!isLoading}>
                    {isLoading ? (
                      <Loading size={1.6} />
                    ) : (
                      `Pagar  ${selectedInstallments}x de ${convertNumberToBRL(
                        cartCourses.totalwithcouponapplied
                          ? (cartCourses.totalwithcouponapplied *
                              (selectedInstallments > 6 ? 1.0459 : 1)) /
                              selectedInstallments
                          : (cartCourses.total * (selectedInstallments > 6 ? 1.0459 : 1)) /
                              selectedInstallments,
                      )}`
                    )}
                  </StyledButton>
                  <br />
                </Form>
              </>
            )}
          </>
        ) : method === 'credit_card_subscription' ? (
          <>
            {isAddress ? (
              <Form
                ref={formAddressRef}
                onSubmit={handleAddressSubmit}
                className="form big"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div className="group-info">
                  <Input
                    name="zipcode"
                    placeholder="CEP"
                    style={{ width: 60 }}
                    value={masker(inputValues.zipcode, ['99.999-999'])}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                  <Input
                    name="number"
                    placeholder="Número"
                    style={{ width: 60 }}
                    value={inputValues.number}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                </div>
                <Input
                  name="address"
                  placeholder="Rua / Avenida"
                  style={{ width: 300 }}
                  value={inputValues.address}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="neighborhood"
                  placeholder="Bairro"
                  style={{ width: 300 }}
                  value={inputValues.neighborhood}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="city"
                  placeholder="Cidade"
                  style={{ width: 300 }}
                  value={inputValues.city}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="state"
                  placeholder="Estado"
                  style={{ width: 300 }}
                  value={inputValues.state}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="complement"
                  placeholder="Complemento"
                  style={{ width: 300 }}
                  value={inputValues.complement}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <StyledButton type="submit" enabled={!isLoading}>
                  {isLoading ? <Loading size={1.6} /> : 'Salvar'}
                </StyledButton>
                <StyledButton
                  style={{
                    color: '#fff',
                    background: '#49baae',
                  }}
                  onClick={() => setIsAddress(false)}
                  type="button"
                  enabled={!isLoading}
                >
                  {isLoading ? <Loading size={1.6} /> : 'Cancelar'}
                </StyledButton>
                <br />
              </Form>
            ) : (
              <>
                <Cards
                  number={card_number}
                  name={Nome_card}
                  expiry={Exp}
                  cvc={CVV}
                  focused={focused}
                />
                {!isLoading ? (
                  <AddressWrapper>
                    {userAddress === undefined && (
                      <StyledButton
                        type="button"
                        onClick={() => setIsAddress(true)}
                        enabled={!isLoading}
                      >
                        Adicionar Endereço
                      </StyledButton>
                    )}
                    <p>
                      Endereço:{' '}
                      {userAddress !== undefined ? (
                        <>
                          {`${userAddress?.address}, ${userAddress?.number} - ${
                            userAddress?.city
                          } ${userAddress?.state} \n CEP: ${masker(userAddress?.zipcode, [
                            '99.999-999',
                          ])}`}
                          <FiEdit onClick={() => setIsAddress(true)} />
                        </>
                      ) : (
                        'Sem endereço cadastrado.'
                      )}
                    </p>
                  </AddressWrapper>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <Loading size={1.6} />
                  </div>
                )}
                <Form
                  ref={formRef}
                  onSubmit={handleSubmitCardSubscription}
                  className="form big"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  autoComplete="off"
                >
                  <div className="dropdown-wrapper">
                    <Dropdown
                      arrowColor="#fff"
                      textColor="#fff"
                      backgroundCollor="#7a8095"
                      customFontSize={14}
                      customRadius={28}
                      customWidth={100}
                      customHeight={3.5}
                      heightScale="rem"
                      title={
                        cartCourses && cartCourses?.courses.length > 0
                          ? `${
                              cartCourses?.courses[0].subscription_installments
                            }x de ${convertNumberToBRL(
                              cartCourses.totalwithcouponapplied
                                ? cartCourses.totalwithcouponapplied
                                : cartCourses.total,
                            )}`
                          : ''
                      }
                      items={getInstallmentsSubscritions(
                        cartCourses && cartCourses?.courses.length > 0
                          ? cartCourses?.courses[0].subscription_installments
                          : 1,
                      )}
                      defaultValue={
                        getInstallmentsSubscritions(
                          cartCourses && cartCourses?.courses.length > 0
                            ? cartCourses?.courses[0].subscription_installments
                            : 1,
                        )[0]
                      }
                      isLoading={false}
                      size="smaller"
                      onChange={(e: any) =>
                        setInstallments(
                          cartCourses && cartCourses?.courses.length > 0
                            ? cartCourses?.courses[0].subscription_installments
                            : 1,
                        )
                      }
                    />
                  </div>
                  <Input
                    name="cpf"
                    placeholder="CPF"
                    style={{ width: 300 }}
                    value={masker(CPF, ['999.999.999-99'])}
                    onChange={event => {
                      setCPF(unMask(event.target.value));
                    }}
                    onBlur={event => {
                      validarCPF(CPF);
                    }}
                    onFocus={changeFocus}
                  />
                  <Input
                    name="cardNumber"
                    placeholder="Número do cartão"
                    style={{ width: 300 }}
                    value={masker(card_number, ['9999999999999999'])}
                    onChange={event => {
                      retCard(card_number);
                      setNumb(event.target.value);
                    }}
                    onFocus={changeFocus}
                    autoComplete="off"
                  />
                  <Input
                    name="cardName"
                    placeholder="Nome"
                    style={{ width: 300 }}
                    value={Nome_card}
                    onChange={event => {
                      setNome_card(event.target.value);
                    }}
                    onFocus={changeFocus}
                    autoComplete="off"
                  />
                  <div className="group-info">
                    <Input
                      name="exp"
                      placeholder="Data de validade"
                      style={{ width: 60 }}
                      value={masker(Exp, ['99/99'])}
                      onChange={event => {
                        setData(unMask(event.target.value));
                        retData(Exp);
                      }}
                      onFocus={changeFocus}
                      autoComplete="off"
                    />
                    <Input
                      name="cvv"
                      placeholder="CVV"
                      style={{ width: 60 }}
                      value={masker(CVV, ['999'])}
                      onChange={event => {
                        setCVV(event.target.value);
                        retCVV(CVV);
                      }}
                      onFocus={changeFocus}
                      autoComplete="off"
                    />
                  </div>

                  <StyledButton type="submit" enabled={!isLoading}>
                    {isLoading ? (
                      <Loading size={1.6} />
                    ) : cartCourses && cartCourses?.courses.length > 0 ? (
                      `Assinar  ${
                        cartCourses?.courses[0].subscription_installments
                      }x de ${convertNumberToBRL(
                        cartCourses.totalwithcouponapplied
                          ? cartCourses.totalwithcouponapplied
                          : cartCourses.total,
                      )}`
                    ) : (
                      ''
                    )}
                  </StyledButton>
                  <br />
                </Form>
              </>
            )}
          </>
        ) : method === 'boleto_subscription' ? (
          <>
            {isAddress ? (
              <Form
                ref={formAddressRef}
                onSubmit={handleAddressSubmit}
                className="form big"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div className="group-info">
                  <Input
                    name="zipcode"
                    placeholder="CEP"
                    style={{ width: 60 }}
                    value={masker(inputValues.zipcode, ['99.999-999'])}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                  <Input
                    name="number"
                    placeholder="Número"
                    style={{ width: 60 }}
                    value={inputValues.number}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                </div>
                <Input
                  name="address"
                  placeholder="Rua / Avenida"
                  style={{ width: 300 }}
                  value={inputValues.address}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="neighborhood"
                  placeholder="Bairro"
                  style={{ width: 300 }}
                  value={inputValues.neighborhood}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="city"
                  placeholder="Cidade"
                  style={{ width: 300 }}
                  value={inputValues.city}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="state"
                  placeholder="Estado"
                  style={{ width: 300 }}
                  value={inputValues.state}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="complement"
                  placeholder="Complemento"
                  style={{ width: 300 }}
                  value={inputValues.complement}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <StyledButton type="submit" enabled={!isLoading}>
                  {isLoading ? <Loading size={1.6} /> : 'Salvar'}
                </StyledButton>
                <StyledButton
                  style={{
                    color: '#fff',
                    background: '#49baae',
                  }}
                  onClick={() => setIsAddress(false)}
                  type="button"
                  enabled={!isLoading}
                >
                  {isLoading ? <Loading size={1.6} /> : 'Cancelar'}
                </StyledButton>
                <br />
              </Form>
            ) : (
              <>
                {!isLoading ? (
                  <AddressWrapper>
                    {userAddress === undefined && (
                      <StyledButton
                        type="button"
                        onClick={() => setIsAddress(true)}
                        enabled={!isLoading}
                      >
                        Adicionar Endereço
                      </StyledButton>
                    )}
                    <p>
                      Endereço:{' '}
                      {userAddress !== undefined ? (
                        <>
                          {`${userAddress?.address}, ${userAddress?.number} - ${
                            userAddress?.city
                          } ${userAddress?.state} \n CEP: ${masker(userAddress?.zipcode, [
                            '99.999-999',
                          ])}`}
                          <FiEdit onClick={() => setIsAddress(true)} />
                        </>
                      ) : (
                        'Sem endereço cadastrado.'
                      )}
                    </p>
                  </AddressWrapper>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <Loading size={1.6} />
                  </div>
                )}
                <Form
                  ref={formRef}
                  onSubmit={handleSubmitBoletoSubscription}
                  className="form big"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  autoComplete="off"
                >
                  <div className="dropdown-wrapper">
                    <Dropdown
                      arrowColor="#fff"
                      textColor="#fff"
                      backgroundCollor="#7a8095"
                      customFontSize={14}
                      customRadius={28}
                      customWidth={100}
                      customHeight={3.5}
                      heightScale="rem"
                      title={
                        cartCourses && cartCourses?.courses.length > 0
                          ? `${
                              cartCourses?.courses[0].subscription_installments
                            }x de ${convertNumberToBRL(
                              cartCourses.totalwithcouponapplied
                                ? cartCourses.totalwithcouponapplied
                                : cartCourses.total,
                            )}`
                          : ''
                      }
                      items={getInstallmentsSubscritions(
                        cartCourses && cartCourses?.courses.length > 0
                          ? cartCourses?.courses[0].subscription_installments
                          : 1,
                      )}
                      defaultValue={
                        getInstallmentsSubscritions(
                          cartCourses && cartCourses?.courses.length > 0
                            ? cartCourses?.courses[0].subscription_installments
                            : 1,
                        )[0]
                      }
                      isLoading={false}
                      size="smaller"
                      onChange={(e: any) =>
                        setInstallments(
                          cartCourses && cartCourses?.courses.length > 0
                            ? cartCourses?.courses[0].subscription_installments
                            : 1,
                        )
                      }
                    />
                  </div>
                  <Input
                    name="cpf"
                    placeholder="CPF"
                    style={{ width: 300 }}
                    value={masker(CPF, ['999.999.999-99'])}
                    onChange={event => {
                      setCPF(unMask(event.target.value));
                    }}
                    onBlur={event => {
                      validarCPF(CPF);
                    }}
                    onFocus={changeFocus}
                  />

                  <StyledButton type="submit" enabled={!isLoading}>
                    {isLoading ? (
                      <Loading size={1.6} />
                    ) : cartCourses && cartCourses?.courses.length > 0 ? (
                      `Assinar  ${
                        cartCourses?.courses[0].subscription_installments
                      }x de ${convertNumberToBRL(
                        cartCourses.totalwithcouponapplied
                          ? cartCourses.totalwithcouponapplied
                          : cartCourses.total,
                      )}`
                    ) : (
                      ''
                    )}
                  </StyledButton>
                  <br />
                </Form>
              </>
            )}
          </>
        ) : method === 'boleto_parcelado' ? (
          <>
            {isAddress ? (
              <Form
                ref={formAddressRef}
                onSubmit={handleAddressSubmit}
                className="form big"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div className="group-info">
                  <Input
                    name="zipcode"
                    placeholder="CEP"
                    style={{ width: 60 }}
                    value={masker(inputValues.zipcode, ['99.999-999'])}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                  <Input
                    name="number"
                    placeholder="Número"
                    style={{ width: 60 }}
                    value={inputValues.number}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                </div>
                <Input
                  name="address"
                  placeholder="Rua / Avenida"
                  style={{ width: 300 }}
                  value={inputValues.address}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="neighborhood"
                  placeholder="Bairro"
                  style={{ width: 300 }}
                  value={inputValues.neighborhood}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="city"
                  placeholder="Cidade"
                  style={{ width: 300 }}
                  value={inputValues.city}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="state"
                  placeholder="Estado"
                  style={{ width: 300 }}
                  value={inputValues.state}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="complement"
                  placeholder="Complemento"
                  style={{ width: 300 }}
                  value={inputValues.complement}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <StyledButton type="submit" enabled={!isLoading}>
                  {isLoading ? <Loading size={1.6} /> : 'Salvar'}
                </StyledButton>
                <StyledButton
                  style={{
                    color: '#fff',
                    background: '#49baae',
                  }}
                  onClick={() => setIsAddress(false)}
                  type="button"
                  enabled={!isLoading}
                >
                  {isLoading ? <Loading size={1.6} /> : 'Cancelar'}
                </StyledButton>
                <br />
              </Form>
            ) : (
              <>
                {!isLoading ? (
                  <AddressWrapper>
                    {userAddress === undefined && (
                      <StyledButton
                        type="button"
                        onClick={() => setIsAddress(true)}
                        enabled={!isLoading}
                      >
                        Adicionar Endereço
                      </StyledButton>
                    )}
                    <p>
                      Endereço:{' '}
                      {userAddress !== undefined ? (
                        <>
                          {`${userAddress?.address}, ${userAddress?.number} - ${
                            userAddress?.city
                          } ${userAddress?.state} \n CEP: ${masker(userAddress?.zipcode, [
                            '99.999-999',
                          ])}`}
                          <FiEdit onClick={() => setIsAddress(true)} />
                        </>
                      ) : (
                        'Sem endereço cadastrado.'
                      )}
                    </p>
                  </AddressWrapper>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <Loading size={1.6} />
                  </div>
                )}
                <Form
                  ref={formRef}
                  onSubmit={handleSubmitBoletoParcelado}
                  className="form big"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  autoComplete="off"
                >
                  <div className="dropdown-wrapper">
                    <Dropdown
                      arrowColor="#fff"
                      textColor="#fff"
                      backgroundCollor="#7a8095"
                      customFontSize={14}
                      customRadius={28}
                      customWidth={100}
                      customHeight={3.5}
                      heightScale="rem"
                      title={`1x de ${convertNumberToBRL(
                        cartCourses.totalwithcouponapplied
                          ? cartCourses.totalwithcouponapplied
                          : cartCourses.total,
                      )}`}
                      items={getMaxInstallments(
                        cartCourses?.courses.length > 0 &&
                          cartCourses?.courses[0].max_installments &&
                          cartCourses?.courses[0].max_installments > 0
                          ? cartCourses?.courses[0].max_installments
                          : 12,
                      )}
                      defaultValue={
                        getMaxInstallments(
                          cartCourses?.courses.length > 0 &&
                            cartCourses?.courses[0].max_installments &&
                            cartCourses?.courses[0].max_installments > 0
                            ? cartCourses?.courses[0].max_installments
                            : 12,
                        )[0]
                      }
                      isLoading={false}
                      size="smaller"
                      onChange={(e: any) => setInstallments(!e.length ? e.key : 1)}
                    />
                  </div>
                  <Input
                    name="cpf"
                    placeholder="CPF"
                    style={{ width: 300 }}
                    value={masker(CPF, ['999.999.999-99'])}
                    onChange={event => {
                      setCPF(unMask(event.target.value));
                    }}
                    onBlur={event => {
                      validarCPF(CPF);
                    }}
                    onFocus={changeFocus}
                  />

                  <StyledButton type="submit" enabled={!isLoading}>
                    {isLoading ? (
                      <Loading size={1.6} />
                    ) : (
                      `Pagar  ${selectedInstallments}x de ${convertNumberToBRL(
                        cartCourses.totalwithcouponapplied
                          ? (cartCourses.totalwithcouponapplied *
                              (selectedInstallments > 6 ? 1.0459 : 1)) /
                              selectedInstallments
                          : (cartCourses.total * (selectedInstallments > 6 ? 1.0459 : 1)) /
                              selectedInstallments,
                      )}`
                    )}
                  </StyledButton>
                  <br />
                </Form>
              </>
            )}
          </>
        ) : (
          <>
            {isAddress ? (
              <Form
                ref={formAddressRef}
                onSubmit={handleAddressSubmit}
                className="form big"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div className="group-info">
                  <Input
                    name="zipcode"
                    placeholder="CEP"
                    style={{ width: 60 }}
                    value={masker(inputValues.zipcode, ['99.999-999'])}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                  <Input
                    name="number"
                    placeholder="Número"
                    style={{ width: 60 }}
                    value={inputValues.number}
                    onChange={e => handleInputChanges(e)}
                    onFocus={changeFocus}
                  />
                </div>
                <Input
                  name="address"
                  placeholder="Rua / Avenida"
                  style={{ width: 300 }}
                  value={inputValues.address}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="neighborhood"
                  placeholder="Bairro"
                  style={{ width: 300 }}
                  value={inputValues.neighborhood}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="city"
                  placeholder="Cidade"
                  style={{ width: 300 }}
                  value={inputValues.city}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="state"
                  placeholder="Estado"
                  style={{ width: 300 }}
                  value={inputValues.state}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <Input
                  name="complement"
                  placeholder="Complemento"
                  style={{ width: 300 }}
                  value={inputValues.complement}
                  onChange={e => handleInputChanges(e)}
                  onFocus={changeFocus}
                />
                <StyledButton type="submit" enabled={!isLoading}>
                  {isLoading ? <Loading size={1.6} /> : 'Salvar'}
                </StyledButton>
                <StyledButton
                  style={{
                    color: '#fff',
                    background: '#49baae',
                  }}
                  onClick={() => setIsAddress(false)}
                  type="button"
                  enabled={!isLoading}
                >
                  {isLoading ? <Loading size={1.6} /> : 'Cancelar'}
                </StyledButton>
                <br />
              </Form>
            ) : (
              <>
                {!isLoading ? (
                  <>
                    <AddressWrapper>
                      {userAddress === undefined && (
                        <StyledButton
                          type="button"
                          onClick={() => setIsAddress(true)}
                          enabled={!isLoading}
                        >
                          Adicionar Endereço
                        </StyledButton>
                      )}
                      <p>
                        Endereço:{' '}
                        {userAddress !== undefined ? (
                          <>
                            {`${userAddress?.address}, ${userAddress?.number} - ${
                              userAddress?.city
                            } ${userAddress?.state} \n CEP: ${masker(userAddress?.zipcode, [
                              '99.999-999',
                            ])}`}
                            <FiEdit onClick={() => setIsAddress(true)} />
                          </>
                        ) : (
                          'Sem endereço cadastrado.'
                        )}
                      </p>
                    </AddressWrapper>
                    {!generatedQr && (
                      <Form
                        onSubmit={() => {}}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                        autoComplete="off"
                      >
                        <Input
                          name="pix_cpf"
                          placeholder="CPF"
                          style={{ width: 300 }}
                          value={masker(CPF, ['999.999.999-99'])}
                          onChange={event => {
                            setCPF(unMask(event.target.value));
                          }}
                          onBlur={event => {
                            validarCPF(CPF);
                          }}
                          onFocus={changeFocus}
                        />
                      </Form>
                    )}
                  </>
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <Loading size={1.6} />
                  </div>
                )}
                <QrArea>
                  <div className="total">
                    <strong>Total:</strong>
                    <p className="price">
                      {convertNumberToBRL(
                        cartCourses && cartCourses.courses.length > 0
                          ? cartCourses.totalwithcouponapplied
                            ? cartCourses.totalwithcouponapplied
                            : cartCourses.total_pix && cartCourses.total_pix > 0
                            ? cartCourses.total_pix
                            : cartCourses.total
                          : 0,
                      )}
                    </p>
                  </div>

                  {generatedQr ? (
                    <>
                      <QRCode value={qr} size={256} />
                      <p
                        style={{
                          width: '60%',
                          textAlign: 'center',
                          marginTop: '10px',
                        }}
                      >
                        Este código é valido durante as próximas 24 horas, após este período não
                        será mais possível realizar o pagamento
                      </p>
                      <StyledButton onClick={handlePixPayment} enabled={!isLoading}>
                        {isLoading ? <Loading size={1.6} /> : 'Já paguei!'}
                      </StyledButton>
                    </>
                  ) : (
                    <StyledButton onClick={generatePixQr} enabled={!isLoading}>
                      {isLoading ? <Loading size={1.6} /> : 'Gerar QR Code'}
                    </StyledButton>
                  )}
                </QrArea>
              </>
            )}
          </>
        )}
      </ItensSection>
    </Container>
  );
};

export default Payment;
