import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
} from "react";
import { FiEdit } from "react-icons/fi";
import ImageUploading, { ImageListType } from "react-images-uploading";

import Compress from "browser-image-compression";

import { SimpleContainer, ButtonsWrapper, StyledButton } from "./styles";

type ImageUploadProps = {
  setImg(b64Img: string): void;
  children?: ReactNode | (Element & ReactNode);
  customChild: boolean;
  bannerStyle: boolean;
};

export type SimpleHandles = {
  handleClearImgs: () => void;
  images: ImageListType;
};

const Simple: ForwardRefRenderFunction<SimpleHandles, ImageUploadProps> = (
  { setImg, customChild = false, bannerStyle = false, children },
  ref
) => {
  const [images, setImages] = useState<ImageListType>([]);

  const handleClearImgs = (): void => {
    setImages([]);
  };

  useImperativeHandle(ref, () => ({
    handleClearImgs,
    images,
  }));

  const getBase64 = (file: File, options: any): any =>
    new Promise((resolve, reject) => {
      Compress(file, options).then((compressedBlob) => {
        const convertedBlobFile = new File([compressedBlob], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });
        const reader = new FileReader();

        reader.readAsDataURL(convertedBlobFile);
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    });

  const onChange = (imageList: ImageListType): void => {
    const options = {
      maxSizeMB: 1,
      useWebWorker: true,
    };

    if (imageList.length > 0 && imageList[0].file) {
      getBase64(imageList[0].file, options).then((result: string) => {
        const [, formatedBase64Url] = result.split("base64,");
        setImg(formatedBase64Url);
      });
    }
    setImages(imageList as never[]);
  };

  return (
    <ImageUploading value={images} onChange={onChange}>
      {({ imageList, onImageUpload, isDragging, dragProps }) => (
        <SimpleContainer className={bannerStyle ? "backgroundImg" : ""}>
          <ButtonsWrapper
            hasImage={imageList.length > 0}
            customChild={customChild}
          >
            <StyledButton
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              type="button"
              className={bannerStyle ? "imgButton backgroundImg" : "imgButton"}
              {...dragProps}
            >
              {customChild ? (
                <>{children}</>
              ) : (
                <>
                  {imageList.length > 0 ? (
                    images[0].dataURL && (
                      <>
                        {bannerStyle ? (
                          <img
                            src={images[0].dataURL}
                            alt="profileImg"
                            className="backgroundImg"
                          />
                        ) : (
                          <img
                            src={images[0].dataURL}
                            alt="profileImg"
                            width={96}
                            height={96}
                            className="profileUrl"
                          />
                        )}
                      </>
                    )
                  ) : bannerStyle ? (
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAMFBMVEXx8/XCy9K/yND09vfw8vTP1tzp7O/i5ure4+fO1dvJ0dfT2d/EzNPt7/Lb4OXo6+4FeM7UAAAFL0lEQVR4nO2c24KrIAxFLdha7///t0dxOlWDSiAKztnrbR4G6SoJBKHZA6zJYncgQeCEAicUOKHACQVOKHBCgRMKnFDghAInFDihwAkFTihwQoETCpxQ4IQCJxQ4ocAJBU4ocEKBEwqcUOCEAicUOKHACQVOKHBCgRMKnFDghAInFDihwAkFTihwQoETCpxQ4IQCJxQ4ocAJBU4ot3Oi1KMq64FnWTVq+EueWzlRquqKVn/J+/ezEfdyHydKPYtc62yF1m1Xymq5ixPVdDnx8eslf1eCVu7hRFXFppAfLW39kNJyByeqOTJirGTvRsbKDZyozsHIpKUQsZK8E1Vu55GTrKTuRL0ZRoyVLviZaTtRVctUMuaVOnCoJO1E1WwjxsorbGZO2Qk7br5WuhApKTvpfZWMy5WAoZKuk6b1NhI4VJJ10uRBSsas0ng+OlUnVaARw9NvqCTqRERJpt9eUtJ0IqPEN36SdNIIKRnIPeafFJ0Ep9c5mr+qTdFJ2CRMpLAn5fScqJeokrFWZkoRdaImwtpw2T9iSnnxuiDoRFXda6hK28JzWTA14ryBxKFlTT9iTlT1W57o3Lta96yED8krRieknCw/DDuEP1TnKBlgzMlCTtZDXr+8pIjOwitK5x7JOKFD3mukiE85ix45S5FxYll46prdiv8ekpsU19wv4kS9LV1ouQPlrPzKliIzTuw9YDYiVfgFSxFx8rR+wcyMomSX9HYpTjlFwonqrB3gBc/JyYQjRcRJYe8Ay4l9rMlLcVi8iTjp7Y/nOBHcMjngWEoi4+TUlcmKw9rnxHzCWMqeU/ltkB9JEZl3SusnYmwQn1fm2GgPeiOzZrM9WZfu/3/BNDznYATLOLENffep+JppeMZBMSZUF9N6ljFM7KF3qpTduBZyQj4W53XTiRsEm1L2dr2k9k9W9Rtjq2BrJj9Zyk7pI7bP9lw8kfH+4KIFLGF77Sa3R90Un0POvHNCcYzsLVMk9+2buni1bd9xjMSJHMPmjCz7zov/fidW5GQ7OS/2e8BoRrLtrBfXScTIMVLsk09cJxEjZ8I6+cR1EmG1tsRaDsZ0EjlyDL0leuxOpulD4JTALtfXORRbnqVO1LDOePdtpoclWPsqulL+wt0P0SNnxFKrrp2opmuXl+5OuHA3PSmByDGQ9ezSydYdM+ELd4YUIsdANnoWTva2RSUv3JlnJRE5I2RbY+6kee1+dTrrhC7cPTZeMUdivZnydaIc3tdqqWuI6USOYZlSfp0oxzVlJxNByUSOYZlSPk6cDzqEXy17JDTn/LBMKRlTSRZ4X2giep2zZnEwZHLiGjifFt6BTtKKHMMspUxO2BkvDzoDm1jkGGa7bsaJx0t9XfgrOfuMlhezwsc48RrKufvhyiXXHatg8T2Zkm0eHzluxO8W4pXHKljkXycBt3h9blFdeqyCx2fPOguLbn6qTWsBu+Czxs/CopsdP4kmkx+mcZ8FRrfuWUqSTSYT005keDucW4iXnzRhMg17iYacC6A0VyZzzIQs0pBrUrn22JoXY4Us0pDjaZMzb+dIMX6/Qi0dHSU0XHySz48heqSaOs60vsvlq2mtpzj9OCh/Trgjew7afgLar63d6ec2SmTZm37+UyV7048K+Gmkm7O10A/8aaSbY7sEr8rYvYoNnX4Sr3EuYJVpVc35Ccu/innZbryMJ1n4v9f4N9FZ39XPZ931GYzMGH9VPHYfAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADp8Q9+nG9anuOrfAAAAABJRU5ErkJggg=="
                      alt="default img"
                      width={180}
                      height={120}
                      className="backgroundImg"
                    />
                  ) : (
                    <img
                      src="https://nextlevelimagesprofile.s3-sa-east-1.amazonaws.com/defaultUser.png"
                      alt="default user"
                      width={96}
                      height={96}
                      className="profileUrl"
                    />
                  )}
                  <FiEdit size={20} />
                </>
              )}
            </StyledButton>
          </ButtonsWrapper>
        </SimpleContainer>
      )}
    </ImageUploading>
  );
};

export default forwardRef(Simple);
