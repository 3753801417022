import styled from 'styled-components';

export const Container = styled.div`
  .teste {
    display: flex;
    button {
      margin-left: auto;
    }
  }

  margin-bottom: 5%;
`;
