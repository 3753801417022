import React from "react";

import AwesomeSlider from "react-awesome-slider";

import CourseContainer from "../../CourseContainer";
import Skeleton from "../../../Skeleton";

import {
  Container,
  CategoryTitle,
  CarouselWrapper,
} from "../../CategoryContainer/styles";

const CategoryCourses: React.FC = () => (
  <Container>
    <CategoryTitle>
      <Skeleton className="category-title" />
    </CategoryTitle>
    <CarouselWrapper>
      <CourseContainer courses={[]} isLoading />
    </CarouselWrapper>
  </Container>
);

export default CategoryCourses;
