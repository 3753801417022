import styled, { css, keyframes } from 'styled-components';

interface SelectedIconContainerProps {
  isWatching?: boolean;
}
const comeFromLeft = keyframes`
  from {
    transform: translateX(-200px)
  }

  to {
    transform: translateX(0px)
  }
`;

const outFromRight = keyframes`
  from {
    transform: translateX(0px)
  }

  to {
    transform: translateX(-200px)
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  margin-bottom: 2px;
`;

export const VideoCardWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 8px 0;

  font-family: 'Roboto', sans-serif;
  color: #e9eaed;

  transition: 0.4s;

  .video-thumb {
    width: 76px;
    height: 43px;

    border-radius: 4px;
  }

  &:hover {
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
`;

export const SelectedIconContainer = styled.div<SelectedIconContainerProps>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 22px;
  width: 22px;

  padding: 0;
  margin: 0;
  transition: 0.4s;

  margin-right: 10px !important;

  ${(props) =>
    props.isWatching
      ? css`
          opacity: 1;
          animation: ${comeFromLeft} 0.4s;
        `
      : css`
          opacity: 0;
          animation: ${outFromRight} 0.4s;
        `}

  svg {
    width: 22px !important;
    height: 22px !important;
  }

  .checked-container {
    position: absolute;
    display: flex;

    top: 14px;
    left: 20px;

    z-index: 3;
  }
`;

export const Thumb = styled.div`
  position: relative;
`;

export const AnnotationIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  margin-bottom: 4px;
  margin-left: 4px;

  img {
    width: 22%;
  }
`;

export const Time = styled.div`
  position: absolute;
  bottom: 4px;
  right: 0;

  p {
    font-size: 12px;
    padding: 4px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
`;

export const StyledProgressBar = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
`;

export const VideoInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-left: 12px;
  margin-top: -6px;

  h3 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0 !important;
  }
  p {
    font-size: 10px;
  }
`;
