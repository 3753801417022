export const getHeaderTabs = (
  role: string,
  exclusive?: boolean,
): Array<{ key: string; value: string }> => {
  if (!exclusive) {
    switch (role) {
      case 'courses':
        return [
          { key: 'courses', value: 'Home' },
          { key: 'liveclasses', value: 'Ao Vivo' },
          { key: 'doubts', value: 'Plantão' },
          { key: 'finance', value: 'Minhas Compras' },
        ];
      case 'noDoubts':
        return [
          { key: 'courses', value: 'Home' },
          { key: 'liveclasses', value: 'Ao Vivo' },
          { key: 'finance', value: 'Minhas Compras' },
        ];
      case 'onlyCourses':
        return [
          { key: 'courses', value: 'Home' },
          { key: 'finance', value: 'Minhas Compras' },
        ];
      case 'onlyLiveClasses':
        return [{ key: 'liveclasses', value: 'Ao Vivo' }];
      case 'coursesAndLiveClasses':
        return [
          { key: 'courses', value: 'Home' },
          { key: 'liveclasses', value: 'Ao Vivo' },
          { key: 'finance', value: 'Minhas Compras' },
        ];
      case 'coursesAndLiveClassesShowCart':
        return [
          { key: 'courses', value: 'Home' },
          { key: 'liveclasses', value: 'Ao Vivo' },
          { key: 'finance', value: 'Minhas Compras' },
        ];
      case 'admin':
        return [{ key: 'admin', value: 'Minhas Vendas' }];
      default:
        return [
          { key: 'liveclasses', value: 'Ao Vivo' },
          { key: 'doubts', value: 'Plantão' },
          { key: 'finance', value: 'Minhas Compras' },
        ];
    }
  }
  return [];
};

export const getUserRole = (profileId: string, levelId?: string, schoolId?: string): string => {
  switch (schoolId) {
    case 'BINDEMY':
      return 'onlyCourses';
    case 'RAIZDAQUESTAO':
      return 'onlyCourses';
    case 'ESCOLAIMPULSE':
      return 'onlyCourses';
    case 'POLOSUNIP':
      return '';
    case 'UNIPSAOJOAO':
      return 'coursesAndLiveClasses';
    case 'CURSO':
      return 'onlyCourses';
    case 'SETEPEBARAPIRACA':
      return 'onlyLiveClasses';
    case 'SETEPEBETAP':
      return 'onlyLiveClasses';
    case 'NOVASDEPAZ':
      return 'onlyLiveClasses';
    case 'IBEM':
    case 'Cursos':
      return 'onlyLiveClasses';

    case 'SEDI':
    case 'CGA':
    case 'MARTINBUCER':
    case 'PROPAGA':
      return 'onlyCourses';
    case 'SETEPEB':
      return 'onlyLiveClasses';
    case 'SELETIVO':
      return 'onlyLiveClasses';
    case 'XLR8ACADEMY':
      return 'onlyCourses';
    case 'SIM':
      return 'onlyCourses';
    default:
      switch (profileId) {
        case 'Parent':
          return 'noDoubts';
        default:
          switch (levelId) {
            case '2Ano':
            case '3Ano':
            case '4Ano':
            case '5Ano':
              return 'noCourses';
            default:
              return 'courses';
          }
      }
  }
};

export const getSpecialUser = (schoolid: string): string => {
  switch (schoolid) {
    case 'SETEPEBARAPIRACA':
      return '/liveclasses';
    case 'NOVASDEPAZ':
      return '/liveclasses';
    case 'IBEM':
      return '/liveclasses';
    case 'SETEPEB':
      return '/liveclasses';
    case 'SETEPEBETAP':
      return '/liveclasses';
    case 'CURSO':
    case 'SEDI':
    case 'CGA':
    case 'MARTINBUCER':
    case 'Cursos':
      return '/courses';
    default:
      return '/';
  }
};
