import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { FaChevronDown, FaRegDotCircle } from 'react-icons/fa';

import Loading from '../Loading';

import {
  DropdownWrapper,
  DropdownHeader,
  DropdownHeaderTitle,
  DropdownHeaderAction,
  ItemsList,
  SelectedItems,
  CustomTitleWrapper,
  CustomTitleFirstText,
  CustomTitleSecondText,
} from './styles';
import { DropdownProps, Item } from './types';

const Dropdown: React.FC<DropdownProps> = ({
  title,
  items = [],
  multiSelect = false,
  customTitle,
  textColor,
  arrowColor,
  backgroundCollor,
  customRadius,
  customHeight,
  customWidth,
  heightScale = 'px',
  fullHeight = false,
  defaultValue,
  placeholder = false,
  isLoading = false,
  size = '',
  customFontSize,
  onChange = () => console.log('default'),
  leftPosition,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState<Item[]>([{ key: '', value: '' }]);

  const firstSelectedItem = useMemo<Item>(() => {
    if (defaultValue && selection[0] && selection[0].key === '') {
      return defaultValue;
    }
    return { key: '', value: '' };
  }, [defaultValue, selection]);

  const dropdownContentWrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      dropdownContentWrapperRef.current &&
      !dropdownContentWrapperRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);

  const handleOnClick = useCallback(
    (item: Item) => {
      if (!selection.some(current => current.key === item.key)) {
        if (!multiSelect) {
          setSelection([item]);
          onChange(item);
        } else {
          setSelection([...selection, item]);
          onChange([...selection, item]);
        }
      } else {
        let selectionAfterRemoval = selection;
        selectionAfterRemoval = selectionAfterRemoval.filter(current => current.key !== item.key);
        setSelection([...selectionAfterRemoval]);
        onChange(selectionAfterRemoval);
      }
      setOpen(false);
    },
    [multiSelect, selection, onChange],
  );

  const isItemSelected = useCallback(
    (item: Item) => selection.find(current => current.key === item.key),
    [selection],
  );

  const getDistanceFromBottom = (): number => {
    const dropdownItemList = document.getElementById('dropdown-item-list');

    let space = 0;

    if (dropdownItemList) {
      space =
        dropdownItemList.getBoundingClientRect().bottom -
        dropdownItemList.getBoundingClientRect().bottom * 0.15;
    }

    return space;
  };

  const path = window.location.pathname;
  useEffect(() => {
    if (
      defaultValue &&
      firstSelectedItem.key !== '' &&
      firstSelectedItem.key !== selection[0].key
    ) {
      setSelection([firstSelectedItem]);
      onChange(firstSelectedItem);
    }
  }, [firstSelectedItem, onChange, selection, defaultValue, placeholder]);

  useEffect(() => {
    setSelection([{ key: '', value: '' }]);
  }, [path]);

  return (
    <DropdownWrapper
      ref={dropdownContentWrapperRef}
      customWidth={customWidth && customWidth}
      customHeight={customHeight && customHeight}
      heightScale={heightScale}
      className={`className ${open ? 'dropdown-open' : 'dropdown-closed'}`}
      style={{ marginBottom: '2px' }}
      {...props}
    >
      <DropdownHeader
        tabIndex={0}
        role="button"
        onKeyPress={() => setOpen(!open)}
        onClick={() => setOpen(!open)}
        isLoading={isLoading}
        backgroundColor={backgroundCollor && backgroundCollor}
        customWidth={customWidth && customWidth}
        customHeight={customHeight && customHeight}
        heightScale={heightScale}
        customRadius={customRadius && customRadius}
        customFontSize={customFontSize && customFontSize}
      >
        <DropdownHeaderTitle
          customFontSize={customFontSize && customFontSize}
          hasValue={selection.length > 0 && selection[0].key !== ''}
          textColor={textColor || 'rbga(255,255,255,0.4)'}
        >
          {!isLoading && !multiSelect && (
            <>
              {customTitle?.hasPromo ? (
                <CustomTitleWrapper>
                  <p>
                    <CustomTitleFirstText>{customTitle.oldValueTitle}</CustomTitleFirstText>
                    {'  '}
                    <CustomTitleSecondText>{customTitle.newValueTitle}</CustomTitleSecondText>
                  </p>
                </CustomTitleWrapper>
              ) : (
                <p>
                  {selection.length > 0 && selection[0].key !== ''
                    ? selection[0].value
                    : `${title}`}
                </p>
              )}
            </>
          )}
          {!isLoading &&
            multiSelect &&
            (selection.length > 0 ? (
              selection.map(item => <SelectedItems key={item.key}>{item.value}</SelectedItems>)
            ) : (
              <p>{title}</p>
            ))}
          {isLoading && !multiSelect && (
            <div>
              <Loading size={1} />
            </div>
          )}
        </DropdownHeaderTitle>
        <DropdownHeaderAction open={open} arrowColor={arrowColor}>
          <FaChevronDown className="chevron" size={18} />
        </DropdownHeaderAction>
      </DropdownHeader>
      <ItemsList
        id="dropdown-item-list"
        fullHeight={fullHeight && getDistanceFromBottom()}
        size={size}
        leftPosition={leftPosition}
      >
        {items.map((item: any) => (
          <li key={`${item.key}-${item.value}`}>
            <button type="button" onClick={() => handleOnClick(item)}>
              <span>{item.value}</span>
              <span>{isItemSelected(item) && <FaRegDotCircle size={10} />}</span>
            </button>
          </li>
        ))}
      </ItemsList>
    </DropdownWrapper>
  );
};

export default Dropdown;
