import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import nlLogo from '../assets/images/nliconshaddow.png';
import novasdepazLogo from '../assets/images/logo_novasdepaz.png';
import ibemLogo from '../assets/images/logo_ibem.png';
import cursoLogo from '../assets/images/nextLevel.png';
import setepebLogo from '../assets/images/logo_setepeb.png';
import seletivoLogo from '../assets/images/seletivo.png';
import sediLogo from '../assets/images/sediLogo.png';
import martinBucerLogo from '../assets/images/martinBucerLogo.png';
import martinBucerLogoFavicon from '../assets/images/martinBucerLogoFavicon.png';
import simLogo from '../assets/images/simLogo2.png';
import propagaLogo from '../assets/images/propaga_branco.png';
import raiz from '../assets/images/raiz.png';
import impulse from '../assets/images/impulse3.png';
import unipsaojoao from '../assets/images/logo_unipsaojoao.png';
import obj from '../assets/images/obj.png';
import xrl8Logo from '../assets/images/xrl8_logo.png';

interface InterfaceInitialRouteContext {
  hasHome: boolean;
  isBindemy?: boolean;
  subdomainUrlImg: string;
  subdomainName: string;
}

export const InitialRouteContext = createContext({} as InterfaceInitialRouteContext);

type InitialRouteContextProviderProps = {
  children: ReactNode;
};

export function InitialRouteContextProvider({ children }: InitialRouteContextProviderProps) {
  const [hasHome, setHasHome] = useState<boolean>(true);
  const [isBindemy, setIsBindemy] = useState<boolean>(false);
  const [subdomainUrlImg, setSubdomainUrlImg] = useState<string>('');
  const [subdomainName, setSubdomainName] = useState<string>('');

  function addNewFaviconAndTitle(url: string, title: string) {
    const favicon: any = document.getElementById('favicon');
    favicon.href = url;
    document.title = title;
  }

  useEffect(() => {
    let urlAtual =
      process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_WHITELABEL}`
        : window.location.href;
    let splitUrlProd = urlAtual.split('.');
    let subdomain = splitUrlProd[0].includes('www')
      ? ['', splitUrlProd[1]]
      : splitUrlProd[0].split('//');

    setSubdomainName(subdomain[1]);

    switch (subdomain[1]) {
      case 'seletivo':
        // a pagina inicial é login
        setHasHome(false);
        setSubdomainUrlImg(seletivoLogo);
        addNewFaviconAndTitle(seletivoLogo, 'Seletivo');
        break;
      case 'setepeb':
        // a pagina inicial é login
        setHasHome(false);
        setSubdomainUrlImg(setepebLogo);
        addNewFaviconAndTitle(setepebLogo, 'Setepeb');
        break;
      case 'setepebrecife':
        // a pagina inicial é o login
        setHasHome(false);
        setSubdomainUrlImg(setepebLogo);
        addNewFaviconAndTitle(setepebLogo, 'Setepeb | Recife');
        break;
      case 'sedi':
        // a pagina inicial é a home default
        setSubdomainUrlImg(sediLogo);
        addNewFaviconAndTitle(sediLogo, 'Seminário Digital');
        break;
      case 'cga':
        // a pagina inicial é a home default
        setSubdomainUrlImg(nlLogo);
        addNewFaviconAndTitle(nlLogo, 'CGA');
        break;
      case 'martinbucer':
        // a pagina inicial é a home default
        setSubdomainUrlImg(martinBucerLogo);
        addNewFaviconAndTitle(martinBucerLogoFavicon, 'Martin Bucer');
        break;
      case 'curso':
        // a pagina inicial é o login
        setSubdomainUrlImg(cursoLogo);
        setHasHome(false);
        addNewFaviconAndTitle(cursoLogo, 'Curso');
        break;
      case 'novasdepaz':
        // a pagina inicial é o login
        setSubdomainUrlImg(novasdepazLogo);
        setHasHome(false);
        addNewFaviconAndTitle(novasdepazLogo, 'Novas de Paz');
        break;
      case 'ibem':
        // a pagina inicial é o login
        setSubdomainUrlImg(ibemLogo);
        setHasHome(false);
        addNewFaviconAndTitle(ibemLogo, 'Ibem');
        break;
      case 'setepebarapiraca':
        // a pagina inicial é o login
        setHasHome(false);
        setSubdomainUrlImg(setepebLogo);
        addNewFaviconAndTitle(setepebLogo, 'Setepeb | Arapiraca');
        break;

      case 'setepebetap':
        // a pagina inicial é o login
        setHasHome(false);
        setSubdomainUrlImg(setepebLogo);
        addNewFaviconAndTitle(setepebLogo, 'Setepeb | Etap');
        break;
      case 'raizdaquestao':
        setHasHome(true);
        setSubdomainUrlImg(raiz);
        addNewFaviconAndTitle(raiz, 'Raiz da Questão');
        break;
      case 'escolaimpulse':
        setHasHome(false);
        setSubdomainUrlImg(impulse);
        addNewFaviconAndTitle(impulse, 'Escola Impulse');
        break;
      case 'sim':
        setHasHome(false);
        setSubdomainUrlImg(simLogo);
        addNewFaviconAndTitle(simLogo, 'SIM');
        break;
      case 'unipsaojoao':
        // a pagina inicial é o login
        setHasHome(false);
        setSubdomainUrlImg(unipsaojoao);
        addNewFaviconAndTitle(unipsaojoao, 'Unip São João');
        break;

      case 'polosunip':
        setHasHome(true);
        setSubdomainUrlImg(unipsaojoao);
        addNewFaviconAndTitle(unipsaojoao, 'Pólos UNIP');
        break;
      case 'objetivomogi':
        setHasHome(true);
        setSubdomainUrlImg(obj);
        addNewFaviconAndTitle(obj, 'Objetivo Mogi');
        break;
      case 'xlr8academy':
        setHasHome(true);
        setSubdomainUrlImg(xrl8Logo);
        addNewFaviconAndTitle(xrl8Logo, 'XLR8 Academy');
        break;
      case 'propaga':
        setHasHome(true);
        setSubdomainUrlImg(propagaLogo);
        addNewFaviconAndTitle(propagaLogo, 'PROPAGA');
        break;
      default:
        setIsBindemy(true);
        setSubdomainUrlImg(nlLogo);
        break;
    }
  }, []);

  return (
    <InitialRouteContext.Provider value={{ hasHome, isBindemy, subdomainUrlImg, subdomainName }}>
      {children}
    </InitialRouteContext.Provider>
  );
}

export const useInitialRoute = () => {
  return useContext(InitialRouteContext);
};
