import React from "react";

import { Container } from "./styles";

interface TooltipProps {
  title?: string;
  className?: string;
  customStyle?: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  className = "",
  title,
  ...rest
}) => (
  <Container className={className}>
    {children}
    <span {...rest}>{title}</span>
  </Container>
);

export default Tooltip;
