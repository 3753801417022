import React, { useMemo, useLayoutEffect, useRef, useState } from "react";

import CourseCardV2 from "../../Atoms/CourseCardV2";
import ShimmerCourseCard from "../../Atoms/Shimmer/CourseCard";

import { Course as CourseInterface } from "../../../models/CourseModels";

import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

import { Container, WrapSlideContent } from "./styles";

interface CourseContainerProps {
  courses: Array<CourseInterface>;
  isLoading: boolean;
  courseViewType?: string;
}

const CoursesContainerAllItems: React.FC<CourseContainerProps> = ({
  courses,
  courseViewType = "horizontal",
  isLoading,
}) => {
  // const sixCourses = useMemo(
  //   () => courses.map((course, index) => index < 10 && course),
  //   [courses]
  // );

  const slideContainer = useRef<any>(null);
  const slideTracking = useRef<any>(null);
  const [widthSlideContainer, setWidthSlideContainer] = useState(0);
  const [slideTrackingContainer, setSlideTrackingContainer] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const windowX = window.innerWidth;

  const handleLeft = () => {
    let x = scrollX + Math.round(windowX / 2);

    if (x > 0) {
      x = 0;
    }
    setScrollX(x);
  };

  const handleRight = () => {
    let x = scrollX - Math.round(windowX / 2);
    let listW = courses.length * 280;

    if (window.innerWidth - listW > x) {
      x = window.innerWidth - listW - 120;
    }

    setScrollX(x);
  };

  useLayoutEffect(() => {
    if (
      slideContainer !== null &&
      slideContainer?.current &&
      slideContainer.current.offsetWidth
    ) {
      setWidthSlideContainer(slideContainer.current?.offsetWidth);
      setSlideTrackingContainer(slideTracking.current?.offsetWidth);
    }
  }, [isLoading]);

  return (
    <>
      {!isLoading ? (
        <Container ref={slideContainer} className="a">
          {courses.map(
            (course, index) =>
              course && (
                <CourseCardV2
                  key={course.courseid}
                  course={course}
                  courseViewType={courseViewType}
                />
              )
          )}
        </Container>
      ) : (
        <Container>
          <WrapSlideContent>
            <ShimmerCourseCard courseViewType={courseViewType} />
            <ShimmerCourseCard
              animationDelay={0.3 * 0}
              courseViewType={courseViewType}
            />
            <ShimmerCourseCard
              animationDelay={0.3 * 1}
              courseViewType={courseViewType}
            />
            <ShimmerCourseCard
              animationDelay={0.3 * 2}
              courseViewType={courseViewType}
            />
            <ShimmerCourseCard
              animationDelay={0.3 * 3}
              courseViewType={courseViewType}
            />
          </WrapSlideContent>
        </Container>
      )}
    </>
  );
};

export default CoursesContainerAllItems;
