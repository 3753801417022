import { useState, useCallback } from 'react';
import { Course as CourseModel } from '../../models/CourseModels';
import apiV2 from '../apiV2';

const useCourseDetails = () => {
  const [courseDetailsDataState, setCourseDetailsDataState] = useState<CourseModel | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchCourseDetailsData = useCallback(async (courseId: string, generatedId: string) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiV2.get<CourseModel>(
        `/course/info?courseid=${courseId}&userid=${generatedId}`,
      );

      if (!response) {
        throw new Error('Network response was not ok');
      }

      setCourseDetailsDataState(response.data);
    } catch (error) {
      if (error instanceof Error) {
        setError(error?.message);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return { courseDetailsData: courseDetailsDataState, loading, error, fetchCourseDetailsData };
};

export default useCourseDetails;
