import { Container, Box, Button } from "./styles";
import { FiChevronRight } from "react-icons/fi";

interface Props {
  description: string;
  onOpen: () => void;
  isWatchingExercise: Object;
}

function ExercisePreviewCard({
  description,
  isWatchingExercise,
  onOpen,
}: Props) {
  return (
    <Container>
{/*       <p>Exercícios</p> */}
      <Box>
        <p>{description}</p>
      </Box>
      <Button onClick={(e) => onOpen()}>
        <p>
          IR PARA OS EXERCÍCIOS
          <FiChevronRight size="12" height="12" width="12" />
        </p>
      </Button>
    </Container>
  );
}

export default ExercisePreviewCard;
