import styled, { css } from "styled-components";

export const Container = styled.footer`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1rem;
    background-color: ${theme.colors.background_primary};
    border-top: 2px solid ${theme.colors.default_green};

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 0.7rem;
      line-height: 14px;
      display: flex;
      align-items: center;

      color: ${theme.colors.default_white};
    }
  `}
`;

export const ImgLogo = styled.img`
  width: 100px;
`;

export const DivisorVertical = styled.div`
  ${({ theme }) => css`
    border-left: 2px solid ${theme.colors.default_green};
    height: 30px;
    margin: 0 1rem;
  `}
`;

export const GridItems = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media (max-width: 980px) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`;

export const ItemsCards = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      color: ${theme.colors.default_white};
    }

    span {
      color: ${theme.colors.default_white};
    }

    &.center {
      flex-direction: column;

      p {
        text-align: center;
        color: ${theme.colors.default_green};
      }
    }
  `}
`;
