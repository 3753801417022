import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  z-index: 9;
  transition: all .5s ease;
  gap: 0 15px;

  > button {
    position: absolute;
    cursor: pointer;
    height: 70%;
    background-color: transparent;
    border: transparent;
    color: #fff;
    opacity: 0;
    transition: all .5s ease;
    width: 55px;
    z-index: 2;

    svg {
      font-size: 2rem;
      background: #0f0f0f;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 10px;
    }

    &.before {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

`;

export const WrapSlideContent = styled.div`
    display: flex;
    padding: 0 5%;
    transition: all .5s ease;
`;
