import React from "react";
import * as S from "./styles";
import Img from "../../assets/section_one.svg";

const ThirdSection: React.FC = () => {
  return (
    <S.Container>
      <S.FirstColumn>
        <S.H2Custom>O que a plataforma não é!</S.H2Custom>
        <S.PDefaultText>
          Mais do que uma plataforma de cursos e videoaulas com aprendizado
          passivo, a Bindemy é uma plataforma de ensino para uso didático, onde
          existe a interação propositiva{" "}
          <a href="/">(baseada na metodologia sala de aula invertida).</a>
          <br />
          <br /> Sua estrutura considera o comportamento dos alunos atuais
          entregando uma experiência de uso que estimula o desenvolvimento do
          estudante,
          <strong>
            além de armazenar dados para o professor ou escola acompanharem o
            progresso do aluno.
          </strong>
        </S.PDefaultText>
        <a className="btn" href="/">
          CONHECER
        </a>
      </S.FirstColumn>
      <S.SecondColumn>
        <S.ImgCustom src={Img} />
      </S.SecondColumn>
    </S.Container>
  );
};

export default ThirdSection;
