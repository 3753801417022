import React from "react";
import * as S from "./styles";
import Img from "../../assets/section_two.svg";
import Img2 from "../../assets/section_two_2.svg";
import Img3 from "../../assets/section_two_3.svg";

const SecondSection: React.FC = () => {
  return (
    <S.Container>
      <S.FirstColumn>
        <S.ImgCustom src={Img} />
        <S.ImgCustom src={Img2} style={{ marginRight: "-425px" }} />
        <S.ImgCustom src={Img3} />
      </S.FirstColumn>
      <S.SecondColumn>
        <S.H2Custom>
          Dinâmica e Informação
          <br /> em um só lugar.
        </S.H2Custom>
        <S.PDefaultText>
          Nossa plataforma permite que professores e escolas construam
          bibliotecas digitais estruturadas para que alunos consultem teorias e
          interajam com todos os tipos de recursos de estudo a hora que precisar
          e de um jeito fácil.
        </S.PDefaultText>
      </S.SecondColumn>
    </S.Container>
  );
};

export default SecondSection;
