import React from "react";
import * as S from "./styles";

type MainProps = {
  children: JSX.Element;
};

const MainContainer: React.FC<MainProps> = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

export default MainContainer;
