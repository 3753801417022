export const calculatePixTotalValue = (
  parcelValue: number,
  discount: number,
  subscription: boolean,
  subscriptionInstallments: number,
): number => {
  const totalValue = subscription
    ? (parcelValue - discount) * subscriptionInstallments
    : (parcelValue - discount) * subscriptionInstallments;

  return totalValue;
};
