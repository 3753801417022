import styled, { css } from "styled-components";

interface CourseModalContainerProps {
  type?: string;
  show?: boolean;
}

export const ModalContainer = styled.div<CourseModalContainerProps>`
  ${(props) =>
    props.show === true
      ? css`
          opacity: 1;
          z-index: 1;
          width: 100%;
          height: 100%;
        `
      : css`
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: auto;
          opacity: 1;
          z-index: 1;
          width: 100%;
          height: 100%;
        `}
`;

export const ModalContent = styled.div`
  margin: auto;
  width: 60%;
  max-width: 750px;
  min-height: 800px;
  background: rgb(34, 34, 34);
  box-shadow: rgb(0 0 0 / 60%) 0px 2px 10px;
  border-radius: 10px;
  overflow: hidden;

  ::-webkit-scrollbar-thumb {
    background: rgb(86, 95, 124);
    border-radius: 8px;
  }
`;

export const ImgContainer = styled.div`
  position: relative;
  max-height: 100%;
  overflow: hidden;

  > svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 2rem;
    background-color: #000000bf;
    color: #fff;
    padding: 2px;
    border-radius: 50%;
    z-index: 2;
  }

  > div {
    height: calc(31vw) !important;
  }

  iframe {
    html {
      background-color: red;
      body {
        div {
          &#player.player {
            max-width: inherit !important;
          }
        }
      }
    }
  }
`;

export const OverlayMask = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg,
    rgba(15, 15, 15, 0.4) 0%,
    rgba(15, 15, 15, 0) 40%
  );
`;

export const InfosCourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
`;

export const InfosCourseWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin: 0 0 25px 0;

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    span {
      font-size: 16px;
    }
  }

  p {
  text-align: right;

  &.colored {
        font-weight: 600;
        color: #fed44a;
    }

    &.colored2 {
        font-weight: 500;
        font-size: 14px;
        color: #aaaaaa;
    }

  }



  div:nth-child(1n) {
    min-width: fit-content;
  }
`;

export const ClassesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ClasseItem = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 4fr 0.5fr;
  gap: 1rem;
  align-items: center;

  padding: 1.5rem;
  margin: 0 0 1rem 0;
  border-bottom: 1.5px solid #fff;

  color: #fff;

  width: 100%;

  .number {
    font-size: 1.5rem;
  }

  img {
    height: 50px;
  }
`;

export const PlayFreeVideo = styled.div`
  display: content;
  background-color: transparent;
  border: none;

  cursor: pointer;
  position: relative;

  > svg {
    position: absolute;
    top: 20%;
    left: 27px;
    font-size: 2rem;

    background: #000;
    border-radius: 50%;
  }

  span.flag {
    position: absolute;
    left: 65px;
    top: -8px;
    padding: 2px 4px;
    font-size: 10px;
    border-radius: 8px;
    background-color: green;
  }
`;
