import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    --primary_cyan_color: #49baae;
    --second_yellow_color: #fed44a;

    --main_black_color: #0f0f0f;
    --main_white: #fff;
    --main_red_color: #f00;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Roboto Medium',sans-serif;

    &::-webkit-scrollbar {
      display: none;
    }

    @media(max-width: 830px){
      body{
        /* min-width: 820px; */
      }
    }
    @media(max-height: 670px){
      body{
        min-height: 663px;
      }
    }
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background: #0f0f0f;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    overflow-x: hidden;
    /* margin-bottom: 2rem; */

    div#root {
      width: 100%;
      max-width: 100%;
      overflow: hidden;
    }
  }

  .block_scroll{
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
    overflow: hidden;
    margin: 0;
    height: 100%;
    overflow: hidden;
  }

  /* Carrousel temporary state */
  .lower{
    z-index: -9999 !important;
  }
  .normal{
    z-index: unset;
  }
  .upper{
    z-index: 999;
  }
  /**--------------------------------- */

  /** price */
  .price-span {
    color: rgb(254,212,74,1);
    font-family: 'Roboto', sans-serif;
    font-weight: bolder !important;
    font-size: 0.825rem;
    margin-bottom: auto;

    &.green{
      color: #000;
    }
  }

  /**------------- */

  .header-warp{
    /* border: solid 1px red; */
  }

  .hasVerticalScroll{
    overflow-x: hidden !important;

    &::-webkit-scrollbar {
      display: unset;
    }
    &::-webkit-scrollbar {
      width: 4px !important;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #565f7c;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .hasHorizontalScroll{
    overflow-y: hidden !important;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar {
      height: 8px !important;
      display: unset;
      /* max-width: 10px !important; */
    }
    &::-webkit-scrollbar-track {
      background: #ABB1C5;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #161a21;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #303236;
      width: 1px !important;
    }
  }
  .overlayed{
    overflow: hidden;
  }
`;
