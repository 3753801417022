import { useEffect, useRef, useState } from "react";
import { CourseSeasonMovie } from "../../../models/CourseModels";

import * as S from "./styles";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { format } from "../../../utils/format";

import { BsFillPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";
import { TiArrowLoop } from "react-icons/ti";
import { GiNextButton, GiPreviousButton } from "react-icons/gi";

interface Props {
  source: CourseSeasonMovie[];
  trackIndex: number;
}

const AudioPlayer = ({ source, trackIndex }: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoop, setIsLoop] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);

  // HTMLAudioElement
  const audioRef = useRef<any>(null);
  useEffect(() => {
    setTotalProgress(audioRef.current.duration);
    if (!audioRef.current) {
      return;
    } else if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    setIsPlaying(false);
    setProgress(0);
    setTotalProgress(0);
  }, [trackIndex]);

  const totalTimer = format(
    !!audioRef.current?.duration === false ? 0 : totalProgress
  );

  const handlePlaying = () => {
    setIsPlaying(!isPlaying);
  };

  const handleLooping = () => {
    setIsLoop(!isLoop);
  };

  function setupPogressListener() {
    audioRef.current.currentTime = 0;
    audioRef.current.addEventListener("timeupdate", () => {
      setProgress(audioRef.current.currentTime);
    });
  }

  function handleSeek(amount: any) {
    audioRef.current.currentTime = amount;
    setProgress(amount);
  }

  return (
    <S.Container>
      <audio
        src={source[trackIndex].url}
        ref={audioRef}
        loop={isLoop}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onLoadedMetadata={setupPogressListener}
        autoPlay
      />
      <S.ProgressBar>
        <p>{format(progress)}</p>
        <Slider
          max={totalProgress}
          value={progress}
          onChange={(e) => handleSeek(e)}
        />
        <p>{totalTimer}</p>
      </S.ProgressBar>
      <S.ControllsPlay>
        <GiPreviousButton className="previous default disabled" />
        {isPlaying ? (
          <BsFillPauseCircleFill onClick={handlePlaying} />
        ) : (
          <BsFillPlayCircleFill onClick={handlePlaying} />
        )}
        <GiNextButton className="next default disabled" />
        <TiArrowLoop
          onClick={handleLooping}
          className={isLoop ? "selected" : "default"}
        />
      </S.ControllsPlay>
    </S.Container>
  );
};

export default AudioPlayer;
