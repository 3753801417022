import { WhatsIcon } from './styles';
import { FaWhatsappSquare } from 'react-icons/fa';

const WhatsappFloating = () => {
  let urlAtual =
    process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_WHITELABEL}`
      : window.location.href;
  let splitUrlProd = urlAtual.split('.');
  let subdomain = splitUrlProd[0].includes('www')
    ? ['', splitUrlProd[1]]
    : splitUrlProd[0].split('//');

  return (
    <WhatsIcon>
      {subdomain[1] === 'sedi' ? (
        <a
          href="https://api.whatsapp.com/send?phone=5519994408256 "
          target="_blank"
          rel="noreferrer"
        >
          <FaWhatsappSquare />
        </a>
      ) : subdomain[1] === 'martinbucer' ? (
        <a
          href="https://api.whatsapp.com/send?phone=5512996030485"
          target="_blank"
          rel="noreferrer"
        >
          <FaWhatsappSquare />
        </a>
      ) : subdomain[1] === 'curso' ? (
        <a
          href="https://api.whatsapp.com/send?phone=5519971519142"
          target="_blank"
          rel="noreferrer"
        >
          <FaWhatsappSquare />
        </a>
      ) : subdomain[1] === 'novasdepaz' ? (
        <a href="https://api.whatsapp.com/send?phone=558196243400" target="_blank" rel="noreferrer">
          <FaWhatsappSquare />
        </a>
      ) : subdomain[1] === 'objetivomogi' ? (
        <a
          href="https://api.whatsapp.com/send?phone=5519981838203"
          target="_blank"
          rel="noreferrer"
        >
          <FaWhatsappSquare />
        </a>
      ) : subdomain[1].includes('setepeb') ? (
                <a
                  href="https://api.whatsapp.com/send?phone=5519988346300"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsappSquare />
                </a>
              ) :subdomain[1].includes('propaga') ? (
                         <a
                           href="https://api.whatsapp.com/send?phone=5519987351740"
                           target="_blank"
                           rel="noreferrer"
                         >
                           <FaWhatsappSquare />
                         </a>
                       ) : (
        <a
          href="https://api.whatsapp.com/send?phone=5519971385444"
          target="_blank"
          rel="noreferrer"
        >
          <FaWhatsappSquare />
        </a>
      )}
    </WhatsIcon>
  );
};

export default WhatsappFloating;
