import React from "react";
import * as S from "./styles";
import Img from "../../assets/section_fourth.svg";
import Img2 from "../../assets/section_fourth_2.svg";

const FourthSection: React.FC = () => {
  return (
    <S.Container>
      <S.H2Custom>Para quem é a Bindemy | Plataforma de Ensino?</S.H2Custom>
      <S.PDefaultText>
        Nossa plataforma permite que professores e escolas construam bibliotecas
        <br />
        digitais estruturadas para que alunos consultem teorias e interajam com
        todos os
        <br /> tipos de recursos de estudo a hora que precisar e de um jeito
        fácil.
      </S.PDefaultText>
      <S.GridItems>
        <S.ItemsCards>
          <S.ImgCustom src={Img} />
          <S.ItemsTextCards>
            <S.H3Custom>Professores |</S.H3Custom>
            <S.DivisorTxt />
            <S.H2Custom className="sub">
              Destaque seu conteúdo e monetize diretamente na plataforma.
            </S.H2Custom>
            <S.PDefaultText>
              Para professores especialistas que querem estruturar um conteúdo
              próprio e expandir seu método de ensino com uma experiência de
              usuário única, didática e com monetização na própria plataforma.
            </S.PDefaultText>
          </S.ItemsTextCards>
        </S.ItemsCards>

        <S.ItemsCards>
          <S.ImgCustom src={Img2} />
          <S.ItemsTextCards>
            <S.H3Custom>Escola |</S.H3Custom>
            <S.DivisorTxt />
            <S.H2Custom className="sub">
              Ofereça uma dinâmica moderna para os pais e alunos.
            </S.H2Custom>
            <S.PDefaultText>
              Para escolas que precisam estruturar seu conteúdo para atender de
              forma dinâmica os alunos da nova geração, misturando o digital com
              o mundo físico, além de oferecer aos pais uma ferramenta que apoia
              o aprendizado dos filhos.
            </S.PDefaultText>
          </S.ItemsTextCards>
        </S.ItemsCards>
      </S.GridItems>
      <S.GridItems className="small">
        <S.ItemsCards className="small">
          <S.H3Custom className="small">79,8%</S.H3Custom>
          <S.PDefaultText className="small">
            dos professores já consideram ministrar aulas com novos recursos e
            ferramentas.
          </S.PDefaultText>
        </S.ItemsCards>

        <S.ItemsCards className="small">
          <S.H3Custom className="small">65,0%</S.H3Custom>
          <S.PDefaultText className="small">
            dos professores acreditam que o trabalho pedagógico aumentou, com
            destaque para as atividades que envolvem interface e/ou interação
            digital.
          </S.PDefaultText>
        </S.ItemsCards>
      </S.GridItems>
    </S.Container>
  );
};

export default FourthSection;
