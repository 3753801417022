import styled from "styled-components";

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
`;

export const ControllsPlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 20px 20px 20px;

  svg {
    font-size: 2.5rem;
    color: #fff;
    transition: all 0.2s;
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
  .default {
    font-size: 1.9rem;
    color: rgba(255, 255, 255, 0.7);
  }
  .selected {
    font-size: 1.9rem;
    color: #37f4a6;
  }
  .default.disabled {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.3);
    &:hover {
      cursor: no-drop;
      opacity: 1;
    }
  }

  .previous {
    margin-right: 20px;
  }
  .next {
    margin-left: 20px;
    margin-right: 10px;
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;

  padding: 20px;

  p {
    color: #fff;
    margin-right: 20px;
    &:last-child {
      margin-left: 20px;
      margin-right: 0;
    }
  }
`;
