import { SUBDOMAIN_ENUM } from '../constants/subddomainEnum';

export const useWhitelabel = () => {
  const subdomain = () => {
    if (process.env.NODE_ENV === 'development') {
      return `${process.env.REACT_APP_WHITELABEL_ADMIN}` as SUBDOMAIN_ENUM;
    }

    let urlAutal = window.location.href;
    let splitUrlProd = urlAutal.split('.');
    let subdomain = splitUrlProd[0].includes('www')
      ? ['', splitUrlProd[1]]
      : splitUrlProd[0].split('//');

    return subdomain[1].toLocaleUpperCase() as SUBDOMAIN_ENUM;
  };

  return {
    subdomain,
  };
};
