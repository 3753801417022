import React from "react";

import { User } from "../../../models/AuthModels";
import { Wrapper, Container, ProfileImgContainer, Content } from "./styles";

interface ChildCardProps {
  child: User;
  onClick(): void;
}

const ChildCard: React.FC<ChildCardProps> = ({ child, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Container>
        <ProfileImgContainer>
          <img src={child.imageurl} alt={`${child.fullname}-profileImg`} />
        </ProfileImgContainer>
        <Content>
          <h3>{child.fullname}</h3>
          <p>{child.schoolName}</p>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ChildCard;
