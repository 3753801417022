import React, { useCallback, useState, useRef, useEffect } from 'react';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

import { Course as CourseInterface } from '../../../models/CourseModels';
import CourseDetailsModal from './Modal/CourseDetailsModal';
import { Modal } from '../Modal';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import { useCart } from '../../../hooks/cart';
import { FlexContainer, Container, IconContainer, ContentCardWrap, PreContentCard } from './syles';
import Button from '../Button';
import ButtonBlocked from '../ButtonBlocked';
import {
  MdLockOutline,
  MdKeyboardArrowDown,
  MdPlayCircleOutline,
  MdOutlineAddShoppingCart,
} from 'react-icons/md';

interface CourseCardProps {
  course: CourseInterface;
  courseViewType: string;
  customClass?: string;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, courseViewType, customClass }) => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const { addCourse } = useCart();
  const [showContent, setShowContent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseSeasons, setCourseSeasons] = useState([]);
  const { user } = useAuth();
  const { cartCourses } = useCart();
  const refModal = useRef<any>(null);
  const [isPlayer, setIsPlayer] = useState<boolean>(false);

  const handleOpenCourse = useCallback(() => {
    const canOpen =
      course.comingsoon !== 'true' &&
      !course.blocked &&
      (!course.status_payment || course.status_payment === 'PAID') &&
      (!course.clicksign_is_signed || course.clicksign_is_signed === 'true');
    if (!canOpen) {
      addToast({
        title: course.prerequirementtitle
          ? course.prerequirementtitle
          : course.status_payment && course.status_payment !== 'PAID'
          ? 'Enviamos um e-mail com as informações de pagamento'
          : course.clicksign_is_signed && course.clicksign_is_signed === 'false'
          ? 'Enviamos um e-mail com as informações para assinar o contrato'
          : 'Bloqueado',
        type: 'info',
      });

      const handleRedirectToUserFinance = () => push('/finance');

      setTimeout(handleRedirectToUserFinance, 3000);
    } else {
      user !== undefined
        ? push(`/course/${course.courseid}`)
        : push(`/course/gratis/${course.courseid}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course.courseid, user, push, addToast, course.comingsoon]);

  const getSeasonsCourse = useCallback(async (courseid: any) => {
    const response = await api.get<any>(`/course/season?courseid=${courseid}`);
    const structureSeasons: any = [];

    response.data.map((item: any, key: number) => {
      structureSeasons.push({
        key: key,
        value: item.title,
        season: item.seasonid,
      });
      return '';
    });

    setCourseSeasons(structureSeasons);
  }, []);

  const triggerModalBtn = (courseid: any) => {
    setShowContent(false);
    getSeasonsCourse(courseid);
    setIsModalOpen(true);
  };

  const addCourseToCart = useCallback(
    (courseToAdd: any) => {
      console.log('Added', courseToAdd);
      addCourse(courseToAdd);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addCourse, course],
  );

  const isCourseInCart = (): boolean => {
    const alreadyInCart = cartCourses.courses.find((i: any) => i.courseid === course.courseid);

    return !!alreadyInCart;
  };

  const handleCloseModalReset = () => {
    setIsModalOpen(false);
    setIsPlayer(false);
  };

  const handlePlayerModal = () => {
    setIsPlayer((prev: boolean) => !prev);
  };

  useEffect(() => {
    const handleCloseModal = (e: any) => {
      if (e.target === refModal.current) {
        setIsModalOpen(false);
        setIsPlayer(false);
      }
    };
    document.addEventListener('click', handleCloseModal);
  }, []);

  return (
    <FlexContainer
      onMouseEnter={() => setShowContent(true)}
      onMouseLeave={() => setShowContent(false)}
      className={`${customClass || ''}`}
    >
      <Container type={courseViewType}>
        <img src={course.thumburl} alt={course.thumburl} aria-hidden="true" />
        {!showContent &&
          (course.comingsoon === 'true' ? (
            <PreContentCard>
              <p className="whitecolor" aria-hidden="true">
                {course.title}
              </p>
              <p className="colored" aria-hidden="true">
                EM BREVE
              </p>
            </PreContentCard>
          ) : !course.is_owner ? (
            <PreContentCard>
              <p aria-hidden="true">{course.title}</p>

              {course.subscription ? (
                <p className="colored" aria-hidden="true" text-align="right">
                  {Number(course.subscription_installments).toFixed(0)}x de R$
                  {Number(course.price).toFixed(2)}
                </p>
              ) : (
                <p className="colored" aria-hidden="true" text-align="right">
                  {course.max_installments > 0 ? course.max_installments : 12}x de R$
                  {Number(
                    (course.price *
                      (course.max_installments && course.max_installments <= 6 ? 1 : 1.0459)) /
                      (course.max_installments > 0 ? course.max_installments : 12),
                  ).toFixed(2)}
                </p>
              )}
            </PreContentCard>
          ) : (
            <PreContentCard>
              <p aria-hidden="true">{course.title}</p>
            </PreContentCard>
          ))}
        <ContentCardWrap show={showContent}>
          {!course.is_owner ? (
            <p className="whitecolor" aria-hidden="true">
              {course.title}
              <br />

              {course.subscription ? (
                <strong className="colored">
                  {Number(course.subscription_installments).toFixed(0)}x de R$
                  {Number(course.price).toFixed(2)}
                </strong>
              ) : (
                <strong className="colored">
                  {course.max_installments > 0 ? course.max_installments : 12}x de R$
                  {Number(
                    (course.price *
                      (course.max_installments && course.max_installments <= 6 ? 1 : 1.0459)) /
                      (course.max_installments > 0 ? course.max_installments : 12),
                  ).toFixed(2)}
                </strong>
              )}
            </p>
          ) : (
            <p className="whitecolor" aria-hidden="true">
              {course.title}
            </p>
          )}
          <IconContainer>
            <MdKeyboardArrowDown onClick={() => triggerModalBtn(course.courseid)} />
            <p>Ver mais</p>
          </IconContainer>
        </ContentCardWrap>
        <ContentCardWrap show={showContent}>
          {course.comingsoon === 'true' ? (
            <div
              className="card_button"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <p>EM BREVE</p>
            </div>
          ) : !course.is_owner ? (
            <div
              className="card_button"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {isCourseInCart() === true ? (
                <Button disabled>
                  <MdOutlineAddShoppingCart />
                  Item já adicionado
                </Button>
              ) : (
                <Button onClick={() => addCourseToCart(course)}>
                  <MdOutlineAddShoppingCart />
                  Adicionar ao carrinho
                </Button>
              )}
            </div>
          ) : (
            <div
              className="card_button"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {course.blocked ? (
                <ButtonBlocked onClick={handleOpenCourse}>
                  <MdLockOutline />
                  Acesso Bloqueado
                </ButtonBlocked>
              ) : course.status_payment && course.status_payment !== 'PAID' ? (
                <ButtonBlocked onClick={handleOpenCourse}>
                  <MdLockOutline />
                  Pagamento Pendente
                </ButtonBlocked>
              ) : course.clicksign_is_signed && course.clicksign_is_signed === 'false' ? (
                <ButtonBlocked onClick={handleOpenCourse}>
                  <MdLockOutline />
                  Contrato Pendente
                </ButtonBlocked>
              ) : (
                <Button onClick={handleOpenCourse}>
                  <MdPlayCircleOutline />
                  Ir para o curso
                </Button>
              )}
            </div>
          )}
        </ContentCardWrap>
        <Modal isOpen={isModalOpen}>
          <CourseDetailsModal
            closeModal={() => handleCloseModalReset()}
            setPlayer={() => handlePlayerModal()}
            course={course}
            courseSeasons={courseSeasons}
            openCourseModal={() => handleOpenCourse()}
            addToCart={() => addCourseToCart(course)}
            alreadyInCart={isCourseInCart()}
            refProp={refModal}
            isPlayer={isPlayer}
          />
        </Modal>
      </Container>
    </FlexContainer>
  );
};

export default CourseCard;
