import React, { useCallback } from "react";
import { FiXSquare } from "react-icons/fi";

import { useAuth } from "../../../../../hooks/auth";
// import { School } from 'models/SchoolModels'; // tipagem
import { User } from "../../../../../models/AuthModels";

import SchoolCard from "../../../../Atoms/SchoolCard";
import ChildCard from "../../../../Atoms/ChildCard";

import { Container } from "./styles";

interface ModalEditSchoolProps {
  viewModal: boolean;
  toggleChangeSchoolModal(): void;
}

const ModalEditSchool: React.FC<ModalEditSchoolProps> = ({
  viewModal = false,
  toggleChangeSchoolModal,
}) => {
  const { user, updateUser } = useAuth();

  const handleChangeSchool = useCallback(
    (school: any) => {
      if (user.profileid !== "Student") {
        const newUser = user;
        newUser.schoolName = school.name;
        newUser.schoolCity = school.city;
        newUser.schoolid = school.schoolid;
        updateUser(newUser);
      }
      toggleChangeSchoolModal();
      window.location.reload();
    },
    [user, updateUser, toggleChangeSchoolModal]
  );

  const handleChangeChild = useCallback(
    (child: User) => {
      if (user.profileid === "Parent") {
        const newUser = user;
        newUser.schoolName = child.schoolName;
        newUser.schoolCity = child.schoolCity;
        newUser.schoolid = child.schoolid;
        newUser.levelid = child.levelid;
        newUser.selectedChild = child;
        updateUser(newUser);
      }
      toggleChangeSchoolModal();
    },
    [updateUser, toggleChangeSchoolModal, user]
  );

  return (
    <Container viewModal={viewModal} className="modal-edit-school}">
      {viewModal && (
        <>
          <div className="content">
            <div className="heading">
              {/* {user.profileid === "Teacher" && <h3>ESCOLAS</h3>}
              {user.profileid === "Parent" && <h3>FILHOS</h3>} */}
              <FiXSquare onClick={toggleChangeSchoolModal} size={32} />
            </div>
            <div className="modal-content hasVerticalScroll">
              <div className="wrapper">
                {/* {user.profileid === "Teacher" &&
                  user.schools?.map((school) => (
                    <SchoolCard
                      key={school.schoolid}
                      school={school}
                      onClick={() => handleChangeSchool(school)}
                    />
                  ))} */}
                {user.profileid === "Parent" &&
                  user.children?.map((child) => (
                    <ChildCard
                      key={child.userid}
                      child={child}
                      onClick={() => handleChangeChild(child)}
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default ModalEditSchool;
