import { useState, Dispatch, SetStateAction } from "react";

interface IuseRequest<T> {
  data: T;
  isLoading: boolean;

  isLastPage: boolean;
  maxPage: number;
  currentPage: number;

  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<T>>;
  setMaxPage: Dispatch<SetStateAction<number>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const useRequestWithPagination = <T>(
  initialState: T
): IuseRequest<T> => {
  const [data, setData] = useState<T>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const isLastPage = currentPage === maxPage;

  return {
    data,
    isLoading,
    isLastPage,
    maxPage,
    currentPage,
    setIsLoading,
    setData,
    setMaxPage,
    setCurrentPage,
  };
};
