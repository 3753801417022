import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioBtnProps } from './types';
import { RadioCustomStyles } from './styles';

const RadioBtn = ({ value, label, onClick }: RadioBtnProps) => {
  return (
    <>
      <FormControlLabel
        value={value}
        label={label}
        control={<Radio style={RadioCustomStyles} />}
        onClick={onClick}
      />
    </>
  );
};

export default RadioBtn;
