import React from "react";
import * as L from "./components";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import { DefaultMain, DefaultColors } from "./styles/styles_main";

const LandingPage: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <L.HeaderLanding />
        <DefaultMain>
          <DefaultColors>
            <L.MainContainer>
              <L.FirstSection />
            </L.MainContainer>
          </DefaultColors>

          <DefaultColors backColor="gradient">
            <L.MainContainer>
              <L.SecondSection />
            </L.MainContainer>
          </DefaultColors>

          <DefaultColors>
            <L.MainContainer>
              <L.ThirdSection />
            </L.MainContainer>
          </DefaultColors>

          <DefaultColors backColor="gradient">
            <L.MainContainer>
              <L.FourthSection />
            </L.MainContainer>
          </DefaultColors>

          <DefaultColors>
            <L.MainContainer>
              <L.FifithSection />
            </L.MainContainer>
          </DefaultColors>
        </DefaultMain>
        <L.Footer />
      </ThemeProvider>
    </>
  );
};

export default LandingPage;
