import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const respond = {
  smallPhone: "@media only screen and (max-width: 360px)",
  phone: "@media only screen and (max-width: 37.5em)",
  tabPort: "@media only screen and (max-width: 56.25em)",
  tabLand: "@media only screen and (max-width: 75em)",
  bigDesktop: "@media only screen and (min-width: 112.5em)",
};

interface IStylesWrapper {
  expanded?: boolean;
}

export const StylesWrapper = styled.div<IStylesWrapper>`
  .sidebar {
    ${({ expanded }) =>
      !expanded
        ? css`
            max-width: 50px;
          `
        : `max-width: unset;`};

    &::-webkit-scrollbar-thumb:hover {
      max-width: 50px;
      background: #555;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
    }
  }
`;

export const Container = styled.div`
  z-index: 2000;

  height: 100vh;
  overflow: auto;
`;

export const SidebarMenu = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  max-width: 25.5rem;
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  color: #a4a6b3;

  margin: 1.5rem 1rem 1.5rem 0.5rem;
  font-size: 1.6rem;
  img {
    margin-right: 1rem;
    max-height: 30px;
  }
`;

export const Link = styled(NavLink)`
  font-size: 1.4rem;
  color: #a4a6b3;
  text-decoration: none;
  padding: 0.8rem 2.7rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.selected {
    background: #9fa2b4;
    // border-left: 2px solid #dde2ff;
    color: #dde2fe;
  }
  svg {
    font-size: 2rem;
    margin-right: 1.5rem;
  }
`;

export const Separator = styled.div`
  height: 1px;
  background: #dfe0eb;
  margin: 2rem auto 1rem auto;
  width: 90%;
  display: block;
`;

interface IHeaderContainer {
  mobile?: boolean;
}

export const HeaderContainer = styled.div<IHeaderContainer>`
  ${(props) =>
    props.mobile
      ? `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem;
        background: #373640
        color: #fff;
        `
      : `
        position: relative;
        width: 100%;

        display: flex;
        justify-content: flex-end;

        padding: 3rem 2rem 0 2rem;

        color: #252733;
      `}
`;

export const PageName = styled.div`
  color: #252733;
  font-weight: bold;
  font-size: 2rem;
`;

export const User = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const Burguer = styled.div`
  div {
    width: 20px;
    height: 1px;
    background-color: #fff;
    margin: 6px 0;
    cursor: pointer;
  }
`;

export const Dropdown = styled.div`
  font-size: 1.6rem;
  color: #a4a6b3;
  text-decoration: none;
  padding: 1rem 2.7rem 1rem 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 2rem;
    margin-right: 2rem;

    &.arrow {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

export const DropdownContainer = styled.div`
  a {
    padding-left: 3rem;
    background: #444857;
  }
`;

export const Wrapper = styled.article`
  padding: 0 5rem 5rem 5rem;

  ${respond.tabLand} {
    padding: 2.5rem;
  }
`;

export const ButtonIcon = styled.button`
  background: #a4a6b3;
  border: none;
  border-radius: 50px;
  justify-self: left;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: cener;
  width: fit-content;
`;
