import React, { useCallback, useRef, useState } from "react";
import * as Yup from "yup";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useParams, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";

import SendCode from "./SendCode";

import { Container, ContentWrapper, LoginWrapper, FormWrapper } from "./styles";
import { useInitialRoute } from "../../../hooks/InitialRouteContext";

interface DataFormInfo {
  code: string;
}

interface Params {
  type: string;
  token: string;
}

const VerifyAccount: React.FC = () => {
  const [isRequesting, setIsRequesting] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { user, sendCodeVerifyAccount, resendCodeVerifyAccount } = useAuth();
  const { addToast } = useToast();

  const { push } = useHistory();
  const params = useParams() as Params;
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token") || "";

  const handleSendCodeVerify = useCallback(
    async (data: DataFormInfo) => {
      setIsRequesting(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          code: Yup.string().required("Código obrigatório!"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await sendCodeVerifyAccount(user.userid, data.code);

        if (response !== "Codigo Invalido") {
          addToast({
            type: "success",
            title: "Conta verificada :)",
            description: "Você já pode acessar nossos conteúdos com segurança!",
          });
          push("/");
        } else {
          addToast({
            type: "error",
            title: "Código inválido",
            description: "Verifique por favor o código enviado em seu email",
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: "error",
            title: "Erro ao validar o código",
            description:
              "Oops... parece que algo deu errado, tente novamente mais tarde!",
          });
        }
      }

      setIsRequesting(false);
      formRef.current?.reset();
    },
    [addToast, sendCodeVerifyAccount, params, token, push, user.userid]
  );

  const handleResendCodeVerify = useCallback(async () => {
    setIsRequesting(true);

    try {
      const response = await resendCodeVerifyAccount(user.userid);

      addToast({
        type: "success",
        title: "Código enviado!",
        description: "O código foi enviado para o email cadastrado!",
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        addToast({
          type: "error",
          title: "Erro de servidor",
          description:
            "Oops... parece que algo deu errado, tente novamente mais tarde!",
        });
      }
    }

    setIsRequesting(false);
    formRef.current?.reset();
  }, [addToast, sendCodeVerifyAccount, params, token, push, user.userid]);

  const { subdomainUrlImg } = useInitialRoute();

  return (
    <Container>
      <ContentWrapper>
        <LoginWrapper>
          <img src={subdomainUrlImg} alt="logo" />
          <Form ref={formRef} onSubmit={handleSendCodeVerify}>
            <FormWrapper>
              <h3>Verificar Conta</h3>
              <p>Por favor, insira o código enviado em seu email cadastrado.</p>
              <p className="highlight" onClick={handleResendCodeVerify}>
                Clique aqui para reenviar Código de verificação
              </p>
              <SendCode isRequesting={isRequesting} />
            </FormWrapper>
          </Form>
        </LoginWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default VerifyAccount;
