import styled from 'styled-components';
import Button from '../../../components/Atoms/Button';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 2rem 0;
`;

export const ContentWrapper = styled.main`
  width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  height: 56%;
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;

  @media (max-width: 979px) {
    width: 100%;
    padding: 0 1rem;
  }

  h3 {
    font-size: 14px;
  }

  > img {
    height: 120px;
    margin-bottom: 4%;
    cursor: pointer;
  }

  form {
    width: 100%;
    max-width: 500px;
  }
`;

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  gap: 1rem;

  h3 {
    font-family: 'Raleway', sans-serif;
    font-size: 24px;
    font-weight: lighter;
    color: #828282;
    letter-spacing: 4px;
  }

  .group-info {
    display: flex;
    width: 100%;
    margin: 8px 0 8px 0;
    align-items: center;
    justify-content: space-between;

    div {
      margin: 0;
      width: 48%;
    }
  }

  .forgot {
    border: none;
    background: none;
    cursor: pointer;
  }

  .dropdown-wrapper {
    display: flex;
    width: 100%;

    margin-bottom: 8px;

    &.school-info {
      display: flex;
      align-items: center;
    }
  }
`;

export const CouponContainer = styled.div`
  display: flex;
  align-items: center;

  flex-direction: row;
  width: 100%;

  > button {
    margin: 0 0 0 5px;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  margin-top: auto;
  gap: 1.5rem;

  .courseContent {
    @media (max-width: 768px) {
      display: flex;
      margin-left: 10px;
      flex-direction: column;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: end;

        .whitecolor {
          color: white;
        }
      }
    }
  }

  .total {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    font-weight: 600;

    span {
      font-weight: 600;
    }

    p {
      font-size: 12px;
    }

    strong {
      font-size: 16px;
    }

    .bold {
      text-align: right;
      font-weight: 600;
      font-size: 16px;
    }

    .oldPrice {
      color: #c6c6c6;
      text-decoration: line-through;
    }
  }

  .btns {
    display: flex;
    width: 100%;
    margin-bottom: 0.4rem;
    justify-content: space-around;
  }

  @media (max-width: 768px) {
    margin-top: 0;

    .btns {
      margin-bottom: 0.4rem;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 768px) {
      .safari_only {
        .btns {
          margin-bottom: 4rem;
        }
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  &.apply-coupon-btn {
    margin-top: 0px, margin-left 16px;
  }

  &.pix-btn {
    width: 40%;
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      filter: invert(1);
    }
    color: #fff;
    background: #49baae;
  }

  &.pix2-btn {
    width: 30%;
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      filter: invert(1);
    }
    color: #fff;
    background: #49baae;
  }

  &.pix2-btn-signup {
    border: 1.5px solid #7a8095;
    width: 30%;
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      filter: invert(1);
    }
    color: #fff;
    background: #0f0f0f;
  }
  &.pix2-btn-signup-selected {
    border: 2px solid #355cc0;
    width: 30%;
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      filter: invert(1);
    }
    color: #fff;
    background: #2a3352;
  }

  &.boleto-btn {
    width: 30%;
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      width: 28px;
      height: 20px;
      filter: invert(0);
    }
    color: #fff;
    background: #646978;
  }

  &.boleto-btn-signup-selected {
    border: 2px solid #355cc0;
    width: 30%;
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      width: 28px;
      height: 20px;
      filter: invert(0);
    }
    color: #fff;
    background: #2a3352;
  }

  &.boleto-btn-signup {
    border: 1.5px solid #7a8095;
    width: 30%;
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      width: 28px;
      height: 20px;
      filter: invert(0);
    }
    color: #fff;
    background: #0f0f0f;
  }

  &.cartao-btn {
    flex-direction: column;
    height: 70px;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      width: 25px;
      height: 18px;
      filter: invert(1);
    }
    color: #fff;
    background: #ff6600;
  }

  &.cartao2-btn {
    flex-direction: column;
    height: 70px;
    width: 35%;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      width: 25px;
      height: 18px;
      filter: invert(1);
    }
    color: #fff;
    background: #ff6600;
  }

  &.cartao2-btn-signup {
    flex-direction: column;
    height: 70px;
    border: 1.5px solid #7a8095;
    width: 35%;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      width: 25px;
      height: 18px;
      filter: invert(1);
    }
    color: #fff;
    background: #0f0f0f;
  }

  &.cartao2-btn-signup-selected {
    flex-direction: column;
    height: 70px;
    border: 2px solid #355cc0;
    width: 35%;
    font-size: 12px;
    img {
      margin-bottom: 6px;
      width: 25px;
      height: 18px;
      filter: invert(1);
    }
    color: #fff;
    background: #2a3352;
  }

  &.signup {
    background: #fff;
    color: #000;

    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.6);
    }
  }
`;

export const PixContainer = styled.div`
  padding: 1rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  background-color: #fff;
`;

export const InputContainerCGA = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
`;
