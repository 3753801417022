import React, { createContext, useContext, useState } from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from './auth';
import { Course as CourseInterface } from '../models/CourseModels';
import { toast } from 'react-toastify';
import apiV2 from '../services/apiV2';
import { v4 } from 'uuid';
import { AxiosResponse } from 'axios';
import { SetStateAction } from 'react';

interface PropsGenerateKey {
  resetCart?: boolean | any;
}

interface GenerateQRCodeSignupBody {
  cartid: string;
  method: string;
  amount: number;
  pix_cpf: string;
}

interface SavePaymentBody {
  userid: string;
  cartid: string;
  method: string;
  amount: number;
  coupons: string[];
  couponid: string;
  userfullname?: string;
  useremail?: string;
  credit_card_cpf?: string;
  credit_card_name?: string;
  credit_card_installments?: number;
  credit_card_number?: string;
  credit_card_cvv?: string;
  credit_card_expiration_date?: string;
}

interface Props {
  children: React.ReactNode;
}

interface CartContextData {
  cartId: string;
  cartCourses: any;
  isCartOpen: boolean;
  addCourse(newCourse: CourseInterface): void;
  generateKey: Function;
  applyCoupon(coupon: string): Promise<void>;
  removeCourse(vourseToRemove: CourseInterface): void;
  reset(): void;
  saveCart(cartKey: string): Promise<void>;
  savePayment(data: SavePaymentBody): Promise<
    | AxiosResponse<string>
    | AxiosResponse<{
        qrcode: string;
        transactionid: string;
      }>
    | 'ERROR'
    | undefined
  >;
  generatePixSignUp(data: GenerateQRCodeSignupBody): Promise<
    | AxiosResponse<string>
    | AxiosResponse<{
        qrcode: string;
        transactionid: string;
      }>
    | 'ERROR'
    | undefined
  >;
  toggleCart: any;
  setIsCartOpen: React.Dispatch<SetStateAction<boolean>>;
  signupApplyCoupon(coupon: string, schoolid: string, amount: number): Promise<any>;
}

const CartContext = createContext({} as CartContextData);

export const CartProvider = ({ children }: Props) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartId, setCartId] = useState<string>(() => {
    const savedCartId = localStorage.getItem('@NextLevel:cartId');

    if (savedCartId) {
      return savedCartId;
    }

    return '';
  });
  const [cartCourses, setCourses] = useState<any>(() => {
    const savedCart = localStorage.getItem('@NextLevel:cart');

    if (savedCart) {
      return JSON.parse(savedCart);
    }

    return {
      cartid: cartId,
      total: 0,
      totalwithcouponapplied: 0,
      courses: [],
      statuscoupontoapply: undefined,
      productcouponlist: [],
      coupons: [],
    } as any;
  });

  const { push } = useHistory();
  const { user } = useAuth();

  const getCartTotal = useCallback((updatedCart: any) => {
    let coursesTotal = 0;

    updatedCart.courses.forEach((course: any) => {
      const hasCoupon = updatedCart.productcouponlist.find(
        (productCoupon: any) => course.courseid === productCoupon.product.courseid,
      );

      if (hasCoupon?.coupon) {
        coursesTotal += hasCoupon.newpricewithcouponapplied;
      } else {
        coursesTotal += course.price;
      }
    });

    if (updatedCart.coupon) {
      switch (updatedCart.coupon.discount) {
        case '$':
          coursesTotal -= updatedCart.coupon.value;
          break;
        case '%':
          coursesTotal *= 1 - updatedCart.coupon.value / 100;
          break;
        default:
          break;
      }
    }

    return coursesTotal;
  }, []);

  const getCartTotalPix = useCallback((updatedCart: any) => {
    let coursesTotal = 0;

    updatedCart.courses.forEach((course: any) => {
      const hasCoupon = updatedCart.productcouponlist.find(
        (productCoupon: any) => course.courseid === productCoupon.product.courseid,
      );

      if (hasCoupon?.coupon) {
        coursesTotal += hasCoupon.newpricewithcouponapplied;
      } else {
        coursesTotal += course.pix_price ? course.pix_price : course.price;
      }
    });

    if (updatedCart.coupon) {
      switch (updatedCart.coupon.discount) {
        case '$':
          coursesTotal -= updatedCart.coupon.value;
          break;
        case '%':
          coursesTotal *= 1 - updatedCart.coupon.value / 100;
          break;
        default:
          break;
      }
    }

    return coursesTotal;
  }, []);

  const addCourse = useCallback(
    (newCourse: CourseInterface) => {
      const updatedCart = { ...cartCourses };

      if (!updatedCart.courses) return;

      const courseAlreadyInCart = updatedCart.courses.findIndex(
        (course: any) => course.courseid === newCourse.courseid,
      );

      if (courseAlreadyInCart < 0) {
        updatedCart.courses.push(newCourse);
        const newTotal = getCartTotal(updatedCart);
        updatedCart.total = newTotal;
        const newTotalPix = getCartTotalPix(updatedCart);
        updatedCart.total_pix = newTotalPix;
        localStorage.setItem('@NextLevel:cart', JSON.stringify(updatedCart));
        setCourses(updatedCart);
      }
    },
    [cartCourses, getCartTotal, getCartTotalPix],
  );

  const removeCourse = useCallback(
    (courseToRemove: CourseInterface) => {
      const updatedCart = { ...cartCourses };

      if (!updatedCart.courses) return;

      const courseIndexToRemove = updatedCart.courses.findIndex(
        (course: any) => course.courseid === courseToRemove.courseid,
      );

      if (courseIndexToRemove > -1) {
        const productCouponIndexToRemove = updatedCart.productcouponlist.findIndex(
          (i: any) => i.product.courseid === courseToRemove.courseid,
        );

        const productCouponToRemove = updatedCart.productcouponlist.find(
          (i: any) => i.product.courseid === courseToRemove.courseid,
        );

        if (productCouponIndexToRemove > -1) {
          updatedCart.productcouponlist.splice(productCouponIndexToRemove, 1);

          const stringCourseIndexToRemove = cartCourses.coupons.findIndex(
            (coupon: any) => coupon === productCouponToRemove?.coupon?.couponid,
          );

          if (courseToRemove) {
            cartCourses.coupons.splice(stringCourseIndexToRemove, 1);
          }
        }

        if (updatedCart.courses.length <= 1) {
          updatedCart.coupons = [];
          updatedCart.coupon = undefined;
        }

        updatedCart.courses.splice(courseIndexToRemove, 1);
        const newTotal = getCartTotal(updatedCart);
        updatedCart.total = newTotal;
        const newTotalPix = getCartTotalPix(updatedCart);
        updatedCart.total_pix = newTotalPix;
        localStorage.setItem('@NextLevel:cart', JSON.stringify(updatedCart));
        setCourses(updatedCart);
      }
    },
    [cartCourses, getCartTotal, getCartTotalPix],
  );

  const reset = (): void => {
    const resetedCart = {
      cartid: cartId,
      total: 0,
      totalwithcouponapplied: 0,
      courses: [],
      statuscoupontoapply: undefined,
      productcouponlist: [],
      coupons: [],
    };

    localStorage.removeItem('@NextLevel:cart');
    localStorage.setItem('@NextLevel:cart', JSON.stringify(resetedCart));
    setCourses(resetedCart);
    push('/');
  };

  const toggleCart = (customState?: boolean) => {
    window.scrollTo(0, 0);

    if (isCartOpen) {
      document.body.classList.remove('block_scroll');
    } else {
      document.body.classList.add('block_scroll');
    }

    if (customState) {
      setIsCartOpen(customState);
      return;
    }

    setIsCartOpen(state => !state);
  };

  const signupApplyCoupon = async (coupon: string, schoolid: string, amount: number): Promise<any> => {
    coupon = coupon.toUpperCase();
    if (coupon) {
      const response = await apiV2.post<any>('/user/signup/payment/coupon/verify', {
        coupon: coupon,
        schoolid: schoolid,
        amount: amount,
        cartid: '',
      });

      if (response.status === 200) {
        if (response.data > 0) {
          toast.success('Cupom aplicado com sucesso', {
            position: 'top-left',
            style: {
              zIndex: 999,
            },
          });
          return response.data;
        } else {
          toast.error('Falha ao aplicar cupom', {
            position: 'top-left',
            style: {
              zIndex: 999,
            },
          });
        }

        return 0;
      }
    }
  };

  const applyCoupon = async (coupon: string): Promise<void> => {
    coupon = coupon.toUpperCase();
    if (coupon && !!user) {
      const cartItensIds = cartCourses.courses.map((item: any) => item.courseid);
      const couponsList = cartCourses.coupons ? [...cartCourses.coupons] : [];

      if (couponsList.includes(coupon)) {
        toast.warn('Este cupom ja foi aplicado em seu carrinho', {
          position: 'top-left',
        });
        return;
      }

      couponsList.push(coupon);

      const response = await apiV2.post<any>('/cart', {
        userid: user.userid,
        cartid: cartId,
        products: cartItensIds,
        couponidtoapply: coupon,
        coupons: couponsList,
      });

      if (response.status === 200) {
        const courses = response.data.productcouponlist.map((i: any) => i.product);
        const formattedCart = { ...response.data, courses };
        const updatedCart = {
          ...cartCourses,
          ...formattedCart,
          coupons: couponsList,
          oldPrice: response.data.total,
        };

        if (response.data.coupon) {
          updatedCart.total = response.data.totalwithcouponapplied;
        }

        if (response.data.statuscoupontoapply === 1) {
          toast.success('Cupom aplicado com sucesso', {
            position: 'top-left',
            style: {
              zIndex: 999,
            },
          });
          localStorage.setItem('@NextLevel:cart', JSON.stringify(updatedCart));
          setCourses(updatedCart);
        } else {
          toast.error('Falha ao aplicar cupom', {
            position: 'top-left',
            style: {
              zIndex: 999,
            },
          });
        }
      }
    }
  };

  const generateKey = (resetCart = false): any => {
    if (resetCart) {
      setCartId('');
      localStorage.removeItem('@NextLevel:cartId');
    }

    if (!cartId) {
      const generatedId = v4();
      localStorage.setItem('@NextLevel:cartId', generatedId);
      setCartId(generatedId);
    }
    return cartId;
  };

  const saveCart = useCallback(
    async (cartKey: any) => {
      const cartItensIds = cartCourses.courses
        ? cartCourses.courses.map((item: any) => item.courseid)
        : [];

      await apiV2.post<string>('/cart', {
        userid: user.userid,
        cartid: cartKey,
        products: cartItensIds,
        couponidtoapply: cartCourses.coupon?.couponid ?? '',
        coupons: cartCourses.coupons,
      });
    },
    [user, cartCourses.coupons, cartCourses.courses, cartCourses.coupon],
  );

  const generatePixSignUp = useCallback(async (data: GenerateQRCodeSignupBody) => {
    try {
      let response;
      if (data.method === 'pix') {
        response = await apiV2.post<{
          qrcode: string;
          transactionid: string;
        }>('/user/signup/payment/pix/qrcode', data);
      }

      return response;
    } catch (err) {
      return 'ERROR';
    }
  }, []);

  const savePayment = useCallback(async (data: SavePaymentBody) => {
    try {
      let response;
      if (data.method === 'credit_card') {
        response = await apiV2.post<string>('/cart/payment', data);
      } else if (data.method === 'pix') {
        response = await apiV2.post<{
          qrcode: string;
          transactionid: string;
        }>('/cart/payment', data);
      } else {
        response = await apiV2.post<string>('/cart/payment', data);
      }

      return response;
    } catch (err) {
      return 'ERROR';
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartId,
        cartCourses,
        setIsCartOpen,
        isCartOpen,
        addCourse,
        generateKey,
        removeCourse,
        applyCoupon,
        reset,
        saveCart,
        savePayment,
        generatePixSignUp,
        toggleCart,
        signupApplyCoupon,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart() {
  const context = useContext(CartContext);

  return context;
}
