export default {
  colors: {
    default_primary: "#29202e",
    default_white: "#fff",
    default_black: "#33283A",
    default_green: "#7ec78f",
    default_yellow: "#EBEA70",
    default_blue: "#38C6F4",

    background_primary: "#33283a",
    background_secondary: "linear-gradient(45deg, #46c6e0 20%, #EBEA70)",

    background_header: "#21386E",
    background_about: "#F3F3F3",
    text: "#050617",
    text_secondary: "#21386E",
    light_text: "rgba(23, 23, 27, 0.6)",
    detail: "#747476",
    divisor: "#EBEA70",
  },
};
