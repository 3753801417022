import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
  ReactNode,
  useEffect,
} from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

import {
  User as UserInterface,
  ParentDashboard,
  StudentDashboard,
  TeacherDashboard,
} from '../models/AuthModels';
import api from '../services/api';
import { useToast } from '../hooks/toast';
import apiV2 from '../services/apiV2';

interface AuthContextData {
  user: UserInterface;
  senha?: string;
  isLoading: boolean;
  getDashboard(user: UserInterface): Promise<void>;
  signIn(loginInfo: Login): Promise<string>;
  signUp(signUp: Signup): Promise<void>;
  signUpWithPayment(signUpWithPayment: SignupWithPayment): Promise<void>;
  signOut(): void;
  updateUser(user: UserInterface, imgUrl?: string, changeApi?: boolean): Promise<void>;
  requestPasswordEmail(userIdentification: string, schoolId: string): Promise<string>;
  resetPassword(token: string, password: string): Promise<string>;
  sendCodeVerifyAccount(user: string, code: string): Promise<string>;
  resendCodeVerifyAccount(user: string): Promise<string>;
  handleCallToken(
    action: 'save' | 'remove' | 'get',
    call: string,
    teacher?: string,
    token?: string,
  ): string | undefined;
  returnBackend: string;
  setReturnBackend: Function;
  subdomain: string;
}

interface SignupWithPayment {
  schoolpartner: string;
  birthdate: string;
  cellphone: string;
  documentNumber: string;
  username: string;
  fullName: string;
  email: string;
  password: string;
  imageUrl: string;
  schoolid: string;
  levelid: string;
  roomid: string;
  codeVerification?: string;

  address: string;
  number: string;
  city: string;
  state: string;
  neighborhood: string;
  zipcode: string;
  complement: string;
  credit_card_installments: number;
  amount: number;
  price: number;
  cartid: string;
  cardNumber: string;
  cardName: string;
  exp: string;
  cvv: string;

  method: string;
  couponid: string;
  productid: string;
}

interface Signup {
  schoolpartner: string;
  birthdate: string;
  cellphone: string;
  documentNumber: string;
  username: string;
  fullName: string;
  email: string;
  password: string;
  imageUrl: string;
  schoolid: string;
  levelid: string;
  roomid: string;
  codeVerification?: string;
  position?: string;
}

interface Login {
  username: string;
  password: string;
}

interface UserLoginData {
  user: UserInterface;
}

interface Props {
  children: ReactNode;
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [returnBackend, setReturnBackend] = useState('');

  const [data, setData] = useState<UserLoginData>(() => {
    const token = localStorage.getItem('@NextLevel:token');
    const user = localStorage.getItem('@NextLevel:user');

    if (user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
      };
    }

    return {} as UserLoginData;
  });

  const { addToast } = useToast();
  const { push } = useHistory();

  const defaultUserProfileImage = useMemo<string>(
    () => 'https://nextlevelimagesprofile.s3-sa-east-1.amazonaws.com/defaultUser.png',
    [],
  );

  //backup user removed
  const tryLoginWithLocalStorage = useCallback(async (user: any) => {
    if (user) {
      const body = {
        schoolpartner: user.schoolpartner,
        cellphone: user.cellphone,
        documentNumber: user.documentNumber,
        birthdate: user.birthdate,
        codeverification: user.codeverification,
        created_at: user.created_at,
        email: user.email,
        fullname: user.fullname,
        imageurl: user.imageurl,
        levelid: user.levelid,
        modified_at: user.modified_at,
        password: user.password,
        planId: user.planId,
        profileid: user.profileid,
        roomid: user.roomid,
        schoolCity: user.schoolCity,
        schoolName: user.schoolName,
        schoolid: user.schoolid,
        status: user.status,
        userid: user.userid,
        username: user.username,
      };

      const { data } = await apiV2.post('/user/signup/backup', body);

      setReturnBackend(data);
    }
  }, []);

  const getDashboard = useCallback(
    async (user: UserInterface) => {
      if (user !== undefined) {
        //backup user removed
        // await tryLoginWithLocalStorage(user);

        let dashboard;
        switch (user.profileid) {
          case 'Parent':
            dashboard = await api.get<ParentDashboard>(`/user/dashboard?userid=${user.userid}`);

            dashboard.data.children?.forEach((child: any) => {
              // eslint-disable-next-line no-param-reassign
              child.imageurl =
                child.imageurl !== ' ' || '' ? child.imageurl : defaultUserProfileImage;
            });
            if (user.schoolid) {
              Object.assign(user, {
                ...dashboard.data.user,
                school: dashboard.data.school,
                children: dashboard.data.children,
                imageurl: dashboard.data.user.imageurl,
              });
            } else {
              Object.assign(user, {
                ...dashboard.data.user,
                schoolCity: dashboard.data.school.city,
                schoolName: dashboard.data.school.name,
                schoolid: dashboard.data.school.schoolid,
                school: dashboard.data.school,
                children: dashboard.data.children,
                selectedChild: dashboard.data.children[0],
                levelid: dashboard.data.children[0].levelid,
                imageurl: dashboard.data.user.imageurl,
              });
            }
            break;
          case 'Teacher':
            dashboard = await api.get<TeacherDashboard>(`/user/dashboard?userid=${user.userid}`);

            // eslint-disable-next-line no-case-declarations
            const userWithoutSchoolId = { ...dashboard.data.user } as any;

            delete userWithoutSchoolId.schoolid;

            if (!user.schoolCity) {
              Object.assign(user, {
                ...userWithoutSchoolId,
                schoolCity: dashboard.data.school.city,
                schoolName: dashboard.data.school.name,
                schoolid: dashboard.data.school.schoolid,
                school: dashboard.data.school,
                calendlyschoolsubjectteacher: dashboard.data.calendlyschoolsubjectteacher,
                fastdoubtteacherstatus: dashboard.data.fastdoubtteacherstatus,
              });
            } else {
              Object.assign(user, {
                ...userWithoutSchoolId,
                school: dashboard.data.school,
                calendlyschoolsubjectteacher: dashboard.data.calendlyschoolsubjectteacher,
                fastdoubtteacherstatus: dashboard.data.fastdoubtteacherstatus,
              });
            }
            break;
          case 'Student':
            dashboard = await api.get<StudentDashboard>(`/user/dashboard?userid=${user.userid}`);
            Object.assign(user, {
              ...dashboard.data.user,
              schoolCity: dashboard.data.school.city,
              schoolName: dashboard.data.school.name,
              planId: JSON.parse(dashboard.data.school.planid),
            });

            break;
          case 'Admin':
            dashboard = await api.get(`/user/dashboard?userid=${user.userid}`);

            Object.assign(user, { ...dashboard.data.user });
            break;
          default:
            throw new Error('Erro, tipo de usuário não listado!');
        }
        // eslint-disable-next-line no-param-reassign
        user.imageurl = user.imageurl !== ' ' || '' ? user.imageurl : defaultUserProfileImage;

        localStorage.setItem('@NextLevel:user', JSON.stringify(user));
      }
    },
    [defaultUserProfileImage],
  );

  getDashboard(data.user).then(response => {
    if (data?.user?.status === 'waitingVerification') {
      push('/verify');
    }
  });

  let urlAtual =
    process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_WHITELABEL}`
      : window.location.href;
  let splitUrlProd = urlAtual.split('.');
  let subdomain = splitUrlProd[0].includes('www')
    ? ['', splitUrlProd[1]]
    : splitUrlProd[0].split('//');

  const customAnalytics = `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','034678285ID');`;

  useEffect(() => {
    if (subdomain[1] === 'objetivomogi') {
      const scriptNode = document.createElement('script');
      scriptNode.type = 'text/javascript';
      scriptNode.innerHTML = customAnalytics;

      document.head.appendChild(scriptNode);
    }
  }, []);

  const signIn = useCallback(
    async ({ username, password }: any) => {
      const response = await api.post<UserInterface>(
        `${process.env.REACT_APP_API_URL_V2}/user/signin`,
        {
          username,
          password,
          schoolid: subdomain[1].toLocaleUpperCase(),
        },
      );

      const user = response.data;

      user && (await getDashboard(user));

      setData({
        user,
      });

      let targetUrl;

      if (user.status !== 'waitingVerification') {
        targetUrl = user.profileid.toLocaleLowerCase() === 'admin' ? '/admin' : '/';
      } else {
        targetUrl = '/verify';
      }

      return targetUrl;
    },
    [getDashboard, subdomain],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@NextLevel:token');
    localStorage.removeItem('@NextLevel:user');
    localStorage.removeItem('@NextLevel:cart');
    localStorage.removeItem('@NextLevel:cartId');
    // localStorage.removeItem('@NextLevel:movieView');

    setData({} as UserLoginData);
  }, []);

  const updateUser = useCallback(
    async (userToUpdate: UserInterface, imgUrl: string, changeApi = false) => {
      let strImage;
      if (changeApi) {
        if (imgUrl && imgUrl !== '') {
          const [, formatedBase64Url] = imgUrl.split('base64,');
          strImage = formatedBase64Url;
        } else {
          strImage = '';
        }

        const newData = {
          userid: data.user.userid,
          email: userToUpdate.email !== '' ? userToUpdate.email : data.user.email,
          password: userToUpdate.password !== '' ? userToUpdate.password : data.user.password,
          imagebase64: strImage,
        };

        const response = await api.post<UserInterface>(`/user/edit`, newData);

        if (response.data) {
          const localData = localStorage.getItem('@NextLevel:user');
          const baseUser = localData ? JSON.parse(localData) : {};
          const updateUserData = { ...baseUser, ...response.data };
          updateUserData.schoolCity = baseUser.schoolCity;
          updateUserData.schoolid = baseUser.schoolid;
          updateUserData.schoolName = baseUser.schoolName;

          localStorage.setItem('@NextLevel:user', JSON.stringify(updateUserData));
          setData({ user: updateUserData });
        }
      } else {
        localStorage.setItem('@NextLevel:user', JSON.stringify(userToUpdate));

        setData({
          user: userToUpdate,
        });
      }
      addToast({
        type: 'success',
        title: 'Usuário atualizado com sucesso :)',
        description: 'Todas as informações foram atualizadas com sucesso!',
      });
    },
    [setData, data, addToast],
  );

  const requestPasswordEmail = useCallback(
    async (userIdentification: string, schoolId: string): Promise<string> => {
      const requestParams = {
        identification: userIdentification,
        schoolid: schoolId,
      };
      const response = await apiV2.post<string>(`/user/forgotpassword/token`, requestParams);

      if (response.data) {
        return response.data;
      }
      return '';
    },
    [],
  );

  const resetPassword = useCallback(async (token: string, password: string) => {
    const request = { token, password };
    const response = await api.post<string>(`/user/forgotpassword/edit`, request);

    if (response.data) {
      return response.data;
    }
    return '';
  }, []);

  const sendCodeVerifyAccount = useCallback(async (userid: string, code: string) => {
    const request = { userid, code };
    const response = await apiV2.post<string>(`/user/emailvalidation`, request);

    if (response.data) {
      return response.data;
    }
    return '';
  }, []);

  const resendCodeVerifyAccount = useCallback(async (userid: string) => {
    const request = { userid };
    const response = await apiV2.post<string>(`/user/emailvalidation/resend`, request);

    if (response.data) {
      return response.data;
    }
    return '';
  }, []);

  const handleCallToken = useCallback(
    (
      action: 'save' | 'remove' | 'get',
      call: string,
      teacher?: string,
      token?: string,
    ): string | undefined => {
      if (action === 'save') {
        const callTokenInfo = { token, call, teacher };
        const twelveDaysExpirationTime = new Date(new Date().getTime() + 12 * 24 * 60 * 60 * 1000);
        Cookies.set(`@NextLevelCallTokenInfo:${call}`, JSON.stringify(callTokenInfo), {
          expires: twelveDaysExpirationTime,
        });
        return 'saved';
      }
      if (action === 'remove') {
        Cookies.remove(`@NextLevelCallTokenInfo:${call}`);
        return 'removed';
      }
      const callToken = Cookies.get(`@NextLevelCallTokenInfo:${call}`);

      return callToken;
    },
    [],
  );

  const signUp = useCallback(
    async ({
      schoolpartner,
      birthdate,
      cellphone,
      documentNumber,
      email,
      password,
      username,
      imageUrl = '',
      fullName,
      schoolid,
      levelid,
      roomid,
      codeVerification,
    }: Signup) => {
      setIsLoading(true);
      const body = {
        schoolpartner,
        birthdate,
        cellphone,
        documentNumber,
        email,
        password,
        profileid: 'Student',
        roomid,
        levelid,
        schoolid,
        fullname: fullName,
        username,
        image_url: imageUrl,
        codeVerificationSchool: codeVerification,
      };

      const { data } = await apiV2.post('/user/signup', body);

      if (data === 'Código Inválido') {
        addToast({
          type: 'error',
          title: 'Código Inválido!',
          description: 'Favor, conferir o código fornecido e tentar novamente.',
        });
        setIsLoading(false);

        return;
      }

      if (data === 'e-mail already taken') {
        addToast({
          type: 'error',
          title: 'Email existente!',
          description: 'Favor, conferir o email e tentar novamente.',
        });
        setIsLoading(false);

        return;
      }

      setReturnBackend(data);

      await signIn({ username, password });

      push('/verify');
      setIsLoading(false);
    },
    [push, signIn],
  );

  const signUpWithPayment = useCallback(
    async ({
      schoolpartner,
      birthdate,
      cellphone,
      documentNumber,
      email,
      password,
      username,
      imageUrl = '',
      fullName,
      schoolid,
      levelid,
      roomid,
      codeVerification,
      address,
      number,
      city,
      state,
      neighborhood,
      zipcode,
      complement,
      credit_card_installments,
      amount,
      price,
      cartid,
      cardNumber,
      cardName,
      exp,
      cvv,
      method,
      couponid,
      productid,
    }: SignupWithPayment) => {
      setIsLoading(true);
      const body = {
        schoolpartner,
        birthdate,
        cellphone,
        documentNumber,
        email,
        password,
        profileid: 'Student',
        roomid,
        levelid,
        schoolid,
        fullname: fullName,
        username,
        image_url: imageUrl,
        codeVerificationSchool: codeVerification,
        address,
        number,
        city,
        state,
        neighborhood,
        zipcode,
        complement,
        credit_card_installments,
        amount,
        price,
        cartid,
        cardNumber,
        cardName,
        exp,
        cvv,
        method,
        couponid,
        productid,
      };

      const { data } = await apiV2.post('/user/signup/payment', body);

      if (data === 'Código Inválido') {
        addToast({
          type: 'error',
          title: 'Código Inválido!',
          description: 'Favor, conferir o código fornecido e tentar novamente.',
        });
        setIsLoading(false);

        return;
      }

      if (data === 'e-mail already taken') {
        addToast({
          type: 'error',
          title: 'Email existente!',
          description: 'Favor, conferir o email e tentar novamente.',
        });
        setIsLoading(false);

        return;
      }

      if (data === '') {
        addToast({
          type: 'error',
          title: 'Erro no pagamento',
          description: 'Favor, conferir os dados e tentar novamente.',
        });

        setIsLoading(false);

        return;
      }

      setReturnBackend(data);

      if (body.method !== 'pix') {
        await signIn({ username, password });
        push('/verify');
      }

      setIsLoading(false);

      return data;
    },
    [push, signIn, addToast],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        isLoading,
        signIn,
        signUp,
        signUpWithPayment,
        getDashboard,
        signOut,
        updateUser,
        requestPasswordEmail,
        resetPassword,
        sendCodeVerifyAccount,
        resendCodeVerifyAccount,
        handleCallToken,
        returnBackend,
        setReturnBackend,
        subdomain: subdomain[1],
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
