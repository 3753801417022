import Select from 'react-select';
import { ContainerSelect, SelectCustomStyles } from './styles';
import { OptionFiledType, SelectCustomProps } from './types';
import { useField } from '@unform/core';
import { useEffect, useRef, useState } from 'react';

const SelectCustom = ({ options, name, placeholder, isLoading, value }: SelectCustomProps) => {
  const selectReference = useRef<null>(null);

  const parseSelectedValue = options.find(option => option.value === value);

  const [selectedValue, setSelectedValue] = useState<OptionFiledType>(parseSelectedValue);
  const { fieldName, registerField } = useField(name);

  const handleChange = (selectedOption: OptionFiledType) => {
    registerField({
      name: fieldName,
      ref: selectReference.current,
      getValue: (ref: HTMLSelectElement | null) => {
        if (!ref) {
          return '';
        }
        return selectedOption ? selectedOption.value : '';
      },
    });
    selectedOption && setSelectedValue(selectedOption);
  };

  useEffect(() => {
    handleChange(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <ContainerSelect>
      <Select
        ref={selectReference}
        options={options}
        styles={SelectCustomStyles}
        menuPosition={'fixed'}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        isLoading={isLoading}
        value={selectedValue}
      />
    </ContainerSelect>
  );
};

export default SelectCustom;
