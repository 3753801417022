import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  padding: 0 5%;

  .teste {
    display: flex;
    button {
      margin-left: auto;
    }
  }

  margin-bottom: 5%;
`;

export const SliderMaskWrap = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgb(15, 15, 15) 22%, rgb(226, 226, 226) 77%);
`;
