import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 42%;
  min-width: 416px;
  height: 50%;
  padding: 0.425rem;
  background: rgba(84, 91, 110, 1);
  border-radius: 4px;
  .content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0.625rem;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      overflow-x: auto;
      column-gap: 24px;
      row-gap: 24px;
      background: rgba(255, 255, 255, 0.7);
      padding: 0.625rem;
      border-radius: 4px;
    }
    .answer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin-top: 12px;
      button {
        height: 100%;
        margin: 0;
        padding: 0;
        margin-left: 16px;
        height: 30px;
        font-size: 14px;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  height: 12%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Roboto Slab', sans-serif;
  font-size: 16px;
  color: #fff;
  margin-bottom: 12px;
  h2 {
    border-bottom: solid 1px #fed44a;
    display: inline;
    font-size: 16px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Roboto Slab', sans-serif;
  color: #363636;
  height: 12%;
  p {
    margin-left: 12px;
    font-size: 14px;
  }
`;
