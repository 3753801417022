import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 6px;
  background: rgba(84, 91, 110, 1);
  margin-top: 20px;

  padding: 4px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  background: rgba(0, 0, 0, 0.5);

  border-radius: 6px;
  transition: 0.4s;

  &:hover {
    transform: translateX(10px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }
`;

export const ProfileImgContainer = styled.div`
  display: flex;
  width: 16%;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: 50px;

    border-radius: 50%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
`;
