import styled, { css } from "styled-components";
import { shade } from "polished";

interface ContainerProps {
  viewModal: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100%;

  top: 0;

  transition: background-color 0.4s;
  ${(props) =>
    props.viewModal
      ? css`
          background: rgba(0, 0, 0, 0.4);
          z-index: 4;
        `
      : css`
          background: none;
          z-index: -20;
        `}
  .content {
    position: absolute;
    display: flex;
    flex-direction: column;

    width: 340px;
    height: 340px;
    top: 0;
    left: 0;

    margin-left: 24%;
    margin-top: 5%;
    padding: 12px;

    /* border: solid 2px rgba(255, 211, 92,1); */
    transition: 0.4s;

    background: rgba(84, 91, 110, 1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 6px;

    .heading {
      position: relative;
      display: flex;
      margin-left: auto;
      justify-content: center;
      align-items: center;
      width: 100%;

      h3 {
        font-family: "Roboto Slab";
        font-weight: 800;
        font-size: 24px;
        color: #fff;
      }

      svg {
        position: absolute;
        top: 0;
        right: 0;
        color: #eb4034;
        transition: 0.4s;
        &:hover {
          color: ${shade(0.4, "#eb4034")};
          cursor: pointer;
        }
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      height: 100%;

      overflow: scroll;

      .wrapper {
        display: flex;
        padding: 0 8px;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.5);
        overflow: scroll;
      }
    }
  }
`;
