import styled from "styled-components";
import Button from "../../Atoms/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
  width: 0;
  overflow: hidden;

  position: absolute;
  right: 0;

  background: #fff;
  border-radius: 6px 0px 0px 6px;

  z-index: 999;

  transition: all 0.3s ease-out 0s;

  &.active {
    max-width: 500px;
    width: 500px;

    @media (max-width: 768px) {
      min-height: 100vh;
      height: auto;
      max-width: 100%;
      width: 100%;

      overflow-y: scroll;
    }
  }
`;

export const TitleSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 100%;

  background: #fed44a;
  border-radius: 6px 6px 0px 0px;

  color: #000;

  font-family: "Roboto", sans-serif;
  font-weight: bolder;

  .close_btn {
    position: absolute;
    left: 24px;
    color: #000;

    transition: filter 0.2s, color 0.2s;
    &:hover {
      filter: opacity(0.6);
      cursor: pointer;
      /* color: red; */
    }
  }
`;

export const ItensSection = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;

  overflow-y: scroll;
`;

export const CouponContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  border: solid 1px rgba(205, 205, 205, 0.3);
  border-radius: 0.5rem;

  > p {
  white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
  color: rgba(111, 111, 111, 1);
    margin-right: 1rem;
    text-align: left;
    font-size: 14px;
  }

  button {
    width: 80px;
    height: 30px;
    margin: 0;
    margin-left: auto;

    border-radius: 0.4rem;
  }

  padding: 1rem 2rem;


  input {
        border-color: #e3e3e3;
      }


  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;

    p {
      min-width: 100%;
      padding: 0;
      margin: 0;
    }

    input {
      border-color: #e3e3e3;
      margin: 0;
      height: 35px;
      max-height: 35px;
      width: 65%;
    }
  }

  @media (max-width: 1200px) {
    input {
      margin: 0;
    }
  }
`;



export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
  }
  span {
  text-align: right;
    margin: 0;
  }

  .nameWithCoupon {
    display: flex;
    flex-direction: column;

    > strong {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .oldPrice {
      color: #c6c6c6;
    text-decoration: line-through;
  }



    .valorcartaoparcelado {
        color: #929292;
        font-size: 11px;

    }

    .discount {
        color: #00b431;
        font-size: 10px;

    }
`;


export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 1rem;
  width: 100%;

  margin-top: auto;


.courseContent {


    @media (max-width: 768px) {
      display: flex;
      margin-left: 10px;
      flex-direction: column;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: end;

        .whitecolor {

              color: white;

        }
      }
    }
  }

  .total {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    font-weight: 600;

    span {
      font-weight: 600;
    }

    p {
      font-size: 12px;
    }

    strong {
      font-size: 16px;
    }

    .bold {
    text-align: right;
      font-weight: 600;
      font-size: 16px;
    }

    .oldPrice {
    color: #c6c6c6;
      text-decoration: line-through;
    }
  }

  .btns {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  @media (max-width: 768px) {
    margin-top: 0;

    .btns {
      margin-bottom: 7rem;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 768px) {
      .safari_only {
        .btns {
          margin-bottom: 4rem;
        }
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40%;

  svg {
    color: #000;
  }

  img {
     width: 20px;
     height: 20px;
  }

  p {
    margin-left: 0.8rem;
    white-space: nowrap;
             overflow: hidden;
             display: block;
             text-overflow: ellipsis;
  }

 &.pix-btn {
  width: 40%;
       font-size: 12px;
     img {
       filter: invert(1);
     }
     color: #fff;
     background: #49baae;
   }

    &.pix2-btn {
     width: 30%;
          font-size: 12px;
        img {
          filter: invert(1);
        }
        color: #fff;
        background: #49baae;
      }

     &.boleto-btn {
      width: 30%;
         font-size: 12px;
       img {
       width: 28px;
            height: 20px;
         filter: invert(0);
       }
       color: #fff;
       background: #646978;
     }

     &.cartao-btn {

          font-size: 12px;
            img {
            width: 25px;
                       height: 18px;
              filter: invert(1);
            }
            color: #fff;
            background: #ff6600;
          }

          &.cartao2-btn {

                 width: 35%;
               font-size: 12px;
                 img {
                 width: 25px;
                            height: 18px;
                   filter: invert(1);
                 }
                 color: #fff;
                 background: #ff6600;
               }

  &.apply-coupon-btn {
  width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;

    p {
      margin: 0;
    }

    svg {
      color: #fff;
      font-weight: bold;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 12px;

    p {
      display: none;
    }

    &.apply-coupon-btn {
    display: row;
      width: 80px;
      height: 35px;
      margin-left: 1rem;

      svg {
        width: 70%;
        height: 70%;
      }
    }
  }
`;


