import { StylesConfig } from 'react-select';
import styled from 'styled-components';
import { OptionType } from './types';

export const SelectCustomStyles: StylesConfig<OptionType, false> = {
  singleValue: styles => ({
    ...styles,
    color: '#fff',
    fontSize: '14px',
  }),
  control: styles => ({
    ...styles,
    border: '1px solid transparent',
    boxShadow: 'none',

    '&:hover, &:focus, &:active': {
      border: '1px solid #ffa22b',
      boxShadow: '0 0 0 1px #ffa22b',
    },
    background: '#7a8095',
    width: '100%',
    margin: '0 auto',
    borderRadius: '30px',
    height: '51px',
    color: '#fff',
    padding: '0 .5rem',
  }),
  menu: styles => ({
    ...styles,
    margin: '0 auto',
    width: '100%',
    border: 'none',
    backgroundColor: '#7a8095',
  }),
  input: styles => ({
    ...styles,
    color: '#fff',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#fff',
    fontSize: '14px',
  }),
  option: (styles, state) => ({
    ...styles,
    color: '#fff',
    fontSize: '14px',
    backgroundColor: state.isSelected ? '#000' : 'transparent',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: '#fff',
  }),
};

export const ContainerSelect = styled.div`
  width: 100%;
`;
