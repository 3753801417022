import React from "react";
import { FiUser } from "react-icons/fi";

import Input from "../../../components/Atoms/Input";
import Loading from "../../../components/Atoms/Loading";

import { StyledButton } from "./styles";

interface RequestPasswordResetEmailProps {
  isRequesting: boolean;
}

const RequestPasswordResetEmail: React.FC<RequestPasswordResetEmailProps> = ({
  isRequesting = false,
}) => {
  return (
    <>
      <Input
        name="username"
        icon={FiUser}
        placeholder="Digite seu usuário ou email"
        style={{ width: 300 }}
        enabled={!isRequesting}
      />
      <StyledButton type="submit" enabled={!isRequesting}>
        {isRequesting ? <Loading size={2} /> : "Enviar"}
      </StyledButton>
    </>
  );
};

export default RequestPasswordResetEmail;
