import { MdDomainVerification } from 'react-icons/md';
import Input from '../../../../../components/Atoms/Input';
import { useWhitelabel } from '../../../../../hooks/useWhitelabel';
import { InputBirthDateProps } from './types';
import { hideComponentCheck } from './utils';
import { dateMask } from '../../../../../utils/masks';

const InputBirthDate = ({ isLogging = false }: InputBirthDateProps) => {
  const { subdomain } = useWhitelabel();
  const subdomainValue = subdomain();
  const hideInput = hideComponentCheck(subdomainValue);

  if (!hideInput) {
    return null;
  }

  return (
    <Input
      autoComplete="off"
      name="birthdate"
      icon={MdDomainVerification}
      mask={dateMask}
      placeholder="Data de Nascimento"
      containerStyle={{ width: '100%' }}
      enabled={!isLogging}
    />
  );
};

export { InputBirthDate };
