import styled, { css } from 'styled-components';
import Skeleton from '../../../Skeleton';
import Button from '../../../Atoms/Button';

interface ContainerProps {
  customType?: string;
}

interface HorizontalSelectContainerProps {
  isLoading: boolean;
}

export const CertificateWrapper = styled.div`
  position: absolute;

  width: 200px;
  height: auto;

  right: 0;
  top: 0;

  margin-top: -26px;
  z-index: 2;

  @media (max-width: 768px) {
    left: 1rem;
    top: 4rem;
  }
`;

export const StyledButton = styled(Button)`
  width: 160px;
  height: 30px;

  font-size: 12px;
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(0, 0, 0, 0.01);

  font-family: 'Raleway';
  color: #e7eaf2;

  min-width: 408px !important;
  height: 100%;

  padding: 0 4px 0 20px;

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;

    margin-bottom: 4px;
    margin-right: auto;
    padding: 4px 12px;

    svg {
      margin-right: 12px;
      transition: color 0.4s;
      &:hover {
        color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 0 1rem;
    height: auto;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 94%;
  max-height: 50%;

  font-family: 'Raleway', sans-serif;
  color: #fff;

  img {
    width: 340px;
    height: auto;
    object-fit: cover;
    margin-bottom: 12px;
  }

  p {
    width: 210px;
    text-align: center;
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    p {
      margin-top: 1rem;
    }
  }
`;

export const HeadingScrollView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  font-family: 'Raleway', sans-serif;
  color: #fff;
  overflow: auto;
  min-height: 34%;
`;

export const ContentHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-box-align: center;
  width: 100%;
  height: 60px;
  margin-top: 6px;
  margin-bottom: 6px;
  border: 2px solid rgb(122, 128, 149);
  border-radius: 10px;
  padding: 0px 15px 0px;
  transition: all 0.4s ease 0s;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const LinkButton = styled.a`
  text-decoration: none;
  font-size: 12px;
  text-align: center;
`;

export const ButtonLive = styled.button`
  background: rgb(254, 212, 74);
  border-radius: 30px;
  height: 33px;
  border: 0px;
  padding: 0px 16px;
  font-family: Raleway, sans-serif;
  color: rgb(53, 53, 54);
  box-shadow: none;
  font-weight: bolder !important;
  text-transform: uppercase;

  display: flex;
  align-items: center;

.certificate{
    margin-bottom: 8px;
      height: 25px;
  }

  transition: 0.2s ease 0s;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  .iconLive {
    margin-left: 4px;
    font-size: 1.3rem;
  }
`;

export const WrappetTextTitle = styled.div`
  display: flex;
  align-items: baseline;

  p {
    margin: 2px 12px 0px 0px;
    color: rgb(255, 255, 255);
    font-family: Roboto;
    font-size: 14px;
    width: 10px;
  }
  h4 {
    color: rgb(255, 255, 255);
    font-weight: normal;
  }
`;

export const ContentTextHeading = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  p {
    margin-left: 20px;
    margin-top: 6px;
    color: rgb(255, 211, 92);
    max-width: 240px;
    font-family: Roboto;
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    width: 70%;
  }
`;

export const ContentTextStyled = styled.p`
  text-align: left !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const HorizontalSelectContainer = styled.div<HorizontalSelectContainerProps>`
  width: 100%;

  ${props =>
    props.isLoading &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 4px;

  width: 96%;
  height: 100%;

  overflow: scroll;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    margin-left: 9px;
    margin-top: 1rem;

    @media (max-width: 768px) {
      margin-top: 2.5rem;
    }
  }

  .subject-select {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-top: 8px;
  }

  .class-select {
    display: flex;
    width: auto;
    margin-left: 8px;

    margin-top: 10px;
    margin-bottom: 12px;
  }
`;

export const VideosScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  padding: 0 4px 32px 0;
`;

export const ShimmerMovieBanner = styled(Skeleton)`
  width: 320px;
  height: 160px;
  margin-bottom: 12px;
`;

export const ShimmerMovieTitle = styled(Skeleton)`
  width: 100px;
  height: 20px;
  margin-top: 12px;
`;

export const DescriptionLive = styled(Skeleton)`
  height: 60px;
  width: 100px;
  border-radius: 10px;
  width: 95%;
  margin-top: 1rem;
`;


export const StyledProgressBar = styled.div`
  width: 90%;
  bottom: 0px;

  > p {
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
      margin-top: 1rem;
      text-align: left;

      @media (max-width: 768px) {
        margin-top: 1rem;
      }
    }
`;
