import { convertNumberToBRL } from '../../../../../../utils/functions';

export const getInstallments = (
  installments: number,
  discount: number,
  totalToCharge: number,
  isSubscription: boolean = false,
  maxInstallments?: number,
): any => {
  const cartTotal = totalToCharge - discount;
  const totalInstallmentsAllowed = maxInstallments ? maxInstallments : 12;

  const subCharge = Array.from({ length: 1 }).map(() => ({
    key: installments,
    value: `12x de ${convertNumberToBRL(cartTotal)}`,
  }));

  const dropdownInstallmentsItemsWithTax = Array.from({
    length: totalInstallmentsAllowed,
  }).map((_, index) => {
    const hasTax = index < 6 ? 1 : 1.0459;
    const hasAddText = index < 6 ? '' : '';
    return {
      key: index + 1,
      value: `${index + 1}x de ${convertNumberToBRL(
        (cartTotal * hasTax) / (index + 1),
      )} ${hasAddText}`,
    };
  });

  const finalInstallmentsArray = [...dropdownInstallmentsItemsWithTax].filter(i => !!i && i);

  const returnItens = isSubscription ? subCharge : finalInstallmentsArray;

  return returnItens;
};
