import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

interface ContainerStyle {
  position?: number;
}

export const Container = styled.div<ContainerStyle>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  max-height: 250px;
  height: 250px;

  @media (max-width: 980px) {
    height: auto;
    max-height: inherit;
  }

  ${(props) =>
    props.position === 0
      ? css`
          margin-top: 50px;
        `
      : css`
          margin-top: 20px;
        `}
`;

export const CategoryTitle = styled.div`
  display: flex;
  align-items: center;

  color: #e9eaed;
  width: 100%;
  padding: 0 5%;
  margin-bottom: 20px;

  font-family: "Roboto Regular", sans-serif;

  /* margin-bottom: -80px; */
  p {
    font-size: 20px;
    margin-right: 12px;
  }

  h3 {
    margin-right: 12px;
    font-size: 14px;

    @media (max-width: 980px) {
      font-size: 1.2rem;
    }
  }
  p {
    font-size: 14px;

    @media (max-width: 980px) {
      font-size: 1.2rem;
    }
  }

  a {
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 12px;

    color: rgb(254, 212, 74, 1);
  }

  h4 {
    z-index: 3;
    align-items: center;
    font-weight: lighter;
    font-size: 12px;

    &:hover {
      text-shadow: 0 0 10px rgb(254, 212, 74, 0.4);
      cursor: pointer;
    }
  }

  button {
    margin-left: 12px;
    z-index: 3;
  }

  .category-title {
    width: 100px;
    height: 12px;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  border-radius: 12px;

  width: 100vw;
  /* height: 220px; */

  .awssld__content {
    background: transparent;

    border-radius: 12px;
  }
  .awssld__content:first-child > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 92%;
  }

  .awssld__controls__arrow-right::before,
  .awssld__controls__arrow-right::after,
  .awssld__controls__arrow-left::before,
  .awssld__controls__arrow-left::after {
    background: #fff;
    opacity: 0;

    transition: opacity 0.4s;
  }

  .awssld__prev {
    width: 40px;
    margin-left: 40px;
  }
  .awssld__next {
    width: 40px;
    margin-right: 40px;
  }
`;

export const SeeAllItems = styled(Link)`
  align-items: flex-end !important;
  margin-right: 0;
  font-size: 14px;

  > svg {
    font-size: 16px;
  }
`;
