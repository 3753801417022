import React, { useState, useEffect } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import { SliderMaskWrap, ImgContainer } from "./styles";

interface MainSliderTypes {
  slideImgs?: any;
}

const MainSlider: React.FC<MainSliderTypes> = ({ slideImgs }) => {
  const [loadedSlider, setLoadedSlider] = useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,
      slides: {
        perView: 1,
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoadedSlider(true);
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  let contentSlideImgs: any = slideImgs.length > 0 ? slideImgs : [];

  const Arrow = (props: {
    disabled: boolean;
    left?: boolean;
    onClick: (e: any) => void;
    styleCustom?: any;
  }) => {
    const disabeld = props.disabled ? " arrow--disabled" : "";
    return (
      <svg
        onClick={props.onClick}
        className={`arrow ${
          props.left ? "arrow--left" : "arrow--right"
        } ${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        style={props.styleCustom}
      >
        {props.left && (
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        )}
        {!props.left && (
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        )}
      </svg>
    );
  };

  useEffect(() => {
    if (slideImgs.length > 0) {
      setLoadedSlider(true);
    }
  }, []);

  return (
    <>
      {contentSlideImgs.length > 0 && (
        <SliderMaskWrap>
          <div
            className="navigation-wrapper"
            style={{
              position: "relative",
            }}
          >
            <div
              ref={sliderRef}
              className="keen-slider"
              style={{
                width: "100%",
                maxWidth: "100%",
                minWidth: "100%",
                backgroundColor: "#000",
              }}
            >
              {contentSlideImgs.map((itemSlider: any, key: number) => {
                return (
                  <div
                    key={key}
                    className={`keen-slider__slide number-slide${key}`}
                  >
                    {itemSlider?.linkurl !== " " ? (
                      <a href={itemSlider?.linkurl} target="_blank">
                        <ImgContainer backImg={itemSlider.url} />
                      </a>
                    ) : (
                      <ImgContainer backImg={itemSlider.url} />
                    )}
                  </div>
                );
              })}
            </div>
            {loadedSlider && instanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                  disabled={currentSlide === 0}
                  styleCustom={{
                    position: "absolute",
                    left: "5px",
                    top: "50%",
                    width: "25px",
                    fill: "#fff",
                    cursor: "pointer",
                  }}
                />
                <Arrow
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                  disabled={
                    currentSlide ===
                    instanceRef.current.track.details.slides.length - 1
                  }
                  styleCustom={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    width: "25px",
                    fill: "#fff",
                    cursor: "pointer",
                  }}
                />
              </>
            )}
          </div>
        </SliderMaskWrap>
      )}
    </>
  );
};

export default MainSlider;
