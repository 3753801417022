import styled from "styled-components";
import Button from "../../Atoms/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 500px;
  height: 100%;

  position: absolute;
  right: 0;

  background: white;
  border-radius: 10px 0px 0px 10px;

  z-index: 5;

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    height: auto;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 100%;

  background: #fed44a;
  border-radius: 6px 6px 0px 0px;

  color: #000;

  font-family: "Roboto", sans-serif;
  font-weight: bolder;

  .close_btn {
    position: absolute;
    top: 12px;
    left: 24px;
    color: #000;

    transition: filter 0.2s, color 0.2s;
    &:hover {
      filter: opacity(0.6);
      cursor: pointer;
    }
  }

  .back_btn {
    position: absolute;
    top: 12px;
    left: 64px;
    color: #000;

    transition: filter 0.2s;
    &:hover {
      filter: opacity(0.6);
      cursor: pointer;
    }
  }
`;

export const ItensSection = styled.div`
  min-height: 93.5vh;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .dropdown-wrapper {
    width: 75%;
    margin-bottom: 8px;
  }

  .group-info {
    display: flex;
    width: 75%;
    margin: 8px 0 8px 0;
    align-items: center;
    justify-content: space-between;

    div {
      margin: 0;
      width: 48%;
    }
  }

  @media (max-height: 700px) {
    div.rccs > div.rccs__card {
      max-height: 120px;
      margin: 15px 15px 15px 0px;
    }
  }

  @media (max-width: 768px) {
    .form.big {
      margin-bottom: 5rem;
    }
  }
`;

export const QrArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .total {
    display: flex;
    align: center;
    justify: center;
    strong {
      margin-right: 1.5rem;
    }
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  width: 75%;
  margin: 0 auto;

  button {
    margin-top: 0;
    color: var(--main_white);
    background: var(--primary_cyan_color);
  }

  p {
  font-size:14px;
    margin: 0.2rem 0.2px 1rem 0.2rem;
    text-align: center;

    & > svg {
      font-size: 1.3rem;
      color: var(--primary_cyan_color);

      cursor: pointer;

      margin: 0 0 0 10px;
      vertical-align: bottom;
    }

    @media (max-width: 768px) {
      margin: 0.5rem 0;
    }
  }
`;

export const ButtonSection = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-around;

  position: absolute;
  bottom: 20px;
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 240px;

  p {
    margin-left: 0.8rem;
  }
`;
