import React, { ButtonHTMLAttributes } from "react";

import Loading from "../Loading";

import { Container } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  enabled?: boolean;
  customStyle?: string;
  bolder?: boolean;
  contrast?: boolean;
  noShaddow?: boolean;
  shimmer?: boolean;
  state?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  customStyle = "",
  bolder = false,
  enabled = true,
  contrast = false,
  noShaddow = false,
  shimmer = false,
  state,
  ...rest
}) => {
  return (
    <Container
      type="button"
      enabled={enabled}
      contrast={contrast}
      bolder={bolder}
      noShaddow={noShaddow}
      shimmer={shimmer}
      customStyle={customStyle}
      state={state}
      disabled={loading}
      {...rest}
    >
      {loading ? <Loading size={1} /> : children}
    </Container>
  );
};

export default Button;
