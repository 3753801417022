import {
  Wrapper,
  HamburguerMenu,
  OpennedMenu,
  NormalMenu,
  Option,
} from "./styles";

import { FaSignInAlt, FaWindowClose } from "react-icons/fa";

interface CollapsibleMenuProps {
  actualTab: string;
  canNavigate: boolean;
  isCollapsed: boolean;
  items: Array<{ key: string; value: string }>;
  handleChangeTab(key: string): void;
  handleCollapseMenu(): void;
  handleLogout(): void;
}

export const CollapsibleMenuV2 = ({
  actualTab,
  canNavigate,
  isCollapsed,
  items,
  handleChangeTab,
  handleCollapseMenu,
  handleLogout,
}: CollapsibleMenuProps): JSX.Element => {
  const toggleMenu = (): void => {
    const menuContainer = document.getElementById("oppened-menu");

    if (menuContainer) {
      if (menuContainer.style.maxHeight && !isCollapsed) {
        menuContainer.style.maxHeight = "0.1px";
      } else {
        menuContainer.style.maxHeight = `${menuContainer.scrollHeight}px`;
      }
    }

    handleCollapseMenu();
  };

  return (
    <Wrapper>
      <HamburguerMenu
        onClick={toggleMenu}
        className={!isCollapsed ? "hidden" : ""}
      >
        <div className="bar" />
        <div className="bar" />
        <div className="bar" />
      </HamburguerMenu>

      <OpennedMenu
        className={!isCollapsed ? "active" : "hidden"}
        id="oppened-menu"
        onClick={toggleMenu}
      >
        <FaWindowClose />
        {items &&
          items.map((item) => (
            <Option
              key={item.value}
              className="logo-option oppened-menu-option"
              tabIsSelected={actualTab.includes(item.key)}
              onClick={() => handleChangeTab && handleChangeTab(item.key)}
            >
              {item.value}
            </Option>
          ))}
        <Option className="mobile" onClick={handleLogout}>
          <FaSignInAlt className="signin" size={20} />
          Logout
        </Option>
      </OpennedMenu>

      <NormalMenu>
        {items &&
          items.map((item) => (
            <Option
              key={item.value}
              className="logo-option"
              tabIsSelected={actualTab.includes(item.key)}
              onClick={() => handleChangeTab && handleChangeTab(item.key)}
            >
              {item.value}
            </Option>
          ))}
      </NormalMenu>
    </Wrapper>
  );
};
