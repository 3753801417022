import styled from "styled-components";
import Button from "../../../components/Atoms/Button";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const ContentWrapper = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  height: 56%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 8%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
  }

  h3 {
    font-size: 14px;
  }

  img {
    height: 120px;
    margin-bottom: 4%;
    cursor: pointer;
  }

  form {
    width: 100%;
    max-width: 500px;
  }
`;

export const FormWrapper = styled.div`
  height: 300px;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    /* padding: 0 1rem; */
  }

  h3 {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    font-weight: lighter;
    color: #353536;
    letter-spacing: 4px;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
`;
