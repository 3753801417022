import React, { useState, useCallback, useEffect } from "react";
import { FiTrash } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import {
  Course as CourseInterface,
  CourseSeason,
} from "../../../models/CourseModels";

import { convertNumberToBRL, getSubscriptionValue, getPixDiscountPrice, getPixDiscountValue, getPriceCreditCardValue, getPixDiscountPriceSubscription } from "../../../utils/functions";
import { useCart } from "../../../hooks/cart";
import api from "../../../services/api";

import { Modal } from "../Modal";
import CourseDetailsModal from "../CourseCardV2/Modal/CourseDetailsModal";

import {
  FlexContainer,
  Container,
  Thumb,
  Heading,
  StyledButton,
} from "./syles";

interface CourseCardProps {
  course: CourseInterface;
  isCartOpen: boolean;
}

const CartCourseCard: React.FC<CourseCardProps> = ({ course, isCartOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seasonListLength, setSeasonListLength] = useState(0);
  const [courseSeasons, setCourseSeasons] = useState<CourseSeason[]>([]);

  const { removeCourse, cartCourses, addCourse } = useCart();
  const { push } = useHistory();

  const isCourseInCart = (): boolean => {
    const alreadyInCart = cartCourses.courses.find(
      (i: any) => i.courseid === course.courseid
    );

    return !!alreadyInCart;
  };

  const courseNewPrice = useCallback((): any => {
    const newPrice = cartCourses.productcouponlist.find(
      (i: any) => i.product.courseid === course.courseid
    );
    if (newPrice) {
      return newPrice;
    }
  }, [cartCourses, course]);

  const getCourseSeasons = useCallback(async () => {
    if (courseSeasons.length < 1) {
      setCourseSeasons([]);
      const response = await api.get<CourseSeason[]>(
        `/course/season?courseid=${course.courseid}`
      );
      setSeasonListLength(response.data.length);
      setCourseSeasons(response.data);
    }
  }, [course.courseid, courseSeasons]);

  const addCourseToCart = useCallback(() => {
    if (course.is_owner) {
      push(`/course/${course.courseid}`);
    } else {
      addCourse(course);
    }
  }, [addCourse, course, push]);

  useEffect(() => {
    if (isCartOpen && courseSeasons.length < 1) {
      getCourseSeasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartOpen]);

  return (
    <FlexContainer>
      <Modal isOpen={isModalOpen}>
        <CourseDetailsModal
          closeModal={() => setIsModalOpen(false)}
          course={course}
          courseSeasons={courseSeasons}
          addToCart={addCourseToCart}
          alreadyInCart={isCourseInCart()}
          seasonListLength={seasonListLength}
        />
      </Modal>
      <Container>

      <div className="container2">
        <Thumb
                  onClick={() => setIsModalOpen(true)}
                  src={course.thumburl}
                  alt={course.courseid}
                />

        <div className="overlay2">

         <div className="button">
                    <StyledButton onClick={() => removeCourse(course)}>
                      <FiTrash size={18} />
                    </StyledButton>
                  </div>

                  </div>
      </div>




        <div className="courseContent">
          <Heading onClick={() => setIsModalOpen(true)}>
            {/* <p>{!!courseNewPrice() ? '' : course.title}</p> */}
            {courseNewPrice() && courseNewPrice().coupon ? (
              <div className="nameWithCoupon">
                <p>{course.title}</p>
                <strong>{`(${courseNewPrice().coupon.couponid})`}</strong>
              </div>
            ) : (
              <p className="whitecolor">{course.title}</p>
            )}
            <span className="price-span green">
              {courseNewPrice() &&
              !!courseNewPrice().coupon &&
              typeof courseNewPrice().newpricewithcouponapplied === "number" ? (
                <>
                  <p className="oldPrice">
                    { course?.subscription ? getSubscriptionValue(course?.subscription_installments, course?.price) : convertNumberToBRL(
                                                             course?.price || 0
                                                           )}
                  </p>
                  <p>
                    { course?.subscription ? getSubscriptionValue(course?.subscription_installments, courseNewPrice().newpricewithcouponapplied) : convertNumberToBRL(
                      courseNewPrice().newpricewithcouponapplied || 0
                    )}
                  </p>
                </>
              ) : (
              course.pix_discount ? (
               <>
              <p> { course?.subscription ? (getSubscriptionValue(course?.subscription_installments, course?.price)) : (
                               getPixDiscountPrice(
                                                       course?.pix_price
                                                     )
                                                     )}</p>
                                                                   {course?.pix_price ?
                                                                   (<p className="valorcartaoparcelado"> {
                                                                                                                            getPixDiscountPriceSubscription(
                                                                                                                                                   course?.pix_price
                                                                                                                                                                                                                 )}</p>):(<></>)}
                                                                   {course?.pix_discount ? (<p className="discount"> {
                                                                                    getPixDiscountValue(
                                                                                                            course?.pix_discount
                                                                                                          )}</p>):(<></>)}
                                    {course?.subscription ? ("") : (<p className="valorcartaoparcelado"> {
                                                         getPriceCreditCardValue(
                                                                                course?.price , course.max_installments
                                                                                                                                              )}</p>)}
                                                     </>
                ) : (<p> { course?.subscription ? getSubscriptionValue(course?.subscription_installments, course?.price) :
                                                                   convertNumberToBRL(
                                                                                           course?.price || 0
                                                                                         )}</p>)
              )}
            </span>
          </Heading>

        </div>
      </Container>
    </FlexContainer>
  );
};

export default CartCourseCard;
