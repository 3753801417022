import styled, { css } from "styled-components";

export const Container = styled.section`
  ${({ theme }) => css`
    padding: 1.5rem;
    background: transparent;
    min-height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 980px) {
      flex-direction: column;
      height: auto;
    }
  `}
`;

export const FirstColumn = styled.article`
  ${({ theme }) => css`
    padding: 4rem;
    display: flex;
    justify-content: center;
    flex: 1.5;
    max-height: 90vh;
    overflow: hidden;
  `}
`;

export const SecondColumn = styled.article`
  ${({ theme }) => css`
    padding: 2rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    gap: 2rem;
    justify-content: center;

    p.detail {
      font-size: 1rem;
      font-weight: 600;
      color: ${theme.colors.default_white};

      span {
        color: ${theme.colors.default_green};
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bolder;
      color: ${theme.colors.default_white};
      background-color: ${theme.colors.default_green};
      border: none;
      border-radius: 0px 10px 0px 10px;
      padding: 0.5rem 0.8rem;
      height: 2.5rem;
      cursor: pointer;
    }
  `}
`;

export const PDefaultText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 1rem;
    font-weight: 500;
    max-width: 320px;
  `}
`;

export const H2Custom = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.6rem;

    span {
      color: ${theme.colors.default_green};
    }
  `}
`;

export const ImgCustom = styled.img`
  ${({ theme }) => css`
    width: 100%;
    max-width: 450px;
  `}
`;
