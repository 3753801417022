import styled, { css } from "styled-components";

type NavProps = {
  click?: boolean | any;
};

export const Container = styled.header`
  ${({ theme }) => css`
    padding: 1.5rem;
    background: ${theme.colors.background_primary};
  `}
`;

export const ImgLogo = styled.img`
  width: 100px;
`;

export const ContainerWrap = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 0.7rem;
      line-height: 14px;
      display: flex;
      align-items: center;

      color: ${theme.colors.default_white};
    }

    div.burguer_menu {
      display: none;
    }

    @media (max-width: 980px) {
      div.burguer_menu {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
          color: ${theme.colors.default_white};
          fill: ${theme.colors.default_white};
          font-size: 1.5rem;
        }
      }
    }
  `}
`;

export const ContainerMenu = styled.nav<NavProps>`
  ${({ theme, click }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-end;

    transition: all 0.2s ease;

    a {
      color: ${theme.colors.default_white};
      font-weight: bolder;
      font-size: 0.9rem;
      text-transform: uppercase;
      padding-right: 3rem;
      text-decoration: none;
      transition: all 0.5s ease;

      :hover {
        color: ${theme.colors.default_green};
      }
    }

    div.wrap {
      display: flex;
    }

    svg.close {
      display: none;
    }

    @media (max-width: 980px) {
      opacity: ${click ? "1" : "0"};
      z-index: ${click ? "1" : "-1"};

      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100vw;
      min-height: 100vh;
      background-color: ${theme.colors.background_primary};
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        padding: 1rem;
      }

      div.wrap {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1rem;

        svg.close {
          display: ${click ? "flex" : "flex"};
          color: ${theme.colors.default_white};
          fill: ${theme.colors.default_white};

          ${(click) =>
            click &&
            css`
              position: absolute;
              top: 0;
              right: 0;
              margin: 1.5rem;
              cursor: pointer;
              z-index: 9;
              font-size: 2rem;
            `}
        }
      }
    }
  `}
`;

export const IconDetail = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.default_green};
    border-radius: 8px 0px 8px 0px;
    padding: 0.3rem;
    margin: 0 0 0 0.7rem;

    a {
      padding: 0;
      font-size: inherit;
      display: flex;
    }

    > svg {
    }

    @media (max-width: 980px) {
    }
  `}
`;

export const DivisorVertical = styled.div`
  ${({ theme }) => css`
    border-left: 2px solid ${theme.colors.default_green};
    height: 30px;
    margin: 0 1rem;
  `}
`;
