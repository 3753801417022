import { SUBDOMAIN_ENUM } from '../../../../../../constants/subddomainEnum';

export const hideComponentCheck = (
  subdomainValue: SUBDOMAIN_ENUM,
  allowPaymentInSignup: boolean,
): boolean => {
  return (
    [
      SUBDOMAIN_ENUM.BINDEMY,
      SUBDOMAIN_ENUM.SEDI,
      SUBDOMAIN_ENUM.CGA,
      SUBDOMAIN_ENUM.MARTINBUCER,
      SUBDOMAIN_ENUM.RAIZDAQUESTAO,
      SUBDOMAIN_ENUM.ESCOLAIMPULSE,
      SUBDOMAIN_ENUM.XLR8ACADEMY,
      SUBDOMAIN_ENUM.PROPAGA,
    ].includes(subdomainValue) || allowPaymentInSignup
  );
};
