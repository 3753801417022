import styled from "styled-components";
import Button from "../../../components/Atoms/Button";

export const Container = styled.div`
  /* width: 100vw; */
  max-width: 100%;
  height: 100vh;

  @media (max-width: 768px) {
    min-height: 100vh;
    height: auto;
    padding: 2rem 0 0 0;
  }
`;

export const ContentWrapper = styled.main`
  width: 100%;
  max-width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 8%;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
  }

  h3 {
    font-size: 14px;
  }

  img {
    height: 120px;
    margin-bottom: 4%;
    cursor: pointer;
  }

  .formStyle {
    @media (max-width: 425px) {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;

  img {
    width: 100%;
    max-width: 100%;
    height: 100vh;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;

    img {
      height: auto;
    }
  }
`;

export const FormWrapper = styled.div`
  height: 300px;
  width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;

  h3 {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    font-weight: lighter;
    color: #353536;
    letter-spacing: 4px;
  }

  .forgot {
    margin-top: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    color: #fff;
    /* text-decoration: underline; */
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  &.signup {
    background: #fff;

    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.6);
      color: #000;
    }
  }
`;
