import styled, { css } from 'styled-components';

export const ErrorMessage = styled.span`
  ${({ theme }) =>
    css`
      font-weight: bold;
      color: #ed9320;
      margin: 4px 0 12px 0;
    `}
`;
