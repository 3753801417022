import React, { useEffect, useState } from "react";
import MainContainer from "../MainContainer";
import * as S from "./styles";
import Logo from "../../assets/logo.svg";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaTiktok, FaYoutube, FaLinkedin } from "react-icons/fa";
import { AiFillCloseCircle, AiOutlineMenu } from "react-icons/ai";

const HeaderLanding: React.FC = () => {
  const [click, setClick] = useState(false);
  const [isTriggerMenu, setIsTriggerMenu] = useState(false);

  const handleClick = () => setClick(!click);

  const handleTriggerMenu = () => {
    if (window.innerWidth <= 980) {
      setIsTriggerMenu(true);
    } else {
      setIsTriggerMenu(false);
    }
  };

  useEffect(() => {
    handleTriggerMenu();
  }, []);

  window.addEventListener("resize", handleTriggerMenu);

  return (
    <S.Container>
      <MainContainer>
        <>
          <S.ContainerWrap>
            <S.ContainerWrap>
              <S.ImgLogo src={Logo} />
              <S.DivisorVertical />
              <span>A nova era do aprendizado</span>
            </S.ContainerWrap>

            <div className="burguer_menu">
              <AiOutlineMenu onClick={handleClick} />
            </div>

            <S.ContainerMenu click={click}>
              {isTriggerMenu ? (
                <>
                  <a href="#product" onClick={handleClick}>
                    O PRODUTO
                  </a>
                  <a href="#company" onClick={handleClick}>
                    A EMPRESA
                  </a>
                  <a href="#plans" onClick={handleClick}>
                    PLANOS
                  </a>
                  <a href="#customers" onClick={handleClick}>
                    CLIENTES
                  </a>
                </>
              ) : (
                <>
                  <a href="#product">O PRODUTO</a>
                  <a href="#company">A EMPRESA</a>
                  <a href="#plans">PLANOS</a>
                  <a href="#customers">CLIENTES</a>
                </>
              )}

              <div className="wrap">
                <S.IconDetail>
                  <a target="_blank" href="">
                    <AiOutlineInstagram />
                  </a>
                </S.IconDetail>
                <S.IconDetail>
                  <a target="_blank" href="">
                    <FaTiktok />
                  </a>
                </S.IconDetail>
                <S.IconDetail>
                  <a target="_blank" href="">
                    <FaYoutube />
                  </a>
                </S.IconDetail>
                <S.IconDetail>
                  <a target="_blank" href="">
                    <FaLinkedin />
                  </a>
                </S.IconDetail>
                <AiFillCloseCircle
                  className="close show"
                  onClick={handleClick}
                />
              </div>
            </S.ContainerMenu>
          </S.ContainerWrap>
        </>
      </MainContainer>
    </S.Container>
  );
};

export default HeaderLanding;
