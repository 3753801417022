import styled from 'styled-components';
import Skeleton from '../../../../../../components/Skeleton';

export const PixInfos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
`;

export const DropdownSkeleton = styled(Skeleton)`
  width: 100%;
  height: 52px;
  border-radius: 30px;
`;
