export interface PaymentOutputDto {
  pages: number;
  payments: PaymentModel[];
}

export interface PaymentModel {
  amount: number;
  cartid: string;
  couponid: string;
  method: PaymentMethod;
  status: PaymentStatus;
  transactionid: string;
  installments: number;
  paid_installments: number;
  userid: string;
  user_info: PaymentUserInfo;
  date: string;
}

interface PaymentUserInfo {
  created_at: string;
  email: string;
  fullname: string;
  imageurl: string;
  levelid: string;
  modified_at: string;
  profileid: string;
  roomid: string;
  schoolid: string;
  status: string;
  userid: string;
  username: string;
}

export enum PaymentMethod {
  credit_card = "credit_card",
  free = "free",
  pix = "pix",
    boleto_parcelado = "boleto_parcelado",
        boleto_subscription = "boleto_subscription",
            credit_card_subscription = "credit_card_subscription",
}

export enum PaymentStatus {
  ERROR = "ERROR",
  FREE = "FREE",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  WAITING_REPASS = "WAITING_REPASS",
  PAID = "PAID",
  CANCELED = "CANCELED",
    NOTPAID = "NOTPAID",
      REFUNDED = "REFUNDED",
}
