import { Typography } from '@material-ui/core';
import { FaAngleDown } from 'react-icons/fa';
import * as S from './styles';
import { AccordionCustomProps } from './types';
import { useState } from 'react';

const AccordionCustom = ({ children, accordionId, title }: AccordionCustomProps) => {
  const [headerText, setHeaderText] = useState(title.show);

  const handleChangeText = () => {
    if (headerText === title.show) {
      return setHeaderText(title.hide);
    }

    return setHeaderText(title.show);
  };

  return (
    <S.AccordionContainer>
      <S.AccordionSummaryStyled
        expandIcon={<FaAngleDown />}
        aria-controls={accordionId}
        id={accordionId}
        onClick={handleChangeText}
      >
        <Typography>{headerText}</Typography>
      </S.AccordionSummaryStyled>
      <S.AccordionDetailsStyled>{children}</S.AccordionDetailsStyled>
    </S.AccordionContainer>
  );
};

export default AccordionCustom;
