import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface OptionProps {
  tabIsSelected?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 1270px) {
    justify-content: flex-end;
    margin-left: auto;
  }
`;

export const HamburguerMenu = styled.div`
  display: none;
  height: 20px;
  cursor: pointer;

  &.hidden {
    display: none;
  }

  .bar {
    width: 32px;
    height: 2px;
    background: white;

    display: flex;
    flex-direction: column;

    display: block;

    @media (max-width: 768px) {
      width: 26px;
    }
  }

  @media (max-width: 1270px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 2.625rem;
  }
  @media (max-width: 768px) {
    width: 10px;
    height: 16px;
    margin-right: 1.625rem;
  }
`;

export const OpennedMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;

  background: #fff;
  padding: 6px;
  border-radius: 4px;
  z-index: 9999;

  overflow: hidden;
  transition: max-height 0.2s ease-out, width 0.2s ease-in-out;

  &.active {
    visibility: visible;
    width: unset;

    svg {
      display: none;
    }

    p.mobile {
      display: none;
    }

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: var(--main_black_color);

      max-height: 100% !important;
      width: 100%;
      height: 100%;

      padding: 1rem;

      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: 2rem;
        border-bottom: none;
        color: #fff;
        margin-bottom: 1.5rem;

        &.mobile {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        & > svg {
          display: flex;
          color: #fff;
          font-size: 1rem;
          width: 30px;
          height: 30px;
        }
      }

      & > svg {
        display: block;
        position: absolute;
        top: 30px;
        right: 30px;
        color: #fff;
        font-size: 3rem;
      }
    }
  }

  &.hidden {
    visibility: hidden;
    width: 0.1px;
    max-height: 0.1px;
  }

  p {
    margin-bottom: 0.225rem;

    color: #262626;
    transition: color 0.4s;

    font-family: 'Fira Sans', sans-serif;

    transition: opacity 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      opacity: 0.4;
    }
  }

  @media (min-width: 1270px) {
    display: none;
  }
`;

export const NormalMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  padding: 12px;
  border-radius: 12px;

  a {
    text-decoration: none;
  }

  p {
    margin-right: 1.25rem;
    color: #e9eaed;
    transition: color 0.4s;

    font-family: 'Fira Sans', sans-serif;

    &:hover {
      cursor: pointer;
      color: #fed44a;
    }
  }

  @media (max-width: 1270px) {
    display: none;
  }
`;

export const Option = styled.p<OptionProps>`
  font-size: 14px;

  ${props =>
    props.tabIsSelected &&
    css`
      border-bottom: 1.4px solid #fff;

      &.oppened-menu-option {
        border-bottom: 1.4px solid #000;
      }
    `}
`;
