import Axios from 'axios';
import Input from '../../../../../components/Atoms/Input';
import { cpfMask } from '../../../../../utils/masks';
import { ButtonSection } from '../../styles';
import PaymentMethodsBtns from './PaymentMethodsBtns';
import { PayOnRegisterProps } from './types';
import { useState } from 'react';
import { mask as masker } from 'remask';

interface AddressItems {
  address?: string;
  number?: string;
  city?: string;
  state?: string;
  neighborhood?: string;
  zipcode?: string;
  complement?: string;
}

const initialInputValues: AddressItems = {
  address: '',
  number: '',
  city: '',
  state: '',
  neighborhood: '',
  zipcode: '',
  complement: '',
};

const PayOnRegister = ({
  courseDetailsData,
  selectedInstallments,
  setInstallments,
  setPaymentMethod,
  isLogging,
  handleApplyCoupon,
  setCoupon,
  totalPrice,
  discount,
}: PayOnRegisterProps) => {
  const [inputValues, setInputValues] = useState(initialInputValues);

  const handleCepValidationReq = (cepToValidate: string) => {
    let tratedCep = cepToValidate.replace('.', '').replace('-', '');

    Axios.get(`https://viacep.com.br/ws/${tratedCep}/json/`)
      .then((res: any) => {
        const { cep, uf, localidade, bairro, logradouro, erro } = res.data;
        if (erro) {
          window.alert('Cep não encontrado, tente movamente');
          return;
        }

        setInputValues({
          ...inputValues,
          address: logradouro,
          city: localidade,
          state: uf,
          neighborhood: bairro,
          zipcode: cep,
        });
      })
      .catch(() => {
        window.alert('Erro ao requisitar, por favor, tente novamente mais tarde.');
      });
  };

  const handleInputChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInputValues({
      ...inputValues,
      [name]: value,
    });

    if (name === 'zipcode' && value.length === 10) {
      handleCepValidationReq(value);
    }
  };

  return (
    <>
      <h3>Endereço</h3>

      <Input
        autoComplete="off"
        name="zipcode"
        placeholder="CEP"
        containerStyle={{ width: '100%' }}
        enabled={!isLogging}
        value={masker(inputValues.zipcode, ['99.999-999'])}
        onChange={e => handleInputChanges(e)}
      />

      <div className="group-info">
        <Input
          autoComplete="off"
          name="address"
          placeholder="Rua / Avenida"
          containerStyle={{ width: '68%' }}
          enabled={!isLogging}
          value={inputValues.address}
          onChange={e => handleInputChanges(e)}
        />

        <Input
          autoComplete="off"
          name="number"
          placeholder="Número"
          containerStyle={{ width: '28%' }}
          enabled={!isLogging}
          value={inputValues.number}
          onChange={e => handleInputChanges(e)}
        />
      </div>
      <Input
        autoComplete="off"
        name="complement"
        placeholder="Complemento"
        containerStyle={{ width: '100%' }}
        enabled={!isLogging}
        value={inputValues.complement}
        onChange={e => handleInputChanges(e)}
      />

      <Input
        autoComplete="off"
        name="neighborhood"
        placeholder="Bairro"
        containerStyle={{ width: '100%' }}
        enabled={!isLogging}
        value={inputValues.neighborhood}
        onChange={e => handleInputChanges(e)}
      />

      <div className="group-info">
        <Input
          autoComplete="off"
          name="city"
          placeholder="Cidade"
          containerStyle={{ width: '68%' }}
          enabled={!isLogging}
          value={inputValues.city}
          onChange={e => handleInputChanges(e)}
        />
        <Input
          autoComplete="off"
          name="state"
          placeholder="Estado"
          containerStyle={{ width: '28%' }}
          enabled={!isLogging}
          value={inputValues.state}
          onChange={e => handleInputChanges(e)}
        />
      </div>

      <h3>Pagamento</h3>

      <ButtonSection>
        <PaymentMethodsBtns
          isLogging={isLogging}
          courseDetailsData={courseDetailsData}
          setInstallments={setInstallments}
          selectedInstallments={selectedInstallments}
          totalPrice={totalPrice}
          setPaymentMethod={setPaymentMethod}
          handleApplyCoupon={handleApplyCoupon}
          setCoupon={setCoupon}
          discount={discount}
        />
      </ButtonSection>

      <Input
        autoComplete="off"
        name="documentNumber"
        placeholder="CPF"
        containerStyle={{ width: '100%' }}
        mask={cpfMask}
        enabled={!isLogging}
      />
    </>
  );
};

export default PayOnRegister;
