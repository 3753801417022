import React, { useCallback, useRef, useState } from "react";
import * as Yup from "yup";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useParams, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";

import nlLogo from "../../../assets/images/nlicon.png";

import RequestPasswordResetEmail from "./RequestPasswordResetEmail";
import ResetPassword from "./ResetPassword";

import { Container, ContentWrapper, LoginWrapper, FormWrapper } from "./styles";
import { useInitialRoute } from "../../../hooks/InitialRouteContext";

interface DataFormInfo {
  username: string;
  password: string;
}

interface Params {
  type: string;
  token: string;
}

const Password: React.FC = () => {
  const [isRequesting, setIsRequesting] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { requestPasswordEmail, resetPassword } = useAuth();
  const { addToast } = useToast();

  const { push } = useHistory();
  const params = useParams() as Params;
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token") || "";
  const { subdomainUrlImg, subdomainName } = useInitialRoute();

  const handleLostPassword = useCallback(
    async (data: DataFormInfo) => {
      setIsRequesting(true);
      if (params.type === "forgot") {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            username: Yup.string().required("Usuário obrigatório!"),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await requestPasswordEmail(
            data.username,
            subdomainName.toLocaleUpperCase()
          );

          if (response === "OK") {
            addToast({
              type: "success",
              title: "Email enviado com sucesso :)",
              description:
                "Enviamos o link de recuperação de senha para o seu email!",
            });
          } else if (response === "User not found") {
            addToast({
              type: "error",
              title: "Usuário ou Email incorreto :(",
              description:
                "Não encontramos um usuário com essa identificação, verifique seu email/username e tente novamente!",
            });
          }
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);

            formRef.current?.setErrors(errors);
          } else {
            addToast({
              type: "error",
              title: "Erro na solicitação de email :(",
              description:
                "Oops... parece que algo deu errado, confira seu usuário!",
            });
          }
        }
      } else {
        try {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            password: Yup.string().required("Senha obrigatória!"),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const response = await resetPassword(token, data.password);

          if (response === "OK") {
            addToast({
              type: "success",
              title: "Senha alterada com sucesso:)",
              description:
                "Você já pode utilizar sua nova senha na tela de login!",
            });
            push("/login");
          } else if (response === "token not found") {
            addToast({
              type: "error",
              title: "Senha já atualizada :(",
              description:
                "Verificamos que o link de verificação já foi utilizado anteriormente, faça uma nova solicitação de email e tente novamente!",
            });
          }
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);

            formRef.current?.setErrors(errors);
          } else {
            addToast({
              type: "error",
              title: "Erro no reset de senha :(",
              description:
                "Oops... parece que algo deu errado, tente novamente mais tarde!",
            });
          }
        }
      }
      setIsRequesting(false);
      formRef.current?.reset();
    },
    [addToast, requestPasswordEmail, resetPassword, params, token, push]
  );

  return (
    <Container>
      <ContentWrapper>
        <LoginWrapper>
          <img
            src={subdomainUrlImg}
            alt="logo"
            onClick={() => push("/login")}
          />
          <Form ref={formRef} onSubmit={handleLostPassword}>
            <FormWrapper>
              <h3>Recuperar senha</h3>
              {params.type === "forgot" ? (
                <RequestPasswordResetEmail isRequesting={isRequesting} />
              ) : (
                <ResetPassword isRequesting={isRequesting} />
              )}
            </FormWrapper>
          </Form>
        </LoginWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Password;
