import styled, { css } from "styled-components";

export const Container = styled.section`
  ${({ theme }) => css`
    padding: 3rem 1.5rem;
    min-height: calc(100vh);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 980px) {
      height: auto;
    }
  `}
`;

export const FirstColumn = styled.article`
  ${({ theme }) => css`
    padding: 2rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;
    gap: 2rem;
    justify-content: center;

    p.detail {
      font-size: 1rem;
      font-weight: 600;
      color: ${theme.colors.default_white};

      span {
        color: ${theme.colors.default_green};
      }
    }

    a.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: bolder;
      color: ${theme.colors.default_white};
      background-color: ${theme.colors.default_green};
      border: none;
      border-radius: 0px 10px 0px 10px;
      padding: 0.5rem 0.8rem;
      height: 2.5rem;
      cursor: pointer;
    }
  `}
`;

export const SecondColumn = styled.article`
  ${({ theme }) => css`
    padding: 4rem;
    display: flex;
    justify-content: center;
    flex: 1;
    max-height: 90vh;
  `}
`;

export const PDefaultText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 1rem;
    font-weight: 500;
    text-align: center;

    a {
      color: ${theme.colors.default_green};
    }
  `}
`;

export const H2Custom = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.6rem;

    span {
      color: ${theme.colors.default_green};
    }

    &.sub {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
    }
  `}
`;

export const H3Custom = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.default_white};
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 2rem;

    span {
      color: ${theme.colors.default_green};
    }

    &.small {
      font-size: 2rem;
      line-height: 2.5rem;
      color: ${theme.colors.default_green};
    }
  `}
`;

export const ImgCustom = styled.img`
  ${({ theme }) => css`
    width: 200px;
    max-width: 200px;
  `}
`;

export const GridItems = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 1.5rem;

    &.small {
      grid-template-columns: 1.5fr 2fr;
      background-color: ${theme.colors.default_white};
      padding: 1rem;
      margin: 2rem 0 0 0;
      width: 80%;
      border-radius: 0px 30px 0px 30px;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 980px) {
      grid-template-columns: repeat(1, 1fr);
      &.small {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  `}
`;

export const ItemsCards = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &.small {
      p {
        color: ${theme.colors.default_black};
        text-align: left;
        margin-left: 1rem;
        font-weight: 600;
      }
    }

    @media (max-width: 980px) {
      flex-direction: column;

      &.small {
        p {
          margin-left: 0;
        }
      }
    }
  `}
`;

export const ItemsTextCards = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 2rem;

    p {
      text-align: left;
      margin-top: 1rem;
    }

    @media (max-width: 980px) {
      padding: 1rem 0;
    }
  `}
`;

export const DivisorTxt = styled.div`
  ${({ theme }) => css`
    width: 160px;
    border-radius: 10px;
    border: 1px solid ${theme.colors.divisor};
    margin: 1rem 0;
  `}
`;
