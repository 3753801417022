import styled from "styled-components";
import Button from "../Button";

export const FlexContainer = styled.div`
  a {
    text-decoration: none;
  }

  color: black;

  border-bottom: solid 1px #ebebeb;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 36px;
  border-radius: 4px;

  transition: width 0.4s, height 0.4s;

  width: 100%;
  height: 100px;


.container2 {
  position: relative;
  width: 30%;
  max-width: 120px;
   @media (max-width: 768px) {
  max-width: 90px;

  }
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay2 {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 0px 0px 4px 85px;
  text-align: center;
}


  p {
    font-size: 14px;
    font-family: "Roboto Medium", sans-serif;

    margin-top: 8px;
  }

  .courseContent {
    width: 65%;

    @media (max-width: 768px) {
      display: flex;
      margin-left: 10px;
      flex-direction: column;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: end;

        .whitecolor {

              color: white;

        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Thumb = styled.img`
  object-fit: cover;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border: solid 0.8px rgba(117, 117, 117, 0.5);
  border-radius: 4px;

  width: 120px;
  height: 68px;

   @media (max-width: 768px) {
   img{
     width: 90px;
     height: 48px;
     }
   }
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
  }
  span {
  text-align: right;
    margin: 0;
  }

  .nameWithCoupon {
    display: flex;
    flex-direction: column;

    > strong {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .oldPrice {
      color: #c6c6c6;
    text-decoration: line-through;
  }



    .valorcartaoparcelado {
        color: #929292;
        font-size: 11px;

    }

    .discount {
        color: #00b431;
        font-size: 10px;

    }
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;

  width: 35px;
  height: 24px;
  padding: 4px 8px;

  border-radius: 4px;

  background: red;
  color: #fff;

  p {
    margin: 0;
    padding: 0;
    margin-right: 12px;
  }

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    height: 22px;

    p {
      display: none;
    }

    svg {
      font-weight: bold;
    }
  }
`;
