import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-height: 250px;
  height: 250px;
  z-index: 9;
  transition: all 0.5s ease;

  @media (max-width: 979px) {
    overflow-y: hidden;
    overflow-x: scroll;
    max-height: 275px;
    height: 275px;
  }

  > button {
    position: absolute;
    cursor: pointer;
    height: 70%;
    background-color: transparent;
    border: transparent;
    color: #fff;
    opacity: 0;
    transition: all 0.5s ease;
    width: 55px;
    z-index: 2;

    svg {
      font-size: 2rem;
      background: #0f0f0f;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 10px;
    }

    &.before {
      left: 0;

      @media (max-width: 980px) {
        display: none;
      }
    }

    &.next {
      right: 0;

      @media (max-width: 980px) {
        display: none;
      }
    }
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

export const WrapSlideContent = styled.div`
  display: flex;
  padding: 0 5%;
  transition: all 0.5s ease;
`;
